import { Policy } from 'models/policies';

import { CancelPolicyButton, ContactUsButton } from '../../components/buttons';
import { PolicyDetailsButton } from '../../sections/PolicyDetails/PolicyDetails.models';
import { ExpatLongTermAddDependentsButton } from './buttons/ExpatLongTermAddDependentsButton';

export const getExpatLongTermButtons = (
  policy: Policy
): PolicyDetailsButton[] => {
  const buttonArray: PolicyDetailsButton[] = [
    { id: 'CONTACT_US', component: ContactUsButton },
  ];

  if (policy.status === 'ACTIVE') {
    buttonArray.push({
      id: 'ADD_DEPENDENTS',
      component: ExpatLongTermAddDependentsButton,
    });
  }

  if (policy.status !== 'CANCELED') {
    buttonArray.push({ id: 'CANCEL_POLICY', component: CancelPolicyButton });
  }

  return buttonArray;
};
