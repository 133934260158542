import { BikeClaims } from 'features/bikeClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useSafeTranslation } from 'shared/i18n';

import { BikeClaimsGettingStartedView } from './GettingStarted.view';

export const BikeClaimsGettingStarted = ({
  onSubmitValue,
  metaData: { policyId, providerId },
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <BikeClaimsGettingStartedView
      handleGetStarted={handleSubmit}
      t={t}
      policyId={policyId}
      providerId={providerId}
    />
  );
};
