import { TFunction, Trans, useTranslation } from '@getpopsure/i18n-react';
import { Button } from '@popsure/dirty-swan';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { CategoryLookup } from 'features/recommendationTool/models/lookups';
import { AllowedWelcomeParams } from 'features/recommendationTool/models/recommendation';
import { APIResponseError } from 'models/error';
import { ReactNode } from 'react';
import AnimateHeight from 'react-animate-height';
import { isMobileApp } from 'shared/util/isMobileApp';

import styles from './styles.module.scss';

interface WelcomePageProps {
  title?: string;
  description?: ReactNode;
  buttonTitle?: string;
  insuranceType: AllowedWelcomeParams;
  onClick(): void;
  icons: TWelcomeIcon[];
  error?: APIResponseError;
  loading: boolean;
}

interface TWelcomeIcon extends CategoryLookup {
  id: number;
  transparent?: boolean;
  hiddenOnMobile?: boolean;
}

const WelcomeIcon = ({
  id,
  transparent = false,
  hiddenOnMobile = false,
  icon,
  iconSrcSet,
}: TWelcomeIcon) => {
  const iconStyle = `${styles.icon} ${transparent && styles.transparent} ${
    hiddenOnMobile && styles.hiddenOnMobile
  }`;
  return (
    <img
      className={iconStyle}
      src={icon}
      srcSet={iconSrcSet}
      alt="Insurance Icon"
      id={id.toString()}
    />
  );
};

const getTitle = (t: TFunction, vertical: AllowedWelcomeParams) =>
  vertical === 'intro'
    ? t(
        'recommendationtool.welcome.title.generic',
        'Let’s look at your lifestyle'
      )
    : t(
        'recommendationtool.welcome.title.vertical',
        'Get a {{vertical}} insurance recommendation',
        { vertical }
      );

export const WelcomePage = ({
  title,
  description,
  buttonTitle,
  insuranceType,
  onClick,
  icons,
  loading,
  error,
}: WelcomePageProps) => {
  const { t } = useTranslation();

  return (
    <div className="p-body" data-testid="recommendation-tool-intro-page">
      {isMobileApp && (
        <NavButton path={routes.me.policies.list.path} title="Back" />
      )}
      <div
        className={`d-flex fd-column ai-start jc-center ${styles.container}`}
      >
        <div className={`d-flex ai-start jc-center ${styles.content}`}>
          <div className={styles.textContent}>
            <h1 className="p-h1 mb8" data-testid="welcome-page-title">
              {title || getTitle(t, insuranceType)}
            </h1>
            <p className="p-p mb24">
              {description || (
                <>
                  <span className="d-flex mb24">
                    {t(
                      'recommendationtool.welcome.description.line1',
                      'We’ll show the pros and cons of the policy based on your answers.'
                    )}
                  </span>
                  <Trans i18nKey="recommendationtool.welcome.description.line2">
                    By clicking "Get started", you acknowledge that this
                    simplified recommendation{' '}
                    <strong className="fw-bold">
                      doesn’t replace expert insurance advice
                    </strong>
                    .
                  </Trans>
                </>
              )}
            </p>
            <Button
              data-cy="button-get-started"
              className="wmn3"
              onClick={onClick}
              loading={loading}
            >
              {buttonTitle ||
                t('recommendationtool.welcome.action', 'Get started')}
            </Button>
          </div>
          <div className={`d-flex f-wrap ${styles.imageContent}`}>
            {icons.map((props: TWelcomeIcon) => (
              <WelcomeIcon key={props.id} {...props} />
            ))}
          </div>
        </div>
        <div className="d-flex jc-start mt24 w100">
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-notice p-notice--danger p-p">
              {error?.message}
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};
