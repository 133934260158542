import { TFunction } from '@getpopsure/i18n-react';
import {
  ArthritisIcon,
  CalendarIcon,
  ClockTimeIcon,
  GlassIcon,
  HomeIcon,
  KeyIcon,
  LawyerLegalIcon,
  PawPetIcon,
  PlaneIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const liability = (t: TFunction): AdditionalInfoType => ({
  price: 4.94,
  description: t(
    'policyModal.liability.description',
    'Liability insurance is there to cover accidental damage you do to other people or their belongings. Most importantly, it covers the hospital bills and any missed wages from them being unable to work. Because of how costly this can get, around 80% of all Germans have the insurance.'
  ),
  accordions: [
    {
      title: t('policyModal.liability.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <HomeIcon size={24} />,
          question: t(
            'policyModal.liability.covered1.question',
            'Damage to rented apartments'
          ),
          answer: t(
            'policyModal.liability.covered1.answer',
            'E.g. damage to the floor, stains on the wall, or cracks in the sink. Keep your security deposit when you move out.'
          ),
        },
        {
          id: 'covered-2',
          icon: <KeyIcon size={24} />,
          question: t('policyModal.liability.covered2.question', 'Lost keys'),
          answer: t(
            'policyModal.liability.covered2.answer',
            'Covers the cost of replacing lost keys for rented flats — but doesn’t cover the locksmith costs.'
          ),
        },
        {
          id: 'covered-3',
          icon: <ArthritisIcon size={24} />,
          question: t(
            'policyModal.liability.covered3.question',
            'Accidental injury to others'
          ),
          answer: t(
            'policyModal.liability.covered3.answer',
            "Accidentally collided with someone during a football game? You're covered for any medical costs or lost wages if they're unable to work."
          ),
        },
        {
          id: 'covered-4',
          icon: <PawPetIcon size={24} />,
          question: t(
            'policyModal.liability.covered4.question',
            'Damage from small pets'
          ),
          answer: t(
            'policyModal.liability.covered4.answer',
            'E.g. if your cat scratches the landlord’s sofa or your parrot destroys their chair. Dogs need a dog liability policy instead.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.liability.title2', 'What’s not covered'),
      items: [
        {
          id: 'not-covered-1',
          icon: <GlassIcon size={24} />,
          question: t(
            'policyModal.liability.notCovered1.question',
            'Broken glass'
          ),
          answer: t(
            'policyModal.liability.notCovered1.answer',
            'If there is a broken window, any kind of glass, or a ceramic or induction stove top in your apartment, this is unfortunately not covered by liability insurance.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <HomeIcon size={24} />,
          question: t(
            'policyModal.liability.notCovered2.question',
            'Damage to your own property'
          ),
          answer: t(
            'policyModal.liability.notCovered2.answer',
            'The damages to your own belongings, to belongings in a property you rent to others, and to your own home are not covered.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <KeyIcon size={24} />,
          question: t(
            'policyModal.liability.notCovered3.question',
            'Locksmith'
          ),
          answer: t(
            'policyModal.liability.notCovered3.answer',
            "If you need to get a locksmith to break into your apartment after losing your key, you'll only be covered for the key, not the door opening service."
          ),
        },
        {
          id: 'not-covered-4',
          icon: <LawyerLegalIcon size={24} />,
          question: t(
            'policyModal.liability.notCovered4.question',
            'Fines and penalties'
          ),
          answer: t(
            'policyModal.liability.notCovered4.answer',
            'Any fines and penalties from damages caused by not following the law like crossing the street on a red light are not covered.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: PlaneIcon,
      title: t('policyModal.liability.details.title1', 'Worldwide coverage'),
      description: t(
        'policyModal.liability.details.description1',
        'For german residents'
      ),
    },
    {
      icon: ClockTimeIcon,
      title: t('policyModal.liability.details.title2', 'No waiting periods'),
      description: t(
        'policyModal.liability.details.description2',
        'You can use your insurance right away'
      ),
    },
    {
      icon: CalendarIcon,
      title: t('policyModal.liability.details.title3', 'Monthly cancellation'),
      description: t(
        'policyModal.liability.details.description3',
        'Simply cancel before the renewal date'
      ),
    },
  ],
  buttonText: t('policyModal.liability.buttonText', 'Get covered'),
});
