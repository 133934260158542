import { email } from '@getpopsure/private-constants';
import { useUpdateBikeV1Questionnaire } from 'features/bikeClaims/hooks/useUpdateBikeClaimsFrameNumber';
import { BikeClaims } from 'features/bikeClaims/models';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { BikeClaimsFrameNumberView } from './FrameNumber.view';

export const BikeClaimsFrameNumber = ({
  value,
  onSubmitValue,
  routes,
  metaData: { questionnaireId, policyId },
}: CustomComponentProps<BikeClaims>) => {
  const { t } = useSafeTranslation();
  const {
    mutate: updateBikeV1Questionnaire,
    isError,
    isLoading,
  } = useUpdateBikeV1Questionnaire();

  const [frameNumber, setFrameNumber] = useState<string>(
    (value as string) ?? ''
  );

  const errorMessage = isError
    ? t(
        'claims.bike.frameNumber.error.message',
        'An error occurred while updating your frame number. Please try again or contact us at {{supportEmail}}.',
        { supportEmail: email.help }
      )
    : undefined;

  const validForSubmission = Boolean(frameNumber && frameNumber.length > 0);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validForSubmission) {
      throw new Error(
        `[Bike Claims] Frame number is missing for submission for policy id: ${policyId}`
      );
    }

    updateBikeV1Questionnaire(
      { questionnaireId, infoType: 'bikeInfo', answer: { frameNumber } },
      {
        onSuccess: () => onSubmitValue(frameNumber),
      }
    );
  };

  return (
    <BikeClaimsFrameNumberView
      handleSubmit={handleSubmit}
      t={t}
      validForSubmission={validForSubmission}
      setFrameNumber={setFrameNumber}
      frameNumber={frameNumber}
      blockerRoute={routes.blockerFrameNumber.path}
      loading={isLoading}
      error={errorMessage}
    />
  );
};
