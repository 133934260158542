import { Address } from '@getpopsure/public-models';
import { QuotePlan } from 'models/expat';
import type { InsuranceTypes } from 'models/insurances/types';
import { Name } from 'models/user';
import type { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

export type PolicyMembers =
  | 'OWNER_ONLY'
  | 'OWNER_WITH_DEPENDENTS'
  | 'DEPENDENTS_ONLY';

export type InsuranceVertical =
  | 'expat_health'
  | 'expatV2'
  | 'life'
  | 'bike'
  | 'liability'
  | 'household'
  | 'dental'
  | 'legal'
  | 'travel';

export const verticalIdMapping: {
  [K in InsuranceVertical]: keyof GenericQuestionnaireState;
} = {
  expat_health: 'expat',
  expatV2: 'expat',
  life: 'life',
  bike: 'bike',
  liability: 'liability',
  household: 'household',
  dental: 'dentalV2',
  legal: 'legal',
  travel: 'travelHealthV1',
};

export type PolicyInfo = {
  quoteId?: string;
  questionnaireId?: string;
  dependents?: { quoteIds: string[]; mainPolicyId?: string };
  mainPolicyId?: string;
  startDate?: string;
  language?: string;
};

export interface CheckoutPolicyRequestPayload {
  type: InsuranceTypes;
  policyInfo: PolicyInfo;
  referralCode?: string;
}

export interface CheckoutPolicy<TPolicyDetailsType = unknown> {
  id: string;
  startDate: string;
  proratedPrice: number;
  proratedPeriod: {
    start: string;
    end: string;
  };
  monthlyPrice: number;
  policyDetails: CheckoutPolicyBaseDetails & TPolicyDetailsType;
  policyHolder: {
    name: Name;
    dateOfBirth: string;
    email: string;
    address?: Address;
  };
}

export interface CheckoutPolicyBaseDetails {
  type: InsuranceTypes;
  // TODO: Combine plan types
  planId: QuotePlan;
  regionOfPurchase?: string;
}

export interface CheckoutInfo<TPolicyDetailsType = unknown> {
  id: string;
  totalMonthlyPrice: number;
  totalProratedPrice: number;
  mainPolicy: CheckoutPolicy<TPolicyDetailsType>;
  subPolicies?: CheckoutPolicy<TPolicyDetailsType>[];
}

export const mapVerticalId: {
  [K in InsuranceTypes]: keyof GenericQuestionnaireState | null;
} = {
  INCOMING: 'expat',
  EXPAT_V2: 'expat',
  LIFE: 'life',
  LIFE_V2: 'lifeV2',
  BIKE: 'bike',
  LIABILITY: 'liability',
  CAR: null,
  JOB: null,
  PUBLIC_HEALTH: null,
  PRIVATE_HEALTH: null,
  HOUSEHOLD: 'household',
  LEGAL: 'legal',
  LEGAL_V2: 'legal',
  DENTAL: 'dentalV2',
  DENTAL_V2: 'dentalV2',
  DOG_LIABILITY: 'dog',
  DISABILITY: 'disability',
  GENERIC: null,
  TRAVEL: 'travelHealthV1',
  PET_HEALTH: null,
  COMPANY_LIFE: null,
  COMPANY_HEALTH: null,
  PENSION: null,
  BASIS_PENSION: null,
  INCOMING_ES: 'expatSpain',
  TRAVEL_HEALTH_V1: null,
  TRAVEL_FR: null,
  BIKE_V2: 'bike',
  INCOMING_EU: 'expatEu',
  INCOMING_LT: 'expatLongTerm',
  DISABILITY_V2: 'disabilityV2',
  COMPANY_PENSION: null,
  DOG_LIABILITY_ES: 'dogLiabilitySpain',
};

export interface CheckoutProps extends CheckoutInfo {
  quoteId: string;
  questionnaireId: string;
}

export interface CheckoutPolicyHolder {
  address?: Address;
  dateOfBirth: string;
  email: string;
  name: Name;
}

export type CheckoutDeclineCode =
  | 'authentication_required'
  | 'card_not_supported'
  | 'currency_not_supported'
  | 'expired_card'
  | 'incorrect_number'
  | 'incorrect_cvc'
  | 'incorrect_zip'
  | 'insufficient_funds'
  | 'invalid_cvc'
  | 'invalid_expiry_month'
  | 'invalid_expiry_year'
  | 'invalid_number'
  | 'generic_decline'
  | 'generic';

// https://stripe.com/docs/declines/codes
export const mapCheckoutDeclineCodeError: Record<CheckoutDeclineCode, string> =
  {
    authentication_required:
      'Card authentication failed, please try again. If this problem persists, you must contact your card issuer.',
    card_not_supported: 'Your card does not support this type of purchase',
    currency_not_supported: 'Your card does not support the specified currency',
    expired_card: 'Your card is expired',
    incorrect_number: 'Your card number is incorrect',
    incorrect_cvc: 'Your CVC number is incorrect',
    incorrect_zip: 'Your card postal code is incorrect',
    insufficient_funds:
      'Your card has insufficient funds to complete the checkout',
    invalid_cvc: 'Your CVC number is incorrect',
    invalid_expiry_month: "Your card's expiry month is invalid",
    invalid_expiry_year: "Your card's expiry year is invalid",
    invalid_number: 'Your card number is incorrect',
    generic_decline:
      "We're unable to process your payment method. Please choose a different payment method and try again.",
    generic:
      "We're unable to process your payment method. Please choose a different payment method and try again.",
  };
