import { insurance } from '@getpopsure/private-constants';
import { Blocker } from 'components/Blocker';
import { submitScreenedOutQuestionnaire } from 'features/disabilityV2/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';

export const EligibilityCheck = () => {
  const dispatch = useDispatch();
  const { t } = useSafeTranslation();

  const submitSQuestionnaire = async () =>
    dispatch(submitScreenedOutQuestionnaire());

  useEffect(() => {
    submitSQuestionnaire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Blocker
      title={t(
        'disabilityV2.qnr.eligibilityCheck.title',
        'Sorry, you are not eligible for disability insurance'
      )}
      description={t(
        'disabilityV2.qnr.eligibilityCheck.description',
        'Thank you for your information. We will check your application and get back to you if we have any questions or not.'
      )}
      iconType="SHIELD"
      buttonProps={[
        {
          type: 'href',
          href: insurance.pension.landingPage,
          caption: t(
            'disabilityV2.qnr.eligibilityCheck.button.caption',
            'Get a quote for life insurance'
          ),
        },
      ]}
    />
  );
};
