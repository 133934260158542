import { reviews } from '@getpopsure/private-constants';

import trustPilotLogo from '../img/trustpilot-logo.svg';
import Rating from '../rating';
import styles from './style.module.scss';

// TODO: dynamically get trustpilot data
const rating = 4.8;
const numberOfReviews = 380;

const TrustpilotReviewBadge = ({
  displayReviews,
}: {
  displayReviews?: boolean;
}) => (
  <a
    className={styles.container}
    href={reviews.trustpilot}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className={styles['inner-container']}>
      <div className="mr8 p-h3 tc-grey-700">{rating.toFixed(1)}</div>
      <div className="mr8">
        <Rating rating={rating} reviewType="trustpilot" />
      </div>
      {!displayReviews && (
        <img
          className={styles['logo-big']}
          src={trustPilotLogo}
          alt="trustpilot logo"
        />
      )}
    </div>
    {displayReviews && (
      <div className={`mt8 ${styles['inner-container']}`}>
        <div className="p-p--small tc-grey-600 fw-bold mr8">
          {numberOfReviews} <span className="fw-normal">reviews</span>
        </div>
        <img
          className={styles['logo-small']}
          src={trustPilotLogo}
          alt="trustpilot logo"
        />
      </div>
    )}
  </a>
);

export default TrustpilotReviewBadge;
