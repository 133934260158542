import { isChecklistCompleted } from 'features/studentChecklist/models';
import { Policy } from 'models/policies';

import { getCurrentStep } from '../getCurrentStep';

export type PolicyAction = 'DETAIL' | 'ACTION_NEEDED' | null;

function getPublicHealthActions(
  policy: Policy,
  needToSignBrokerMandate: boolean
): PolicyAction {
  const { status } = policy;
  const isCanceled = status === 'DROPPED_OUT' || status === 'CANCELED';
  const currentStep = getCurrentStep(policy);

  if (
    [
      'LETTER_OF_ACCEPTANCE',
      'CONFIRMATION_OF_COVERAGE',
      'PROOF_OF_ENROLLMENT',
      'BANK_ACCOUNT',
      'IBAN',
      'PHOTO_UPLOAD',
      'GERMAN_ADDRESS',
    ].includes(currentStep) &&
    !isCanceled
  ) {
    return 'ACTION_NEEDED';
  }

  const { studentChecklist } = policy.attributes;

  if (
    [
      'APPLICATION_RECEIVED',
      'APPLICATION_SENT',
      'COVERED',
      'STUDENT_DOCS_UPLOADED_BY_US',
    ].includes(status)
  ) {
    if (
      studentChecklist !== undefined &&
      policy.status !== 'COVERED' &&
      !isChecklistCompleted(studentChecklist) &&
      !studentChecklist?.isCompleted
    ) {
      return 'ACTION_NEEDED';
    }

    return 'DETAIL';
  }

  if (needToSignBrokerMandate) {
    return 'ACTION_NEEDED';
  }

  return 'DETAIL';
}

function getPoliciesListAction(
  policy: Policy,
  needToSignBrokerMandate = false
): PolicyAction {
  const { type, status } = policy;

  if (type === 'PUBLIC_HEALTH') {
    return getPublicHealthActions(policy, needToSignBrokerMandate);
  }

  if (
    [
      'BIKE',
      'HOUSEHOLD',
      'LIABILITY',
      'LEGAL',
      'INCOMING',
      'EXPAT_V2',
      'DENTAL',
    ].includes(type) &&
    ['ACTIVE', 'PENDING', 'CANCELED'].includes(status)
  ) {
    return 'DETAIL';
  }

  if (
    [
      'LIFE',
      'PRIVATE_HEALTH',
      'DOG_LIABILITY',
      'DISABILITY',
      'GENERIC',
      'COMPANY_LIFE',
      'COMPANY_HEALTH',
      'COMPANY_PENSION',
      'TRAVEL',
      'PET_HEALTH',
      'PENSION',
      'BASIS_PENSION',
      'INCOMING_ES',
      'TRAVEL_FR',
      'INCOMING_EU',
      'INCOMING_LT',
      'DOG_LIABILITY_ES',
    ].includes(type)
  ) {
    return 'DETAIL';
  }

  return null;
}

export { getPoliciesListAction, getPublicHealthActions };
