import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button, IbanInput, Input } from '@popsure/dirty-swan';
import { Pension } from 'features/pension/models';
import { FormEvent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';
import { isValidIban } from 'shared/util/isValidIban';

import styles from './PaymentDetails.module.scss';

export const PaymentDetails = ({
  onSubmitValue,
  questionnaireAnswers,
}: QuestionnaireFormProps<Pension>) => {
  const { t } = useSafeTranslation();

  const name =
    questionnaireAnswers.name && capitalizeName(questionnaireAnswers.name);

  const [accountHolder, setAccountHolder] = useState(
    questionnaireAnswers.sepa?.accountHolder ?? name
  );
  const [iban, setIban] = useState(questionnaireAnswers.sepa?.iban ?? '');

  const [error, setError] = useState<null | string>(null);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setError(null);

    if (!isValidIban(iban)) {
      return setError("The IBAN you've entered is invalid");
    }

    onSubmitValue({
      iban,
      accountHolder,
    });
  };

  const isValid = iban && accountHolder;

  return (
    <form
      className={`p-body d-flex fd-column ai-start jc-center ${styles.container}`}
      onSubmit={handleSubmit}
    >
      <div className="wmx8">
        <h1 className="p-h1 mb8">
          {t('pension.qnr.paymentDetails.title', 'Payment details')}
        </h1>
        <p className="p-p">
          {t(
            'pension.qnr.paymentDetails.description',
            'Bank account details for monthly and one-off payments. You will be charged only after your pension plan is approved.'
          )}
        </p>
        <div className="wmx6">
          <IbanInput
            className="mt16"
            type="text"
            placeholder={t(
              'pension.qnr.paymentDetails.iban.placeholder',
              'IBAN'
            )}
            value={iban}
            onChange={setIban}
            required
            data-cy="input-iban"
          />
          <Input
            className="mt16"
            type="text"
            placeholder={t(
              'pension.qnr.paymentDetails.accountHolder.placeholder',
              "Account holder's name"
            )}
            value={accountHolder}
            onChange={(e) => setAccountHolder(e.target.value)}
            required
            data-cy="input-account-holder"
          />
        </div>
      </div>

      <AnimateHeight duration={300} height={error ? 'auto' : 0}>
        <div className="p-notice p-notice--warning mt16">{error}</div>
      </AnimateHeight>
      <div className="mt24 d-flex gap16">
        <Button disabled={!isValid} data-cy="button-continue">
          {t('pension.qnr.paymentDetails.button.caption', 'Continue')}
        </Button>
      </div>
    </form>
  );
};
