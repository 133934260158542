import * as Sentry from '@sentry/react';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { ClaimsAction, RequestAction } from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import { ClaimSubmitInfo } from 'models/claims';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import { ActionResponse } from 'shared/models/types';

import { ExpatSpainClaim, ZSubmitClaimInfo } from './models';

export const submitClaim =
  (policyId: string, answers: Partial<ExpatSpainClaim>) =>
  () =>
  async (
    dispatch: ThunkDispatch<AppState, unknown, RequestAction | ClaimsAction>
  ): Promise<ActionResponse> => {
    const requestType: RequestType = 'CREATE_CLAIM';
    try {
      if (!policyId) {
        throw new Error(
          '[Expat Submit Claims] Policy ID is missing for submitting claim.'
        );
      }

      const validationResult = ZSubmitClaimInfo.safeParse({
        ...answers,
      });

      if (!validationResult.success) {
        throw new Error(
          `[Expat - Submit Claims] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedData = validationResult.data;
      const isCostPlanClaim = validatedData.claimType === 'COST_PLAN';

      const claimInfo: ClaimSubmitInfo = {
        userPolicyId: policyId,
        claimTypes: [
          {
            claimType: validatedData.claimType,
            additionalInfo: validatedData.additionalInfo,
          },
        ],
        uploadTokens: validatedData.uploadDocuments.map((file) => file.token),
        amount: isCostPlanClaim
          ? undefined
          : validatedData.payoutDetails.amount,
        iban: isCostPlanClaim ? undefined : validatedData.payoutDetails.iban,
        details: validatedData.diagnosis,
      };

      dispatch(setRequestInProcess(true, requestType));

      await endpoint.submitClaim(claimInfo);
      dispatch(setRequestInProcess(false, requestType));
      return 'SUCCESS';
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      return 'ERROR';
    }
  };
