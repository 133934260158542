import { paramsSetUrl } from '@getpopsure/public-utility';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { getTrackingObject } from '@getpopsure/tracker';
import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';

import { DisabilityV2 } from '../../models';

type DisabilityTallyFormProps = Pick<
  QuestionnaireFormProps<DisabilityV2>,
  'questionnaireAnswers'
>;

export const DisabilityTallyForm = ({
  questionnaireAnswers: answers,
}: DisabilityTallyFormProps) => {
  const source = getTrackingObject();
  const size = useWindowSize();

  const qnrParams = [
    { key: 'formId', value: uuidv4() },
    { key: 'degree', value: answers.degree ?? '' },
    { key: 'height', value: String(answers.height) },
    { key: 'weight', value: String(answers.weight) },
    { key: 'dateOfBirth', value: answers.dateOfBirth ?? '' },
    { key: 'occupation', value: answers.occupation ?? '' },
    { key: 'profession', value: answers.profession?.value ?? '' },
    { key: 'monthlyNetIncome', value: String(answers.monthlyNetIncome) },
    { key: 'price', value: String(answers.quote?.price) },
    { key: 'monthlyPayout', value: String(answers.monthlyPayout) },
    { key: 'questionnaire', value: answers.questionnaireId ?? '' },
    { key: 'quote', value: answers.quote?.id ?? '' },
  ];

  const utmParams = [
    { key: 'utmSource', value: source?.utm_source ?? '' },
    { key: 'utmMedium', value: source?.utm_medium ?? '' },
    { key: 'utmCampaign', value: source?.utm_campaign ?? '' },
    { key: 'utmTerm', value: source?.utm_term ?? '' },
    { key: 'utmContent', value: source?.utm_content ?? '' },
  ];

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src={paramsSetUrl(
          `https://tally.so/r/wMO74X`,
          qnrParams.concat(utmParams)
        )}
        title="Disability insurance signup"
        data-cy="tally-iframe"
      />
    </>
  );
};
