import {
  BottomOrRegularModal,
  Button,
  Checkbox,
  Input,
} from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { Trans, useSafeTranslation } from 'shared/i18n';

import styles from './SepaMandate.module.scss';

type SepaMandateProps = {
  iban: string;
  accountHolder: string;
  checkboxValues: string[];
  setCheckboxValues: (checkboxValues: string[]) => void;
};

export const SepaMandate = ({
  iban,
  accountHolder,
  checkboxValues,
  setCheckboxValues,
}: SepaMandateProps) => {
  const history = useHistory();
  const { t } = useSafeTranslation();
  const [openModal, setOpenModal] = useState(false);

  const navigateToPaymentDetails = () => {
    const path = generatePath(routes.policies.pension.questionnaire.path, {
      groupId: 'signup',
      questionId: 'sepa',
    });
    history.push(path);
  };

  return (
    <div className={`bg-white pb8 ${styles.container}`}>
      <div className="p-h2 mb16 d-flex fd-row ai-center jc-between">
        <h2 className="jc-start">
          {t('pension.qnr.sepaMandate.title', 'Set up direct debit')}
        </h2>
        <Button
          variant="filledGray"
          type="button"
          onClick={navigateToPaymentDetails}
        >
          {t('pension.qnr.sepaMandate.edit.caption', 'Edit')}
        </Button>
      </div>
      <div>
        <Input
          className="mt16"
          type="text"
          placeholder="IBAN"
          defaultValue={iban}
          disabled
        />
        <Input
          className="mt16"
          type="text"
          placeholder="Account holder's name"
          defaultValue={accountHolder}
          disabled
        />
        <Checkbox
          bordered={false}
          classNames={{ container: 'mt24', label: 'wmx8' }}
          onChange={setCheckboxValues}
          wide
          options={{
            sepaMandate: t(
              'pension.qnr.sepaMandate.checkbox.text',
              'I agree to set up direct debit by SEPA mandate and confirm to have full access to the provided account.'
            ),
          }}
          value={checkboxValues}
        />
      </div>
      <div className="ta-center">
        <button
          type="button"
          className="p-a fw-bold bg-transparent c-pointer mt24 ta-center"
          onClick={() => setOpenModal(true)}
        >
          {t(
            'pension.qnr.sepaMandate.viewAgreementButton.caption',
            'View agreement'
          )}
        </button>
      </div>
      <BottomOrRegularModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title={t('pension.qnr.sepaMandate.modal.title', 'SEPA mandate')}
      >
        <Trans i18nKey="pension.qnr.sepaMandate.modal.text">
          <div className="p-p px24 pb24">
            <p>
              By setting up direct debit, I authorise Squarelife Insurance AG to
              collect payments from my account by direct debit or any other
              similar direct debit system in use in my country. At the same
              time, I instruct my credit institution to honour the direct debits
              drawn on my account by the group company. I agree that I will be
              notified of the SEPA direct debit collection at least 5 calendar
              days in advance.
            </p>
            <p>
              <b className="fw-bold">Note:</b> I may request a refund of the
              debited amount within 8 weeks, beginning with the debit date. The
              conditions agreed with my credit institution apply.
            </p>
            <p className="pt24">
              <b className="fw-bold">Payment mode:</b> Monthly
            </p>
            <p>
              <b className="fw-bold">Premium:</b> as agreed above
            </p>
            <p>
              <b className="fw-bold">Company:</b> Squarelife Insurance AG
            </p>
            <p>
              <b className="fw-bold">Payment method:</b>{' '}
              SEPA-Lastschrift:DE50120300001081442533
            </p>
            <p>
              <b className="fw-bold">Creditor-Identification-No:</b>{' '}
              DE65ZZZ00001990209
            </p>
          </div>
        </Trans>
      </BottomOrRegularModal>
    </div>
  );
};
