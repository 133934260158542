import { faq } from '@getpopsure/private-constants';
import { illustrations } from '@popsure/dirty-swan';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export const PurchaseReceiptsBlocker = () => {
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container}>
      <div className={`p-body ta-center wmx7 ${styles['inner-container']}`}>
        <img
          src={illustrations.shieldWarning}
          alt={t('claims.bike.blocker.imgAlt', 'Purple shield')}
          width={68}
        />
        <header className="mt16 p-h1">
          {t('claims.bike.blocker.purchaseReceipts.title', "We're sorry...")}
        </header>
        <p className="p-p mt16">
          {t(
            'claims.bike.blocker.purchaseReceipts.description',
            'Proof of purchase for the affected bike, lock, and accessories are always required to settle your claim.'
          )}
        </p>
        <a
          /* TODO: [KONG] Replace with reusable white button */
          className={`p-btn--primary mt16 wmn3 bg-white tc-primary-500 ${styles.button}`}
          href={faq.bikeProofToKeepBikeCovered}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('claims.bike.blocker.purchaseReceipts.cta', 'Learn more')}
        </a>
      </div>
    </div>
  );
};
