import { TFunction } from '@getpopsure/i18n-react';
import { blog, faq, insurance, website } from '@getpopsure/private-constants';
import { isDateFuture } from '@getpopsure/public-utility';
import { Blocker } from 'components/Blocker';
import RadioForm from 'components/questionnaireForms/RadioForm';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { DependentsIntro } from 'features/expat/components/DependentsIntro';
import { JobOffer } from 'features/expat/components/JobOffer';
import { UniversityName } from 'features/expat/components/UniversityName';
import {
  assertedUniversities,
  dependentOccupationMapping,
  dependentStudentMapping,
  ExpatDependentQuestionnaire,
  Occupation,
  StudentType,
  University,
} from 'features/expat/models';
import {
  getArrivalDateYearRange,
  getDateOfBirthYearRange,
  getJobOfferDateYearRange,
  isBetweenFourAndFiveYearsInGermanyBlocker,
  isDateInThePast,
  isMoreThanFiveYearsInGermany,
  isOver,
  isWithinMonth,
  YEARLY_MIN_INCOME_THRESHOLD,
} from 'features/expat/utils';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { generatePath } from 'react-router';

export const preQuoteDependentQNR = (
  t: TFunction,
  mainPolicyId: string
): PartialQuestionnaire<ExpatDependentQuestionnaire, 'prequote'> => {
  return {
    welcome: {
      required: true,
      type: 'CUSTOM',
      groupId: 'prequote',
      component: DependentsIntro,
      retrieveAnswerObject: (): Partial<ExpatDependentQuestionnaire> => ({
        welcome: true,
        mainPolicyId,
        isPolicyHolder: false,
      }),
      retrieveQuestionsToRemove: () => ['dateOfBirth', 'arrivalDate', 'quote'],
    },
    dateOfBirth: {
      required: true,
      type: 'DATE',
      groupId: 'prequote',
      props: {
        question: t(
          'expat.qnr.dependentPreQuote.age.title',
          'When were they born?'
        ),
        yearRange: getDateOfBirthYearRange(),
      },
      validateAnswer: (date) => {
        if (isDateFuture(date)) {
          return t(
            'expat.qnr.preQuote.age.error.noFutureDate',
            'The date has to be today or in the past'
          );
        }
        return null;
      },
      retrieveAnswerObject: (
        dateOfBirth: string
      ): Partial<ExpatDependentQuestionnaire> => ({
        dateOfBirth,
      }),
      retrieveNextPageId: (answer) => {
        if (isOver(answer, 75)) {
          return 'seventyFiveAndOverBlocker';
        }
        return 'arrivalDate';
      },
      retrieveQuestionsToRemove() {
        return [
          'occupation',
          'employedAnnualIncome',
          'employmentStartDate',
          'typeOfStudent',
          'universityName',
          'quote',
        ];
      },
    },
    seventyFiveAndOverBlocker: {
      required: (answers) => {
        if (answers.dateOfBirth) {
          return isOver(answers.dateOfBirth, 75);
        }
        return false;
      },
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.dependentPreQuote.seventyFiveAndOverBlocker.title',
            'Unfortunately...'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.seventyFiveAndOverBlocker.description',
            'Because they are over 74 years old, they aren’t eligible for expat health insurance.\n\nYou can try out our **digital recommendation** tool to find out which health insurance is the best option for them.'
          ),
          buttonProps: [
            {
              type: 'href',
              href: generatePath(routes.insuranceTool.intro.path, {
                insuranceType: 'health',
              }),
              caption: t(
                'expat.qnr.dependentPreQuote.seventyFiveAndOverBlocker.primaryButton.cta',
                'Get a recommendation'
              ),
            },
            {
              variant: 'SECONDARY',
              type: 'href',
              href: blog.insuranceAfter55,
              caption: t(
                'expat.qnr.dependentPreQuote.seventyFiveAndOverBlocker.secondayButton.cta',
                'Learn more'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      groupId: 'prequote',
    },
    arrivalDate: {
      required: true,
      type: 'DATE',
      groupId: 'prequote',
      props: {
        question: t(
          'expat.qnr.dependentPreQuote.arrivalDate.title',
          "What's their arrival date in Germany?"
        ),
        yearRange: getArrivalDateYearRange(),
        additionalInfo: {
          title: t(
            'expat.qnr.preQuote.arrivalDate.information.title',
            'Why is the date important?'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.arrivalDate.information.description',
            {
              defaultValue:
                'The maximum length of coverage on expat health insurance is 5 years. If they plan to stay in Germany permanently, expat health insurance might not be the best option for them. You can try out our [digital recommendation]({{link}}) tool to find the health insurance plan that best suits their needs.',
              link: generatePath(routes.insuranceTool.intro.path, {
                insuranceType: 'health',
              }),
            }
          ),
        },
      },
      validateAnswer: (answer) => {
        if (
          dayjs().add(1, 'year').subtract(1, 'days').isBefore(dayjs(answer))
        ) {
          return t(
            'expat.qnr.preQuote.arrivalDate.maxFutureArrivalDate',
            'Arrival date should not be more than 1 year in the future. Please adjust the arrival date.'
          );
        }
        return null;
      },
      retrieveAnswerObject: (
        arrivalDate: string
      ): Partial<ExpatDependentQuestionnaire> => ({
        arrivalDate,
      }),
      retrieveNextPageId: (answer) => {
        if (isMoreThanFiveYearsInGermany(answer)) {
          return 'moreThanFiveYearsInGermanyBlocker';
        }
        if (isBetweenFourAndFiveYearsInGermanyBlocker(answer)) {
          return 'betweenFourAndFiveYearsInGermanyBlocker';
        }
        return 'occupation';
      },
      retrieveQuestionsToRemove: () => ['quote', 'startDate'],
    },
    betweenFourAndFiveYearsInGermanyBlocker: {
      required: (answers) => {
        if (answers.betweenFourAndFiveYearsInGermanyBlocker) {
          return isBetweenFourAndFiveYearsInGermanyBlocker(answers.arrivalDate);
        }
        return false;
      },
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.dependentPreQuote.betweenFourAndFiveYearsInGermanyBlocker.title',
            'Expat health insurance might not be the best choice for them...'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.betweenFourAndFiveYearsInGermanyBlocker.description',
            'Expat health insurance is a temporary option that is only valid for the first 5 years of their stay in Germany. It does not offer full health insurance coverage or cover pre-existing conditions, so it cannot be used to renew a visa.\n\nYou can try out our digital recommendation tool to find out which health insurance is the best option for them.'
          ),
          buttonProps: [
            {
              type: 'href',
              href: generatePath(routes.insuranceTool.intro.path, {
                insuranceType: 'health',
              }),
              caption: t(
                'expat.qnr.dependentPreQuote.betweenFourAndFiveYearsInGermanyBlocker.primatButton.cta',
                'Get a recommendation'
              ),
            },
            {
              variant: 'SECONDARY',
              type: 'button',
              onClick: () => props.onSubmitValue(true),
              caption: t(
                'expat.qnr.betweenFourAndFiveYearsInGermanyBlocker.secondaryButton.cta',
                'Continue application'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      retrieveNextPageId: (answer) => {
        if (answer === true) {
          return 'occupation';
        }
        return 'betweenFourAndFiveYearsInGermanyBlocker';
      },
      groupId: 'prequote',
    },
    moreThanFiveYearsInGermanyBlocker: {
      required: (answers) => {
        if (answers.arrivalDate) {
          return isMoreThanFiveYearsInGermany(answers.arrivalDate);
        }
        return false;
      },
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.dependentPreQuote.nonAcceptedForEmploymentBlocker.title',
            'Unfortunately...'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.moreThanFiveYearsInGermany.description',
            'Since they have been in Germany for longer than 5 years, they aren’t eligible for expat health insurance.\n\nYou can try out our __digital recommendation tool__ to find out which health insurance is the best option for them.'
          ),
          buttonProps: [
            {
              type: 'href',
              href: generatePath(routes.insuranceTool.intro.path, {
                insuranceType: 'health',
              }),
              caption: t(
                'expat.qnr.moreThanFiveYearsInGermany.exploreButton.cta',
                'Get a recommendation'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      groupId: 'prequote',
    },
    occupation: {
      required: true,
      type: 'CUSTOM',
      component: (props) =>
        RadioForm({
          ...props,
          value: props.value !== undefined ? String(props.value) : undefined,
          question: t(
            'expat.qnr.dependentPreQuote.occupation.title',
            "What's their main occupation in Germany?"
          ),
          mapValue: dependentOccupationMapping(t),
          additionalInfo: {
            title: t(
              'expat.qnr.preQuote.occupation.information.title',
              'Working for an international company?'
            ),
            description: t(
              'expat.qnr.dependentPreQuote.occupation.information.description',
              'If they have a contract with a company that has a tax registration in Germany, they are considered to be employed in Germany.'
            ),
          },
        }),
      retrieveAnswerObject: (
        occupation: Occupation
      ): Partial<ExpatDependentQuestionnaire> => ({
        occupation,
      }),
      retrieveNextPageId: (answer: Occupation, questionnaireAnswers) => {
        if (['UNEMPLOYED', 'SELF_EMPLOYED', 'OTHER'].includes(answer)) {
          return 'quote';
        }

        if (
          answer === 'STUDENT' &&
          questionnaireAnswers?.dateOfBirth &&
          isOver(questionnaireAnswers.dateOfBirth, 30)
        ) {
          return 'quote';
        }

        if (
          answer === 'STUDENT' &&
          questionnaireAnswers?.dateOfBirth &&
          !isOver(questionnaireAnswers.dateOfBirth, 30)
        ) {
          return 'typeOfStudent';
        }

        if (answer === 'HAVE_A_JOB_OFFER') {
          return 'employmentStartDate';
        }

        if (answer === 'PROFESSIONAL_ATHLETE') {
          return 'isProfessionalDancer';
        }

        return 'employedAnnualIncome';
      },
      retrieveQuestionsToRemove() {
        return [
          'employedAnnualIncome',
          'typeOfStudent',
          'typeOfStudent',
          'universityName',
          'quote',
        ];
      },
      groupId: 'prequote',
    },
    employedAnnualIncome: {
      required: (answers) => {
        return answers.occupation === 'EMPLOYED';
      },
      type: 'NUMBER',
      props: {
        question: t(
          'expat.qnr.dependentPreQuote.yearlyIncome.title',
          "What's their annual employment income before taxes?"
        ),
        placeholder: t(
          'expat.qnr.preQuote.yearlIncome.placeholder',
          'Annual Income'
        ),
      },
      groupId: 'prequote',
      retrieveAnswerObject: (
        employedAnnualIncome: number
      ): Partial<ExpatDependentQuestionnaire> => ({
        employedAnnualIncome,
      }),
      retrieveNextPageId: (answer, questionnaireAnswers) => {
        if (answer < YEARLY_MIN_INCOME_THRESHOLD) {
          return 'quote';
        }

        if (answer > insurance.privateHealth.employeeThreshold) {
          return 'aboveThresholdEmployedBlocker';
        }

        if (
          answer >= YEARLY_MIN_INCOME_THRESHOLD &&
          answer <= insurance.privateHealth.employeeThreshold &&
          questionnaireAnswers?.dateOfBirth &&
          !isOver(questionnaireAnswers.dateOfBirth, 55)
        ) {
          return 'withinThresholdEmployedBlocker';
        }
        return 'aboveThresholdOrOver55EmployedBlocker';
      },
    },
    aboveThresholdEmployedBlocker: {
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.dependentPreQuote.aboveThresholdEmployedBlocker.title',
            'Expat health insurance might not be right for them'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.aboveThresholdEmployedBlocker.description',
            'Expat health insurance is typically not accepted by employers in Germany. It is only a temporary option for new arrivals in Germany. It does not offer comprehensive health insurance coverage.\n\nIt looks like they might be eligible for private health insurance.'
          ),
          buttonProps: [
            {
              type: 'href',
              href: insurance.privateHealth.landingPage,
              caption: t(
                'expat.qnr.dependentPreQuote.aboveThresholdEmployedBlocker.exploreButton.cta',
                'Explore private health insurance'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      groupId: 'prequote',
    },
    withinThresholdEmployedBlocker: {
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.dependentPreQuote.withinThresholdEmployedBlocker.title',
            'Expat health insurance might not be right for them'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.withinThresholdEmployedBlocker.description',
            'Expat health insurance is typically not accepted by employers in Germany. It is only a temporary option for new arrivals in Germany. It does not offer full health insurance coverage or cover pre-existing conditions.\n\nIt looks like they might be eligible for public health insurance.'
          ),
          buttonProps: [
            {
              type: 'href',
              href: insurance.publicHealth.landingPage,
              caption: t(
                'expat.qnr.dependentPreQuote.withinThresholdEmployedBlocker.exploreButton.cta',
                'Explore public health insurance'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      groupId: 'prequote',
    },
    aboveThresholdOrOver55EmployedBlocker: {
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.dependentPreQuote.aboveThresholdOrOver55EmployedBlocker.title',
            'Expat health insurance might not be right for them'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.aboveThresholdOrOver55EmployedBlocker.description',
            'Expat health insurance is typically not accepted by employers in Germany. It is only a temporary option for new arrivals in Germany. It does not offer full health insurance coverage or cover pre-existing conditions.\n\nThey can try out our **digital recommendation tool** to find out which health insurance is the best option for them. '
          ),
          buttonProps: [
            {
              type: 'href',
              href: generatePath(routes.insuranceTool.intro.path, {
                insuranceType: 'health',
              }),
              caption: t(
                'expat.qnr.aboveThresholdOrOver55EmployedBlocker.exploreButton.cta',
                'Get a recommendation'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      groupId: 'prequote',
    },
    employmentStartDate: {
      type: 'CUSTOM',
      component: (props) =>
        JobOffer({
          ...props,
          value: props.value === undefined ? undefined : String(props.value),
          question: t(
            'expat.qnr.dependentPreQuote.jobOfferStartDate.title',
            'When does their employment contract begin?'
          ),
          yearRange: getJobOfferDateYearRange(),
          skipDetails: {
            path: '',
            title: t(
              'expat.qnr.preQuote.jobOfferStartDate.skipQuestion',
              "I don't know yet"
            ),
            onClick: () => props.onSubmitValue(),
          },
          validate: (date?: string) => {
            if (date && isDateInThePast(date)) {
              return {
                validForSubmission: false,
                errorMsg: t(
                  'expat.qnr.preQuote.jobOfferStartDate.error',
                  'Date has to be in the future'
                ),
              };
            }
            if (date && isWithinMonth(date)) {
              return {
                validForSubmission: true,
                errorMsg: t(
                  'expat.qnr.preQuote.jobOfferStartDate.warning',
                  'Keep in mind that expat health insurance is a monthly subscription.'
                ),
              };
            }
            return { validForSubmission: true };
          },
        }),
      groupId: 'prequote',
      retrieveAnswerObject: (
        employmentStartDate: Date
      ): Partial<ExpatDependentQuestionnaire> => ({
        employmentStartDate,
      }),
      retrieveNextPageId: () => {
        return 'quote';
      },
    },
    typeOfStudent: {
      type: 'RADIO',
      groupId: 'prequote',
      props: {
        question: t(
          'expat.qnr.dependentPreQuote.studentType.title',
          'Where are they studying?'
        ),
        mapValue: dependentStudentMapping(t),
      },
      retrieveAnswerObject: (
        typeOfStudent: StudentType
      ): Partial<ExpatDependentQuestionnaire> => ({
        typeOfStudent,
      }),
      retrieveNextPageId: (answer: StudentType) => {
        if (['LANGUAGE_STUDENT', 'OTHER'].includes(answer)) {
          return 'quote';
        }
        return 'universityName';
      },
    },
    universityName: {
      type: 'CUSTOM',
      component: UniversityName,
      retrieveAnswerObject: (
        universityName: string
      ): Partial<ExpatDependentQuestionnaire> => ({
        universityName,
      }),
      retrieveNextPageId: (answer: University, questionnaireAnswers) => {
        const isListedUniversity = assertedUniversities.includes(answer);
        if (
          answer === null ||
          !isListedUniversity ||
          (questionnaireAnswers?.dateOfBirth &&
            isOver(questionnaireAnswers.dateOfBirth, 30))
        ) {
          return 'quote';
        }
        return 'under30StudentBlocker';
      },
      groupId: 'prequote',
    },
    under30StudentBlocker: {
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.dependentPreQuote.under30StudentBlocker.title',
            'Expat health insurance might not be the best choice for them'
          ),
          description: t(
            'expat.qnr.dependentPreQuote.under30StudentBlocker.description',
            {
              defaultValue:
                'We strongly recommend students sign up for public health insurance. They will get a discounted price until they are 30 years old and it is their chance to get into the public healthcare system in Germany.\n\nExpat health insurance is only a short-term option for new arrivals in Germany. It does not provide full health insurance coverage or cover pre-existing conditions.\n\nIf they still decide to go with expat health insurance, they will need to [request an exemption]({{link}}) from a public health insurance provider. They will only be eligible for public health insurance again if they start working or switch study programs.',
              link: faq.studentHealthStatusConfirmation,
            }
          ),
          buttonProps: [
            {
              type: 'href',
              href: insurance.publicHealth.landingPage,
              caption: t(
                'expat.qnr.dependentPreQuote.under30StudentBlocker.exploreButton.cta',
                'Explore public health insurance'
              ),
            },
            {
              variant: 'SECONDARY',
              type: 'button',
              onClick: () => props.onSubmitValue(),
              caption: t(
                'expat.qnr.under30StudentBlocker.secondaryButton.cta',
                'Continue application'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      retrieveNextPageId: (answer) => {
        if (answer === undefined) {
          return 'quote';
        }
        return 'under30StudentBlocker';
      },
      groupId: 'prequote',
    },
    isProfessionalAthleteBlocker: {
      required: (answers) => {
        if (answers.occupation) {
          return (
            answers.occupation === 'PROFESSIONAL_ATHLETE' &&
            answers.isProfessionalDancer === false
          );
        }
        return false;
      },
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.athleteBlocker.dependent.title',
            'We can’t sign them up'
          ),
          description: t(
            'expat.qnr.athleteBlocker.description',
            'Unfortunately, professional athletes, are not covered by expat health insurance.\n\nWe’re working hard to make our policies cover all use cases, but still have certain limitations.'
          ),
          buttonProps: [
            {
              type: 'href',
              href: website.support,
              caption: t('expat.qnr.athleteBlocker.button.cta', 'Speak to us'),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      groupId: 'prequote',
    },
    isProfessionalDancerBlocker: {
      required: (answers) => {
        return answers.isProfessionalDancer ?? false;
      },
      type: 'CUSTOM',
      component: (props) =>
        Blocker({
          title: t(
            'expat.qnr.nonAcceptedForEmploymentBlocker.title',
            'We have another option for you'
          ),
          description: t(
            'expat.qnr.isProfessionalDancerBlocker.description',
            'As a professional dancer, you are not eligible for expat health insurance.\n\nYou can explore long-term expat health insurance as an alternative.'
          ),
          buttonProps: [
            {
              type: 'href',
              href: insurance.longTermExpatHealth.landingPage,
              caption: t(
                'expat.qnr.moreThanFiveYearsInGermany.exploreLongTermExpatButton.cta',
                'Explore long-term expat health'
              ),
            },
          ],
          iconType: 'SHIELD',
          ...props,
        }),
      groupId: 'prequote',
    },
    isProfessionalDancer: {
      required: (answers) => {
        return answers.occupation === 'PROFESSIONAL_ATHLETE';
      },
      type: 'BOOLEAN',
      props: {
        question: t(
          'expat.qnr.preQuote.isProfessionalDancer.title',
          'Are you a professional dancer?'
        ),
      },
      groupId: 'prequote',
      retrieveAnswerObject: (
        isProfessionalDancer: boolean
      ): Partial<ExpatDependentQuestionnaire> => ({
        isProfessionalDancer,
      }),
      retrieveNextPageId: (answer: Occupation) => {
        if (answer) {
          return 'isProfessionalDancerBlocker';
        }
        return 'isProfessionalAthleteBlocker';
      },
    },
  };
};
