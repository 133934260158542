import { ChevronUpIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getClaimTypeMapping } from 'features/claimsV2/utils';
import { Claim } from 'models/claims';
import AnimateHeight from 'react-animate-height';
import { TFunction } from 'shared/i18n';

import styles from './ClaimDetail.module.scss';
import checkedIcon from './icons/checked.svg';

export const ClaimDescription = ({
  claim,
  t,
  isClaimSubmittedOpen,
  setIsClaimSubmittedOpen,
}: {
  claim: Claim;
  t: TFunction;
  isClaimSubmittedOpen: boolean;
  setIsClaimSubmittedOpen: (open: boolean) => void;
}) => {
  const { claimTypes, insuranceType, details, iban, dateOfEvent } = claim;
  const hasClaimTypes = claimTypes && claimTypes.length > 0;

  const claimDisplayType = hasClaimTypes
    ? claimTypes
        .map(({ claimType }) =>
          getClaimTypeMapping(t, { insuranceType, claimType })
        )
        .join(', ')
    : null;
  const claimDisplayAmount = claim.amount;
  return (
    <div className="ds-card ds-card--no-dropshadow mt24 p24 gap24">
      <button
        type="button"
        className="bg-white c-pointer d-flex df-row ai-start w100"
        onClick={() => setIsClaimSubmittedOpen(!isClaimSubmittedOpen)}
      >
        <span className={classNames('ai-center mr16', styles.row)}>
          <img src={checkedIcon} alt="Checked" />
        </span>
        <span className={classNames('p-h3 w100 jc-between', styles.row)}>
          {t('claims.detail.claimsSubmitted.title', 'Claims submitted')}

          <ChevronUpIcon
            className={
              isClaimSubmittedOpen ? styles.chevronOpen : styles.chevronClosed
            }
            color="primary-500"
            size={24}
            noMargin
          />
        </span>
      </button>
      <div className="d-flex fd-column w100">
        <AnimateHeight
          duration={300}
          height={isClaimSubmittedOpen ? 'auto' : 0}
        >
          {claimDisplayType && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.claimType', 'Claim type')}
              </div>
              <div className="w70 p-p">{claimDisplayType}</div>
            </div>
          )}
          {dateOfEvent && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.eventDate', 'Event date')}
              </div>
              <div className="w70 p-p">
                {dayjs(dateOfEvent).format('DD MMM YYYY')}
              </div>
            </div>
          )}
          {!['INCOMING', 'DENTAL'].includes(insuranceType) && (
            <div className="d-flex d-row mt24 ai-center">
              <div className="w30 mr24 p-p tc-grey-500">
                {t('claims.detail.eventDescription', 'Event description')}
              </div>
              <div className="w70 p-p">{details}</div>
            </div>
          )}
          {claimDisplayAmount !== 0 && (
            <>
              <div
                className={classNames(
                  'bg-grey-200 my32',
                  styles['vertical-line']
                )}
              />
              <div className="d-flex d-row mt24">
                <div className="w30 mr24 p-p tc-grey-500">
                  {t('claims.detail.claimedAmount', 'Claimed amount')}
                </div>
                <div className="w70 p-p">€{claimDisplayAmount}</div>
              </div>
              <div className="d-flex d-row mt24">
                <div className="w30 mr24 p-p tc-grey-500">
                  {t('claims.detail.ibanForPayout', 'IBAN for payout')}
                </div>
                <div className="w70 p-p">{iban}</div>
              </div>
            </>
          )}
        </AnimateHeight>
      </div>
    </div>
  );
};
