import { TFunction } from '@getpopsure/i18n-react';
import { isEEACountry } from '@getpopsure/public-models';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CreateQuoteReturnType,
  QuestionnaireTitles,
  QuestionnaireType,
  questionOrder,
  QuoteResponse,
  SubmitQuestionnaireReturnType,
  SubmittablePensionQuestionnaire,
  SubmittablePensionQuote,
  UpdateableQuestionnaire,
  ZPensionQuestionnaireGrouping,
  ZQuoteSchema,
  ZSubmittablePensionQuestionnaire,
  ZUpdateablePensionQuestionnaire,
} from '../models';
import { createLabelTitles, labelAnswers } from '../util';

export const createPensionQuote = (answers: SubmittablePensionQuote) => {
  return endpoint.network.post<QuoteResponse>(
    '/signups/pension/quotes',
    answers
  );
};

const createPensionQuestionnaire = async (
  answers: SubmittablePensionQuestionnaire,
  questionnaireType: QuestionnaireType,
  labelTitles: QuestionnaireTitles,
  answerLabels: Record<string, unknown>,
  uploadTokens: string[]
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{
    id: string;
    answers: SubmittablePensionQuestionnaire;
  }>('/questionnaires/', {
    answers,
    questionnaireType,
    metadata: {
      labels: {
        title: labelTitles,
        answers: answerLabels,
      },
      questionOrder,
      source,
    },
    uploadTokens,
  });
};

const updatePensionQuestionnaire = async (
  answers: SubmittablePensionQuestionnaire,
  questionnaireId: string
) => {
  return endpoint.network.patch<{
    id: string;
    answers: SubmittablePensionQuestionnaire;
  }>(`/questionnaires/${questionnaireId}`, {
    answers,
    questionnaireType: 'PENSION',
  });
};

const submitApplication = async (questionnaireId: string, quoteId: string) => {
  return endpoint.network.post('/signups/pension/application', {
    questionnaireId,
    quoteId,
  });
};

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'pension'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'pension'>,
    getState: () => AppState
  ) => {
    const requestType = 'CREATE_POST_QUOTE_SUBMIT_INFO';
    dispatch(setRequestInProcess(true, requestType));

    const questionnaire = getGenericQuestionnaire(getState()).pension || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[PENSION] Quote not defined`);
      }

      const quoteValidation = ZQuoteSchema.safeParse(questionnaire.quote);

      if (!quoteValidation.success) {
        throw new Error(
          `[PENSION - Submission] Questionnaire quote errors: ${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;
      const { initialAmount, monthlyPayment } = validatedQuote;

      const {
        data: { id: quoteId },
      } = await createPensionQuote({ initialAmount, monthlyPayment });

      const questionnaireValidation =
        ZPensionQuestionnaireGrouping.safeParse(questionnaire);

      if (!questionnaireValidation.success) {
        throw new Error(
          `[PENSION - Submission] Questionnaire answer validation errors: ${questionnaireValidation.error.toString()}`
        );
      }
      const validatedAnswers = questionnaireValidation.data;

      const {
        personalInfo: {
          name: { firstName, lastName },
          email,
          uploadPassport,
          uploadIdCard,
          identificationDocument,
          citizenship,
        },
      } = validatedAnswers;

      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
          })
        );
      }

      const hasPassport =
        !isEEACountry(citizenship) || identificationDocument === 'PASSPORT';

      const identificationDocumentFile = hasPassport
        ? uploadPassport
        : uploadIdCard;

      if (!identificationDocumentFile) {
        throw new Error(
          `[PENSION - Submission] Identification document token is missing`
        );
      }

      const uploadTokens = identificationDocumentFile
        .map((file) => file.token)
        .filter((token) => token !== undefined);

      const submittableAnswers =
        ZSubmittablePensionQuestionnaire.safeParse(questionnaire);

      if (!submittableAnswers.success) {
        throw new Error(
          `[PENSION - Submission] Submittable answers validation errors: ${submittableAnswers.error.toString()}`
        );
      }

      const submittableQuestionnaire = submittableAnswers.data;

      const answerLabels = labelAnswers(t);
      const labelTitles = createLabelTitles(
        // UpdateableQuestionnaire is a superset of SubmittablePensionQuestionnaire with a legal disclaimer object added
        submittableQuestionnaire as UpdateableQuestionnaire,
        t
      );

      const {
        data: { id: questionnaireId },
      } = await createPensionQuestionnaire(
        submittableQuestionnaire,
        'PENSION',
        labelTitles,
        answerLabels,
        uploadTokens
      );

      dispatch(
        storeGenericQuestionnaireAnswer('pension', {
          quoteId,
          questionnaireId,
          quote: { ...questionnaire.quote, id: quoteId },
        })
      );

      await generateCheckoutDocuments(questionnaireId, quoteId, 'PENSION', t);

      dispatch(setRequestInProcess(false, requestType));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));

      Sentry.captureException(error, {
        tags: {
          feature: 'SIGNUP',
          vertical: 'PENSION',
        },
        extra: {
          description: 'Failed to submit pension quote and questionnaire',
        },
      });
      return { status: 'ERROR' };
    }
  };

export const reviewAction =
  (): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'pension'> | RequestAction
  > =>
  async () => {
    return { status: 'SUCCESS' };
  };

export type SubmitApplicationDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'pension'> | RequestAction
>;

export const submitApplicationAction =
  (): ThunkAction<
    Promise<SubmitQuestionnaireReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'pension'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'pension'>,
    getState: () => AppState
  ) => {
    const requestType = 'PENSION_SUBMIT_APPLICATION';
    dispatch(setRequestInProcess(true, requestType));

    try {
      const questionnaire = getGenericQuestionnaire(getState()).pension || {};

      const questionnaireWithLegalDisclaimer = {
        ...questionnaire,
        riskConsent: true,
        shortTermLossConsent: true,
        hasReviewedDocuments: true,
      };

      if (!questionnaire.questionnaireId) {
        throw new Error(
          '[PENSION - Submit Application] Missing questionnaire id'
        );
      }

      if (!questionnaire.quoteId) {
        throw new Error(
          '[PENSION - Submit Application] Missing quote id for update'
        );
      }

      const questionnaireValidation = ZUpdateablePensionQuestionnaire.safeParse(
        questionnaireWithLegalDisclaimer
      );

      if (!questionnaireValidation.success) {
        throw new Error(
          `[PENSION - Submit Application] Questionnaire answer validation errors: ${questionnaireValidation.error.toString()}`
        );
      }

      const validatedAnswers = questionnaireValidation.data;
      const { questionnaireId, quoteId } = questionnaire;
      await updatePensionQuestionnaire(validatedAnswers, questionnaireId);

      await submitApplication(questionnaireId, quoteId);

      dispatch(setRequestInProcess(false, requestType));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
      Sentry.captureException(error, {
        tags: {
          feature: 'SIGNUP',
          vertical: 'PENSION',
        },
        extra: {
          description: 'Failed to submit pension application',
        },
      });
      return { status: 'ERROR' };
    }
  };
