import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RequestStatus, RequestStatusHandler } from 'shared/models/types';

import {
  updateAndCompleteStudentChecklist,
  updatePolicyDetails,
} from '../actions';
import { StudentChecklistRequest } from '../models';

type UpdateChecklistFn = (
  userPolicyId: string,
  checklist: StudentChecklistRequest
) => Promise<void>;

export const useUpdateChecklist =
  (): RequestStatusHandler<UpdateChecklistFn> => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState<RequestStatus>({
      type: 'INITIAL',
    });

    const reset = () => {
      setStatus({
        type: 'INITIAL',
      });
    };

    const updateChecklist = async (
      userPolicyId: string,
      checklist: StudentChecklistRequest
    ) => {
      setStatus({ type: 'LOADING' });

      try {
        await updateAndCompleteStudentChecklist(userPolicyId, checklist);
        await dispatch(updatePolicyDetails(userPolicyId));
        setStatus({
          type: 'SUCCESS',
        });
      } catch (error) {
        setStatus({ type: 'ERROR', error });

        Sentry.captureException(error, {
          tags: {
            feature: 'STUDENT_CHECKLIST',
            vertical: 'PUBLIC_HEALTH',
          },
          extra: {
            userPolicyId,
            checklist,
            description: 'Error happened trying to update student checklist',
          },
        });
      }
    };

    return {
      requestFn: updateChecklist,
      reset,
      error: status.error,
      isError: status.type === 'ERROR',
      isLoading: status.type === 'LOADING',
      isSuccess: status.type === 'SUCCESS',
    };
  };
