import { TFunction } from '@getpopsure/i18n-react';
import {
  BriefcaseIcon,
  CalendarIcon,
  CarTrafficIcon,
  CheckSquareIcon,
  ClockTimeIcon,
  EuroIcon,
  FileTextIcon,
  HomeIcon,
  LawyerLegalIcon,
  PhoneIcon,
  PlaneFlightIcon,
  ShieldIcon,
  WorldGlobeEarthIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const legal = (t: TFunction): AdditionalInfoType => ({
  price: 13.51,
  description: t(
    'policyModal.legal.description',
    'Disputes arise when we expect them least, and legal bills can be costly. Legal insurance covers lawyer fees, court costs, costs for expert fees, and out-of-court dispute resolution procedures such as mediation.'
  ),
  accordions: [
    {
      title: t('policyModal.legal.title1', "What's covered?"),
      items: [
        {
          id: 'covered-1',
          icon: <FileTextIcon size={24} />,
          question: t(
            'policyModal.legal.covered1.question',
            'Professional matters'
          ),
          answer: t(
            'policyModal.legal.covered1.answer',
            'Disputes related to your employment. E.g. wrongful termination, termination agreement, or your rights during maternity leave.'
          ),
        },
        {
          id: 'covered-2',
          icon: <BriefcaseIcon size={24} />,
          question: t(
            'policyModal.legal.covered2.question',
            'Personal matters'
          ),
          answer: t(
            'policyModal.legal.covered2.answer',
            'E.g. contractual issues with your gym membership or instances of your phone provider overcharging you.'
          ),
        },
        {
          id: 'covered-3',
          icon: <CarTrafficIcon size={24} />,
          question: t('policyModal.legal.covered3.question', 'Traffic'),
          answer: t(
            'policyModal.legal.covered3.answer',
            "Legal protection for bike or car accidents, unjustified parking fines or accusations, deciding who is at fault for an accident, and hit-and-run cases where you're the victim."
          ),
        },
      ],
    },
    {
      title: t('policyModal.legal.title2', 'Add-ons'),
      items: [
        {
          id: 'addons-1',
          icon: <HomeIcon size={24} />,
          question: t('policyModal.legal.addons1.question', 'Home'),
          answer: t(
            'policyModal.legal.addons1.answer',
            'Additional coverage for disputes that arise as a tenant or homeowner. E.g. issues with rental contracts, rent increases, property management, or noise complaints.'
          ),
        },
        {
          id: 'addons-2',
          icon: <LawyerLegalIcon size={24} />,
          question: t(
            'policyModal.legal.addons2.question',
            'Criminal protection'
          ),
          answer: t(
            'policyModal.legal.addons2.answer',
            'Coverage to defend yourself against the false accusations of criminal negligence and offenses like theft, embezzlement, and fraud if you are found not guilty.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.legal.title3', "What's not covered"),
      items: [
        {
          id: 'not-covered-1',
          icon: <PlaneFlightIcon size={24} />,
          question: t(
            'policyModal.legal.notCovered1.question',
            'Immigration matters'
          ),
          answer: t(
            'policyModal.legal.notCovered1.answer',
            'Matters relating to immigration, visas, and residence permits cannot be covered by the policy.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <FileTextIcon size={24} />,
          question: t(
            'policyModal.legal.notCovered2.question',
            'Reviewing contracts as a preventive measure'
          ),
          answer: t(
            'policyModal.legal.notCovered2.answer',
            'Legal insurance is a damage insurance rather than a preventative one. As such, coverage is only provided if there is a breach or damage.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <BriefcaseIcon size={24} />,
          question: t(
            'policyModal.legal.notCovered3.question',
            'Self-employment and freelancing'
          ),
          answer: t(
            'policyModal.legal.notCovered3.answer',
            'The policy does not cover business activities for self-employment as a main occupation or activities associated with running a business. Self-employment as a secondary job is covered on our advanced plan.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <ClockTimeIcon size={24} />,
          question: t(
            'policyModal.legal.notCovered4.question',
            'Anticipated, ongoing, or occurred legal problems'
          ),
          answer: t(
            'policyModal.legal.notCovered4.answer',
            'Any matter for which the initial cause of the issue happened in the past or before the end of the respective waiting period is not covered.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.legal.title4', 'Important to know'),
      items: [
        {
          id: 'important-1',
          icon: <PhoneIcon size={24} />,
          question: t(
            'policyModal.legal.important1.question',
            'Free consultations'
          ),
          answer: t(
            'policyModal.legal.important1.answer',
            'When a legal issue arises, you’ll have access to an initial phone consultation with a lawyer to go through your case. The deductible only applies for insured legal matter, so the initial phone consultation is always free.'
          ),
        },
        {
          id: 'important-2',
          icon: <ClockTimeIcon size={24} />,
          question: t(
            'policyModal.legal.important2.question',
            'Waiting periods'
          ),
          answer: t(
            'policyModal.legal.important2.answer',
            'Waiting periods vary depending on the area of law and the plan you choose. This means you have to wait some time after the policy start date for the coverage to come into effect.'
          ),
        },
        {
          id: 'important-3',
          icon: <CheckSquareIcon size={24} />,
          question: t(
            'policyModal.legal.important3.question',
            'Claims process'
          ),
          answer: t(
            'policyModal.legal.important3.answer',
            'If you need to hire a lawyer, the first step is to submit a claim through your account. The claim will be reviewed to see if the legal costs for your case will be covered. If your claim is approved, we can recommend an English-speaking lawyer from our network. However, you’re always free to choose your lawyer and will be covered up to the standard amounts according to the Lawyers’ Fees Act (Rechtsanwaltsvergütungsgesetz). Just keep in mind that it is your responsibility to hire legal counsel, and recommended lawyers are never obliged to take on your case.'
          ),
        },
        {
          id: 'important-4',
          icon: <EuroIcon size={24} />,
          question: t('policyModal.legal.important4.question', 'Deductible'),
          answer: t(
            'policyModal.legal.important4.answer',
            'A deductible is the amount you have to pay once for every insured legal claim. This deductible only applies for insured legal matter, so the initial phone consultations are always free.\n\nThe deductible usually starts at €300, but might be lower if you held legal insurance in Germany in the past 5 years. After the first claim is paid out, the deductible increases to €500, and reduces by €100 every claim-free insurance year. If your claim can be solved below €250 no deductible has to be paid.'
          ),
        },
        {
          id: 'important-5',
          icon: <CalendarIcon size={24} />,
          question: t(
            'policyModal.legal.important5.question',
            '1 month cancellation period'
          ),
          answer: t(
            'policyModal.legal.important5.answer',
            'Your plan can be canceled in your account with one full month’s notice, so you will have your plan for the end of the current month plus the next.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: ShieldIcon,
      title: t('policyModal.legal.details.title1', 'Money back guarantee'),
      description: t(
        'policyModal.legal.details.description1',
        '14 or 60 days based on the plan'
      ),
    },
    {
      icon: WorldGlobeEarthIcon,
      title: t('policyModal.legal.details.title2', 'Worldwide coverage'),
      description: t(
        'policyModal.legal.details.description2',
        '€250,000 or €500,000 based on the plan'
      ),
    },
  ],
  buttonText: t('policyModal.legal.actionButton', 'Continue to quote'),
});
