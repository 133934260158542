import { illustrations } from '@popsure/dirty-swan';
import { imageTypeMapping } from 'models/insurances/types/mapping';

import { ExtendedQuestionType } from './models';

export const getSettingsForQuestionType = (type: ExtendedQuestionType) => {
  switch (type) {
    case 'INTRO':
      return {
        title: "Hey! Let's get you a quote in minutes",
        subTitle:
          'We need a few pieces of information about you and we can figure out the rest.',
      };

    case 'INPUT':
      return {
        placeholder: 'Placeholder!',
        label: 'Label',
      };

    case 'ADDRESS':
      return {
        placeholders: {
          manualAddressEntry: 'Search for address',
          street: 'Street',
          houseNumber: 'House number',
          additionalInformation: 'Additional information (C/O, apartment, ...)',
          postcode: 'Postcode',
          city: 'City',
        },
        manualAddressEntryTexts: {
          preText: 'Or',
          cta: 'enter address manually',
        },
        configuration: {
          googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
        },
      };

    case 'BLOCKER':
      return {
        title: 'This is a blocker title',
        description: 'This can be a description',
        additionalInfo: 'Here is additional information that can be added',
        paragraphClassName: '',
        iconType: 'PERSON',
        buttonProps: [],
      };

    case 'CURRENCY':
      return {
        placeholder: '100',
        label: 'Amount',
      };

    case 'NUMBER':
      return {
        placeholder: 'Amount',
      };

    case 'TEXT':
      return {
        placeholder: 'Begin typing here...',
        maxLength: 400,
      };

    case 'DATE':
      return {
        yearRange: {
          min: 2020,
          max: 2030,
        },
        placeholders: {
          day: 'Day',
          month: 'Month',
          year: 'Year',
        },
      };

    case 'RADIO':
      return {
        mapValue: {
          1: 'None',
          2: '1 tooth',
          3: '2 teeth',
          4: '3 teeth',
        },
      };

    case 'CHECKBOX':
      return {
        mapValue: {
          1: 'Smoke detector',
          2: 'Security system',
          3: 'Security camera',
          4: 'None of the above',
        },
      };

    case 'QUOTEPAGE':
      return {
        config: {
          title: 'Here is your quote',
          headerImage: {
            src: imageTypeMapping.DOG_LIABILITY,
            alt: 'A white dog playing with a toy',
          },
          priceHeader: { title: '{{value}}', subTitle: 'per month' },
          actions: {
            items: [
              { type: 'SubHeader', text: 'Deductible' },
              {
                type: 'Description',
                text: 'The deductible is the amount you pay out of pocket per insured event before the insurance starts to pay. For instance, if you choose a deductible of €150 and submit a claim for a damage of €2,000, you will pay €150 and we will pay the remaining €1,850.',
              },
              {
                type: 'Toggle',
                mapValues: {
                  0: '€0',
                  1: '€150',
                },
              },
            ],
            valuesMap: {
              0: '6.90',
              1: '5.58',
            },
            submitMap: [
              {
                id: '1',
                price: 6.9,
                plan: 'NON_DEDUCTIBLE',
                isDeductible: false,
                deductibleAmount: 0,
              },
              {
                id: '2',
                price: 5.58,
                plan: 'DEDUCTIBLE',
                isDeductible: true,
                deductibleAmount: 150,
              },
            ],
            button: {
              text: 'Get covered',
            },
          },
          coveredSection: {
            title: "What's covered?",
            layout: { compact: false, cards: true },
            items: [
              {
                title: 'Medical injuries to other people',
                description:
                  'If your dog gets excited and runs into someone, causing them to fall and hurt themselves, your policy will cover the expenses.',
                icon: {
                  src: illustrations.aids,
                  alt: 'First aid kit bag',
                },
              },
              {
                title: 'Damage to rented apartments',
                description:
                  'Did your dog decide to chew up your rented furniture? Relax and keep your security deposit when you move out!',
                icon: {
                  src: illustrations.bed,
                  alt: 'Bed',
                },
              },
              {
                title: 'Property damage',
                description:
                  "You'll be covered if your dog damages someone's belongings (E,g, your friends carpet or laptop)",
                icon: {
                  src: illustrations.damagedLaptop,
                  alt: 'Damaged laptop',
                },
              },
              {
                title: 'Financial loss',
                description:
                  "If your dog bites the mailperson and can't work after that, dog liability insurance will cover you.",
                icon: {
                  src: illustrations.moneyIncome,
                  alt: 'Money Income',
                },
              },
            ],
          },
          notCoveredSection: {
            title: "What's not covered?",
            items: [
              {
                title: 'Damage to your own property',
                description:
                  'Replacement or repair of things you own is not covered by dog liability.',
                icon: {
                  src: illustrations.brokenGlass,
                  alt: 'Broken glass',
                },
              },
              {
                title: 'Rented electronics',
                description:
                  "If your dog damages an electronic device you rented from someone else, it's not covered.",
                icon: {
                  src: illustrations.washingMachine,
                  alt: 'Washing machine',
                },
              },
              {
                title: 'Broken glass in your own home',
                description:
                  "If you're renting a flat, glass breakage isn't covered. You can get [household insurance](https://feather-insurance.com/household-insurance) to cover glass.",
                icon: {
                  src: illustrations.brokenAquarium,
                  alt: 'Broken aquarium',
                },
              },
              {
                title: 'Dangerous breeds',
                description:
                  "Breeds that might be considered to be dangerous can't be covered. Check the complete list.",
                icon: {
                  src: illustrations.bigDog,
                  alt: 'Dog',
                },
              },
            ],
          },
          addons: [
            {
              id: 'addonOne',
              title: 'Additional insurance package',
              description:
                'Additional coverage for you to add to your basic insurance.\n\nMake sure to have a look into the details of this additional package!',
              price: 33.44,
              link: '',
            },
          ],
          reviewBadge: true,
          ctaBox: {
            header: 'Dog liability insurance',
            title: '€1,23',
            subTitle: 'per month',
            buttonText: 'Get covered',
          },
          faq: {
            title: 'Frequently asked questions',
            data: [
              {
                questionId: 1,
                question:
                  'My dog is considered to be a “dangerous” breed can I still be covered?',
                answer:
                  'Some dog breeds which are considered “dangerous” can’t be covered through Feather but other insurers may still cover them. Policies for such dog breeds are typically a bit more expensive.',
              },
              {
                questionId: 2,
                question:
                  'Do I need dog liability insurance if I already have personal liability insurance?',
                answer:
                  'Personal liability insurance covers small animals like cats or birds but dogs usually aren’t covered. This is why we recommend getting dog liability insurance in addition to personal liability insurance.',
              },
              {
                questionId: 3,
                question: 'What happens if my dog has puppies?',
                answer:
                  'Your dog’s puppies are automatically covered by dog liability insurance until they turn 1 year old or when they permanently leave your household, whichever happens sooner. ',
              },
            ],
            footer: `See our [FAQ page](https://faq.feather-insurance.com/en/) for more answers.`,
          },
          moreQuestionsSection: {
            bookACallGACategory: 'Dog',
            title: 'Have more questions?',
            bookACallUrl: '',
            secondaryActionLink: 'https://faq.feather-insurance.com/en/',
            secondaryAction: 'Visit our FAQ',
          },
        },
      };

    case 'REVIEW':
      return {
        reviewValues: [],
      };

    default:
      return {};
  }
};
