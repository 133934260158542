import { trackStructuredEvent } from '@getpopsure/analytics';
import { email } from '@getpopsure/private-constants';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useUpdateChecklist } from 'features/studentChecklist/hooks/useUpdateChecklist';
import { StepProps } from 'features/studentChecklist/models';
import { getStudentChecklist } from 'features/studentChecklist/selectors';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import style from './BankAccount.module.scss';
import { BankAccountModal } from './components';

export const BankAccount = ({ completed, submittedChecklist }: StepProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { policyId } = useParams<{ policyId: string }>();
  const initialIban = useSelector(getStudentChecklist(policyId))?.iban;
  const { t } = useSafeTranslation();
  const {
    requestFn: updateChecklist,
    reset,
    isLoading,
    isError,
  } = useUpdateChecklist();

  const errorMessage = isError
    ? t(
        'studentChecklist.bankAccount.error.message',
        'Student checklist could not be updated with IBAN details. Please try again or contact us at {{supportEmail}}.',
        { supportEmail: email.help }
      )
    : undefined;

  useEffect(() => {
    if (isError || isLoading) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }

    return () => {
      setOpenModal(false);
    };
  }, [isError, isLoading]);

  const onOpen = () => setOpenModal(true);

  const handleSubmit = async (iban: string) => {
    trackStructuredEvent({
      category: 'STUDENT_CHECKLIST',
      action: 'SUBMITTED_BANK_ACCOUNT',
    });

    updateChecklist(policyId, { iban });
  };

  if (submittedChecklist) return null;

  /**
   * Student Checklist does not allow edition of submitted data
   */
  if (completed) return null;

  return (
    <>
      <p className="p-p p-p--small tc-grey-500">
        {t(
          'studentChecklist.bankAccount.description',
          "If you're not in Germany yet, you can provide it when you arrive."
        )}
      </p>
      <Button
        data-testid="bank-account"
        className={classNames('w100 wmx3 mt8', style.button)}
        onClick={onOpen}
      >
        {t('studentChecklist.bankAccount.button.title', 'Enter IBAN')}
      </Button>

      {openModal &&
        createPortal(
          <BankAccountModal
            onClose={() => {
              reset();
              setOpenModal(false);
            }}
            onSubmit={handleSubmit}
            initialIban={initialIban}
            loading={isLoading}
            error={errorMessage}
          />,
          document.body
        )}
    </>
  );
};
