import { calendly, faq } from '@getpopsure/private-constants';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Card, InfoCard } from '@popsure/dirty-swan';
import classNames from 'classnames';
import FAQSection from 'components/faq';
import Markdown from 'components/markdown';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import SectionHeader from 'components/sectionHeader';
import { Pension, Quote as QuoteType } from 'features/pension/models';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { Trans, useSafeTranslation } from 'shared/i18n';

import { Calculator } from '../Calculator';
import { considerations, faqData, whyFeather } from './content';
import styles from './Quote.module.scss';

export const Quote = ({
  onSubmitValue,
  value,
  ...props
}: QuestionnaireFormProps<Pension, QuoteType>) => {
  const { t } = useSafeTranslation();
  return (
    <div>
      <header
        className={classNames(
          'p-body d-flex ai-center fd-column pt40',
          styles.title
        )}
      >
        <img
          src={imageTypeMapping.PENSION}
          className={classNames(styles.headerImg, 'mxauto')}
          alt={t(
            'pension.qnr.quote.header.image.alt',
            'A palm tree and a sunbed'
          )}
        />
        <h1 className="p-h1 p--serif">
          {t('pension.qnr.quote.title', 'Calculate your pension')}
        </h1>
      </header>
      <Calculator onSubmitValue={onSubmitValue} value={value} {...props} />
      <div className="bg-white">
        <section
          className={classNames(
            'pt56 pb80 p-body',
            styles.considerationsContainer
          )}
        >
          <SectionHeader
            centeredOnMobile
            title={t(
              'pension.qnr.quote.considerations.title',
              'Important considerations'
            )}
          />
          <div className="d-flex f-wrap jc-center mt32 gap16">
            {considerations(t).map(({ title, icon, description }) => (
              <InfoCard
                key={title}
                className="wmx6"
                title={title}
                topIcon={icon}
                state="static"
              >
                <div className="p-p tc-grey-600 ta-center">
                  <Markdown openLinksInNewTab={true}>{description}</Markdown>
                </div>
              </InfoCard>
            ))}
          </div>
        </section>
        <section className={classNames('pb80 p-container')}>
          <SectionHeader
            centeredOnMobile
            title={t('pension.qnr.quote.whyFeather.title', 'Why Feather?')}
          />

          <div
            className={classNames(
              styles.whyFeatherContainer,
              'd-grid gap16 mt32'
            )}
          >
            {whyFeather(t).map(({ title, description, icon }) => (
              <Card
                key={title}
                title={title}
                description={description}
                icon={icon}
                dropShadow={false}
                verticalAlignment="top"
                classNames={{ wrapper: styles.card }}
              />
            ))}
          </div>
        </section>
        <section className={classNames('pb80 p-container d-flex jc-center')}>
          <ReviewBadge />
        </section>
        <section className="p-body pb80">
          <FAQSection data={faqData(t)} />
          <Trans i18nKey="pension.qnr.quote.faq.linksection">
            <div className="p-p tc-grey-600 mt32">
              See our{' '}
              <a
                href={faq.pensionInsurance}
                className="p-a"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ page
              </a>{' '}
              for more answers.
            </div>
          </Trans>
        </section>
        <section>
          <MoreQuestionsSection
            description={t(
              'pension.qnr.quote.moreQuestions.description',
              'Reach out to us for more information or check out our FAQ for answers to common questions about pension insurance.'
            )}
            bookACallUrl={calendly.pensionInsurance}
            secondaryAction={t(
              'pension.qnr.quote.moreQuestions.faq.cta',
              'Visit our FAQ'
            )}
            secondaryActionLink={faq.pensionInsurance}
          />
        </section>
      </div>
    </div>
  );
};
