import { website } from '@getpopsure/private-constants';
import { useQuery } from '@tanstack/react-query';
import { ErrorWithAction } from 'components/ErrorWithAction';
import TimedLoadSpinner from 'components/timedLoadSpinner';
import { useParams } from 'react-router';
import api from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';

import { ClaimDetailWithTaskContext } from './ClaimDetail.view';

export const ClaimDetail = () => {
  const { t } = useSafeTranslation();
  const { claimId } = useParams<{ claimId: string }>();
  const { data, isLoading, isError } = useQuery(['claim', claimId], {
    queryFn: () => api.getCurrentClaim(claimId),
  });

  if (isLoading || (!data?.data && !isError)) {
    return <TimedLoadSpinner />;
  }

  if (isError) {
    return (
      <ErrorWithAction
        title={t('claims.detail.error.title', 'Something went wrong')}
        description={t(
          'claims.detail.error.description',
          'Sorry, there was a problem loading your claim. If the problem persists, please contact support.'
        )}
        cta={{
          title: t('claims.detail.error.button.title', 'Contact support'),
          onClick: () => {
            window.location.href = website.support;
            return null;
          },
        }}
      />
    );
  }

  return <ClaimDetailWithTaskContext claim={data?.data} t={t} />;
};
