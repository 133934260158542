import LoadingSpinner from 'components/loadingSpinner';
import {
  MailingListResponse,
  UpdateMailingPreference,
} from 'models/mailingPreferences';

import MailingPreferencesView from './MailingPreferences.view';
import { useGetMailingPreferences } from './useGetMailingPreferences';
import { useUpdateMailingPreferences } from './useUpdateMailingPreferences';

export const MailingPreferences = () => {
  const { isLoading, data, error } = useGetMailingPreferences();
  const mutation = useUpdateMailingPreferences();

  const updateMailingPreferences = async (
    options: UpdateMailingPreference
  ): Promise<MailingListResponse> => {
    return mutation.mutateAsync(options);
  };

  if (isLoading || !data) {
    return <LoadingSpinner />;
  }

  return (
    <MailingPreferencesView
      mailingPreferences={data}
      updateMailingPreferences={updateMailingPreferences}
      error={error}
    />
  );
};
