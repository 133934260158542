import { TFunction } from '@getpopsure/i18n-react';
import { getRoutes, Rule } from '@getpopsure/qnr-framework';
import routes from 'constants/routes';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import { useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { LegalClaimsAndConsultations, LegalClaimType } from './models';

export const LegalClaimsAndConsultationsComponents = {} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  LegalClaimsAndConsultations,
  'preQuestionnaire',
  typeof LegalClaimsAndConsultationsComponents
>;

const mapClaimType = (
  t: TFunction
): Record<LegalClaimType, { title: string; description: string }> => ({
  BOOK_A_CONSULTATION: {
    title: t(
      'legal.qnr.claims.claimType.bookPhoneConsultation.btn.title',
      'Book a phone consultation'
    ),
    description: t(
      'legal.qnr.claims.claimType.bookPhoneConsultation.btn.description',
      '10-minute consultation with a lawyer (usually within 2 business days) to see if you have a valid legal case, without getting into details or reviewing documents.'
    ),
  },
  LEGAL_CLAIM: {
    title: t(
      'legal.qnr.claims.claimType.submitClaim.btn.title',
      'Submit a claim'
    ),
    description: t(
      'legal.qnr.claims.claimType.submitClaim.btn.description',
      "In-depth review of your legal matter and documents. You'll get confirmation that your legal case can be covered before hiring a mediator or lawyer."
    ),
  },
});

export const getTranslatedQuestionnaire = (
  t: TFunction
): ClaimQuestionnaire => [
  {
    id: 'claimType',
    required: true,
    type: 'RADIO',
    groupId: 'preQuestionnaire',
    props: {
      mapValue: mapClaimType(t),
    },
    screen: {
      question: "Let's get started",
    },
  },
];

const rules: Rule<LegalClaimsAndConsultations>[] = [];

export const ClaimsAndConsultations = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const { policyId } = useParams<{ policyId: string }>();
  const { url } = useRouteMatch();

  const onAnswer = <QuestionId extends keyof LegalClaimsAndConsultations>(
    questionId: QuestionId,
    answer: LegalClaimsAndConsultations[QuestionId]
  ) => {
    if (questionId === 'claimType' && answer) {
      if (answer === 'BOOK_A_CONSULTATION') {
        setTimeout(() => {
          history.push(
            generatePath(routes.claims.legal.phoneConsultation.path, {
              policyId,
            })
          );
        }, 200);
      }
      if (answer === 'LEGAL_CLAIM') {
        setTimeout(() => {
          history.push(
            generatePath(routes.claims.legal.submitClaim.path, {
              policyId,
            })
          );
        }, 200);
      }
    }
  };

  const questions = getTranslatedQuestionnaire(t);
  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules,
  };

  return (
    <Switch>
      <Route
        path={routes.claims.legal.claimsAndConsultations.questionnaire.path}
      >
        <SignupQuestionnaire
          questionnaireAnswers={{}}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LegalClaimsAndConsultationsComponents,
          }}
          basePath={url}
          questionId="claimType"
          featureName="LegalClaimsAndConsultations"
        />
      </Route>
    </Switch>
  );
};
