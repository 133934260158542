import { AppState } from 'reducers';

import { Quote } from '../models';

export const getQuoteInfo =
  (dependent = false) =>
  (state: AppState): Quote | undefined => {
    const questionnaireState = dependent
      ? state.genericQuestionnaire.expatLongTerm?.dependent
      : state.genericQuestionnaire.expatLongTerm;

    // eslint-disable-next-line no-prototype-builtins
    return questionnaireState?.hasOwnProperty('quote')
      ? questionnaireState.quote
      : undefined;
  };
