import { useMutation } from '@tanstack/react-query';
import {
  MailingListResponse,
  UpdateMailingPreference,
} from 'models/mailingPreferences';
import endpoint from 'shared/api';

const updateMailingPreferences = async (
  options: UpdateMailingPreference
): Promise<MailingListResponse> => {
  const { data } = await endpoint.updateMailingPreferences(options);

  return data;
};

export const useUpdateMailingPreferences = () => {
  const mutation = useMutation({
    mutationFn: updateMailingPreferences,
    mutationKey: ['mailingPreferences'],
  });

  return mutation;
};
