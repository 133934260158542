import { TFunction } from '@getpopsure/i18n-react';
import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { ThunkAction } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CreateQuoteReturnType,
  Quote,
  SubmittableDogLiabilitySpainQuestionnaire,
  ZQuote,
  ZSubmittableDogLiabilitySpainQuestionnaire,
} from './models';

export const createDogLiabilitySpainQuote = (answers: Quote) => {
  return endpoint.network.post<Quote>(
    '/signups/dog-liability-spain/quotes',
    answers
  );
};

const createDogLiabilitySpainQuestionnaire = async (
  answers: SubmittableDogLiabilitySpainQuestionnaire
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{
    id: string;
    answers: SubmittableDogLiabilitySpainQuestionnaire;
  }>('/questionnaires/', {
    answers,
    questionnaireType: 'DOG_LIABILITY_ES',
    metadata: source,
  });
};

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'dogLiabilitySpain'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'dogLiabilitySpain'>,
    getState: () => AppState
  ) => {
    const questionnaire =
      getGenericQuestionnaire(getState()).dogLiabilitySpain || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[DOG_LIABILITY_ES] Quote not defined`);
      }

      const quoteValidation = ZQuote.safeParse(questionnaire.quote);

      if (!quoteValidation.success) {
        throw new Error(
          `[DOG_LIABILITY_ES - Submission] Questionnaire quote errors: ${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;

      const validationResult =
        ZSubmittableDogLiabilitySpainQuestionnaire.safeParse(questionnaire);

      if (!validationResult.success) {
        throw new Error(
          `[DOG_LIABILITY_ES - Submission] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      const {
        data: { id: quoteId },
      } = await createDogLiabilitySpainQuote(validatedQuote);

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        personalInfo: {
          email,
          name: { firstName, lastName },
        },
      } = validatedAnswers;

      // check if the account exists and create a new account if needed
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await createDogLiabilitySpainQuestionnaire(validatedAnswers);
      dispatch(
        storeGenericQuestionnaireAnswer('dogLiabilitySpain', {
          quoteId,
          questionnaireId,
          quote: { ...questionnaire.quote, id: quoteId },
        })
      );

      await generateCheckoutDocuments(
        questionnaireId,
        quoteId,
        'DOG_LIABILITY_ES',
        t
      );

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );
      Sentry.captureException(
        `[DOG_LIABILITY_ES Review] Failed to submit with following error: ${error}`
      );
      return { status: 'ERROR' };
    }
  };
