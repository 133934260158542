import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { AutoSuggestMultiSelect } from '@popsure/dirty-swan';
import { useEffect, useState } from 'react';

export type AutoSuggestFormProps<Questionnaire extends QuestionnaireQuestions> =
  {
    validate?: (value: string[]) => boolean;
    options: string[];
  } & QuestionnaireFormProps<Questionnaire, string[]>;

export const AutoSuggestMultiSelectForm = <
  Questionnaire extends QuestionnaireQuestions
>({
  value: defaultValues,
  options,
  onSubmitValue,
  placeholder,
  featureName,
  question,
  validate,
  setValidForSubmission,
}: AutoSuggestFormProps<Questionnaire>) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    defaultValues ?? []
  );

  useEffect(() => {
    const isValid =
      selectedValues && (validate ? validate(selectedValues) : true);
    setValidForSubmission(Boolean(isValid));
  });

  const handleSubmit = () => {
    if (!selectedValues) {
      throw new Error(
        `[${featureName}] AutoSuggest value is missing for submission for question: ${question}`
      );
    }
    onSubmitValue(selectedValues);
  };

  return (
    <Header onSubmit={handleSubmit}>
      <div className="mt16 w100 wmx6">
        <AutoSuggestMultiSelect
          placeholder={placeholder ?? ''}
          setValues={(values) => {
            const selected = options.filter((option) =>
              values.map((val) => val.value).includes(option)
            );
            setSelectedValues(selected);
          }}
          selectedValues={selectedValues.map((val) => ({
            value: val,
            leftIcon: undefined,
          }))}
          chipsListClassName="wmx9"
          options={options.map((val) => ({
            value: val,
            leftIcon: undefined,
          }))}
        />
      </div>
    </Header>
  );
};
