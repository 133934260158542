import { TFunction } from '@getpopsure/i18n-react';
import { faq, website } from '@getpopsure/private-constants';
import { illustrations } from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import { travel } from 'models/insurances/assets/img';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { Name } from 'models/user';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import {
  coverageOneYearInTheFutureValidator,
  coverageStartDateFromToday,
} from 'SignupQuestionnaire/shared/customValidators';

import { createTravelHealthV1PostQuote } from '../actions';
import { CoveredGroup } from '../components/CoveredGroup';
import { PolicyDetails } from '../components/PolicyDetails/PolicyDetails';
import { TravelDateForm } from '../components/TravelDateForm/TravelDateForm';
import {
  CoverOptions,
  TravelHealthV1,
  TravelHealthV1GroupIds,
} from '../models';

const travelImg = imageTypeMapping.TRAVEL;

export const TravelHealthV1Components = {
  COVERED_GROUP: CoveredGroup,
  CUSTOM_DATE: TravelDateForm,
} as const;

export type TravelHealthV1Questionnaire = SignupQuestionnaireType<
  TravelHealthV1,
  TravelHealthV1GroupIds,
  typeof TravelHealthV1Components
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  cover: CoverOptions = 'MYSELF'
): TravelHealthV1Questionnaire => {
  const selfCover = cover === 'MYSELF';

  return [
    {
      id: 'intro',
      required: true,
      type: 'INTRO',
      props: {
        title: "Hey! Let's get you a travel insurance quote in minutes",
        subTitle:
          'We need a few pieces of information about you and we can figure out the rest.',
      },
      screen: {
        continueButtonText: 'Get started',
      },
      groupId: 'preQuote',
    },
    {
      id: 'cover',
      required: true,
      type: 'RADIO',
      props: {
        mapValue: {
          MYSELF: 'Myself',
          MYSELF_AND_OTHERS: 'Myself and someone else',
        },
      },
      screen: {
        question: 'Who would you like to cover for your trip?',
      },
      groupId: 'preQuote',
    },
    {
      id: 'numberOfCovered',
      type: 'NUMBER',
      props: {},
      screen: {
        question: 'How many other people would you like to cover? ',
        description:
          'Up to one more adult and seven children under 21 years old.',
      },
      groupId: 'preQuote',
    },
    {
      id: 'numberOfCoveredBlocker',
      required: false,
      type: 'BLOCKER',
      props: {
        title: 'Unfortunately...',
        description: '',
        additionalInfo: `We are unable to offer you travel health insurance at this time.\n\nPlease reach out via our [support page](${website.support}) if we can assist with anything else.`,
        paragraphClassName: '',
        iconType: 'SHIELD',
        buttonProps: [],
      },
      screen: {
        question: '',
        layout: 'Standalone',
      },
      groupId: 'preQuote',
    },
    {
      id: 'dateOfBirth',
      required: true,
      type: 'DATE',
      props: {
        yearRange: {
          min: { op: 'subtract', type: 'years', value: 64 },
          max: { op: 'subtract', type: 'years', value: 18 },
        },
      },
      screen: {
        question: 'What is your date of birth?',
      },
      groupId: 'preQuote',
    },
    {
      id: 'ageBlocker',
      required: false,
      type: 'BLOCKER',
      props: {
        title: 'Unfortunately...',
        description: '',
        additionalInfo: `We are unable to offer you travel health insurance at this time.\n\nPlease reach out via our [support page](${website.support}) if we can assist with anything else.`,
        paragraphClassName: '',
        iconType: 'SHIELD',
        buttonProps: [],
      },
      screen: {
        question: '',
        layout: 'Standalone',
      },
      groupId: 'preQuote',
    },
    {
      id: 'isLivingInGermany',
      required: true,
      type: 'BOOLEAN',
      props: {},
      screen: {
        question: selfCover
          ? 'Do you currently live in Germany?'
          : 'Do all of the people you want to cover currently live in Germany?',
      },
      groupId: 'preQuote',
    },
    {
      id: 'germanyBlocker',
      type: 'BLOCKER',
      props: {
        title: 'Unfortunately...',
        description: '',
        additionalInfo: `We are unable to offer you travel health insurance at this time.\n\nPlease reach out via our [support page](${website.support}) if we can assist with anything else.`,
        paragraphClassName: '',
        iconType: 'SHIELD',
        buttonProps: [],
      },
      screen: {
        question: '',
        layout: 'Standalone',
      },
      groupId: 'preQuote',
    },
    {
      id: 'quote',
      required: true,
      type: 'QUOTEPAGE',
      props: {
        config: {
          title: 'Here is your quote',
          headerImage: {
            src: travelImg,
            alt: 'Travel health',
          },
          actions: {
            items: [],
            valuesMap: {
              '0': selfCover ? '1.5' : '2.67',
              '1': selfCover ? '7.58' : '13.92',
              '2': selfCover ? '8.75' : '17.08',
            },
            submitMap: [
              {
                id: '1',
                planId: 'BASIC',
                price: selfCover ? 1.5 : 2.67,
              },
              {
                id: '2',
                planId: 'ADVANCED',
                price: selfCover ? 7.58 : 13.92,
              },
              {
                id: '3',
                planId: 'PREMIUM',
                price: selfCover ? 8.75 : 17.08,
              },
            ],
            button: [
              {
                title: 'Basic',
                subTitle: 'Health',
                perText: 'per month',
              },
              {
                title: 'Advanced',
                subTitle: 'Health, cancellation and curtailment',
                perText: 'per month',
              },
              {
                title: 'Premium',
                subTitle: 'Health, cancellation, curtailment and luggage',
                perText: 'per month',
              },
            ],
          },
          coveredSection: {
            title: "What's covered?",
            layout: {
              cards: true,
              compact: true,
            },
            items: [
              {
                title: 'Dental care',
                description:
                  'Pain-relieving dental treatment, simple fillings and repair of existing dentures.',
                icon: {
                  src: illustrations.dentalTreatment,
                  alt: 'dental treatment',
                },
              },
              {
                title: 'In-patient treatment',
                description:
                  'Medical treatment at the hospital in case of illness or accident.',
                icon: { src: illustrations.aids, alt: 'aid' },
              },
              {
                title: 'Out-patient treatment',
                description:
                  "Coverage for doctor's visits while traveling abroad in the event of illness or accident.",
                icon: { src: illustrations.medicine, alt: 'medicine' },
              },
              {
                title: 'Medical prescriptions',
                description:
                  'Drugs, surgical dressings and aids prescribed to you by a doctor.',
                icon: { src: illustrations.shield, alt: 'dental' },
              },
              {
                title: 'Rescue & repatriation',
                description:
                  'Rescue costs, transport to and from hospital, repatriation of mortal remains or funeral costs.',
                icon: {
                  src: illustrations.location,
                  alt: 'location',
                },
              },
              {
                title: 'Pregnancy complications',
                description:
                  "You're covered in case of issues with your pregnancy or premature birth.",
                icon: { src: illustrations.pregnancy, alt: 'pregnancy' },
              },
            ],
          },
          notCoveredSection: {
            title: "What isn't covered?",
            items: [
              {
                title: 'Pre-existing conditions',
                description:
                  'Treatments required for illness known prior to the start of the journey.',
                icon: {
                  src: illustrations.documentCross,
                  alt: 'document cross',
                },
              },
              {
                title: 'Advanced dental care',
                description:
                  'Including implants, orthodontic treatment, cleaning, crowns, in-lays, splints and functional treatments.',
                icon: {
                  src: illustrations.dentalCleaning,
                  alt: 'dental cleaning',
                },
              },
              {
                title: 'Precautionary measures',
                description:
                  'Vaccinations, preventative blood tests and other precautionary treatments are not covered.',
                icon: {
                  src: illustrations.vaccination,
                  alt: 'Vaccination',
                },
              },
              {
                title: 'Traveling despite official warnings',
                description:
                  'Treatment is not covered if a travel warning was issued by the German government before departure.',
                icon: {
                  src: illustrations.shieldWarning,
                  alt: 'shield warning',
                },
              },
            ],
          },
          reviewBadge: true,
          faq: {
            title: 'Frequently asked questions',
            data: [
              {
                questionId: 1,
                question: 'Does the policy include a deductible?',
                answer:
                  'Feather’s annual travel insurance policy always has a €0 deductible. This includes both the yearly individual plan and the yearly family plan.',
              },
              {
                questionId: 2,
                question:
                  'What do I do if I need to see a doctor while travelling?',
                answer:
                  'In the event of an accident or illness, you are fully covered to visit any doctor you choose. Just make sure to keep all the documents that you are given, including invoices (correctly named and addressed), referrals, prescriptions, and anything else!',
              },
              {
                questionId: 3,
                question: 'Does my policy cover lost luggage?',
                answer: 'The premium plan covers lost or damaged luggage.',
              },
              {
                questionId: 4,
                question: 'Is there a limitation on coverage?',
                answer:
                  'There is no limitation on the cost of needed medical treatment.\n\nRescue costs are limited to €5,000 euros.\n\nReimbursement for cancellation, curtailment, and lost or damaged luggage is limited to €2,000 for individuals and €4,000 for families and partners.',
              },
              {
                questionId: 5,
                question: 'Does my policy cover delayed or cancelled flights?',
                answer:
                  'The advanced and premium plans offer reimbursement for flights you have to cancel or delay due to an insured event, like sickness.',
              },
              {
                questionId: 6,
                question: 'What do I do in an emergency abroad?',
                answer:
                  'In an emergency, please go immediately to the nearest doctor or hospital to seek treatment.\n\nYou must also immediately contact the emergency hotline on +49 40 55557877 if:\n\n- you need urgent treatment in a hospital (in-patient treatment); or\n\n- before extensive diagnostic or therapeutic (eg massage or physiotherapy) treatments begins\n\nIn all other cases, like treatment at a doctor’s office, you can simply make a claim after the incident or when you are back in Germany! You do not need to call the hotline.',
              },
              {
                questionId: 7,
                question: 'How do I make a claim?',
                answer:
                  'In your Feather account, you can click on the claim button and submit your claim and all the documents needed.',
              },
              {
                questionId: 8,
                question:
                  'Are sports activities like skiing, surfing, or hiking covered?',
                answer:
                  'Any needed medical treatment is covered, including injuries resulting from sport accidents. Professional sports activities aren’t covered.\n\nSearch and rescue costs are limited to €5,000 euros.',
              },
              {
                questionId: 9,
                question: 'Which countries are included?',
                answer:
                  'All of our travel insurance plans provide worldwide coverage, excluding Germany, the country of your residence.',
              },
              {
                questionId: 10,
                question: 'How do I cancel?',
                answer:
                  'This is an annual policy. You can cancel anytime, up to one month before the end of the contracted year. The pending amount for the full yearly premium will be charged, and your policy stays active until the end of the year. It will not be renewed for the next year.',
              },
              {
                questionId: 11,
                question: 'How do I pay for my policy?',
                answer:
                  'All plans are annual policies. The yearly premium is divided and paid in monthly contributions.\n\nThe monthly premiums will be automatically charged to your preferred payment method. We accept credit/debit cards and PayPal.',
              },
            ],
          },
          moreQuestionsSection: {
            showBookCallAction: false,
            title: 'Have more questions?',
            secondaryActionLink: faq.base,
            secondaryAction: 'Visit our FAQ',
          },
        },
      },
      screen: {
        question: '',
        layout: 'Standalone',
      },
      groupId: 'preQuote',
    },
    {
      id: 'startDate',
      required: true,
      type: 'DATE',
      props: {
        yearRange: {
          min: { op: 'subtract', type: 'years', value: 0 },
          max: { op: 'add', type: 'years', value: 1 },
        },
      },
      screen: {
        question:
          'What date would you like your travel health insurance policy to begin?',
        additionalInfo: {
          title: 'Info',
          description: `- **Travel health**: only trips starting after the policy start date will be covered\n\n- **Travel cancellation**: only trips starting after the policy start date and booked no longer than 3 days before policy start are covered. If trips have been booked before they are only covered if the departure date is more than 30 days after the policy start.\n\n- **Luggage**: only trips starting after policy start`,
        },
      },
      validations: [
        coverageStartDateFromToday(),
        coverageOneYearInTheFutureValidator(),
      ],
      groupId: 'signup',
    },
    {
      id: 'email',
      required: true,
      type: 'EMAIL',
      props: {
        verticalId: 'travelHealthV1',
      },
      screen: {
        question: "What's your email address?",
      },
      groupId: 'signup',
    },
    {
      id: 'name',
      required: true,
      type: 'NAME',
      props: {},
      screen: {
        question: "What's your name?",
      },
      groupId: 'signup',
    },
    {
      id: 'gender',
      required: true,
      type: 'RADIO',
      props: {
        mapValue: {
          MALE: 'Male',
          FEMALE: 'Female',
        },
      },
      screen: {
        question: 'Which gender do you identify with?',
      },
      groupId: 'signup',
    },
    {
      id: 'othersCovered',
      type: 'COVERED_GROUP',
      props: {},
      screen: {
        question:
          'What are the names and birthdays of the dependents you would like to cover?',
      },
      groupId: 'signup',
    },
    {
      id: 'review',
      required: true,
      type: 'REVIEW',
      props: {
        requestType: 'TRAVEL_SUBMIT_INFO',
        reviewValues: [
          {
            title: 'Coverage',
            value: {
              type: 'String',
              key: 'cover',
              valueMap: {
                MYSELF: 'Myself',
                MYSELF_AND_OTHERS: 'Myself and someone else',
              },
            },
            path: 'cover',
          },
          {
            title: 'Policy Plan',
            value: {
              type: 'Strings',
              key: 'quote',
              keys: ['planId'],
            },
            path: 'quote',
          },
          {
            title: "Policy holder's name",
            value: {
              type: 'Strings',
              key: 'name',
              keys: ['firstName', 'lastName'],
            },
            path: 'name',
          },
          {
            title: "Policy holder's email",
            value: {
              type: 'String',
              key: 'email',
            },
            path: 'email',
          },
          {
            title: 'Date of birth',
            value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
            path: 'dateOfBirth',
          },
          {
            title: 'Coverage start date',
            value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
            path: 'startDate',
          },
          {
            title: 'Others covered',
            value: {
              type: 'Array',
              key: 'othersCovered',
              transform: (val) => {
                const { firstName, lastName, dateOfBirth } = val as Name & {
                  dateOfBirth: string;
                };
                return `${firstName} ${lastName} / ${dayjs(dateOfBirth).format(
                  'DD MMM YYYY'
                )}\n`;
              },
            },
            path: 'OthersCovered',
          },
        ],
        verticalId: 'travelHealthV1',
        insuranceType: 'TRAVEL',
        createPostQuote: () => createTravelHealthV1PostQuote(t),
      },
      screen: {
        layout: 'Standalone',
      },
      groupId: 'signup',
    },
    {
      id: 'reviewCheckout',
      required: true,
      type: 'CHECKOUT',
      props: {
        type: 'TRAVEL',
        PolicyDetails,
        checkoutIcon: travel,
      },
      screen: {
        layout: 'Standalone',
      },
      groupId: 'signup',
    },
  ];
};
