import routes from 'constants/routes';
import { Route, Switch } from 'react-router';

import { ClaimsAndConsultations } from './ClaimsAndConsultations';
import { PhoneConsultation } from './PhoneConsultation';
import { SubmitClaim } from './SubmitClaim';

export const Claims = () => {
  return (
    <Switch>
      <Route
        exact={false}
        path={routes.claims.legal.submitClaim.path}
        component={SubmitClaim}
      />
      <Route
        exact={false}
        path={routes.claims.legal.phoneConsultation.path}
        component={PhoneConsultation}
      />
      <Route
        exact={false}
        path={routes.claims.legal.claimsAndConsultations.path}
        component={ClaimsAndConsultations}
      />
    </Switch>
  );
};
