import { trackStructuredEvent } from '@getpopsure/analytics';
import { calendly } from '@getpopsure/private-constants';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { Button, Checkbox } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { Pension } from 'features/pension/models';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import shieldIcon from './icons/shield.svg';
import styles from './InfoScreen.module.scss';

type InfoScreenProps<Questionnaire extends QuestionnaireQuestions> = {
  title: string;
} & QuestionnaireFormProps<Questionnaire, boolean>;

export const InfoScreen = ({
  title,
  onSubmitValue,
}: InfoScreenProps<Pension>) => {
  const [consentGiven, setConsentGiven] = useState<string[]>([]);
  const { t } = useSafeTranslation();

  const handleSubmit = () => {
    onSubmitValue(true);
  };

  return (
    <form
      className={`p-container d-flex fd-column ai-start jc-center wmx12 ${styles.container}`}
      onSubmit={handleSubmit}
    >
      <img src={shieldIcon} alt="shield icon" />
      <h1 className="p-h1 mt16 mb8">{title}</h1>
      <Checkbox
        bordered={false}
        classNames={{ container: 'mt24', label: 'wmx8' }}
        onChange={setConsentGiven}
        wide
        options={{
          consent: t(
            'pension.qnr.infoScreen.checkbox.text',
            'I understand and wish to continue'
          ),
        }}
        value={consentGiven}
      />

      <div className={classNames('mt24 d-flex gap16', styles.buttonContainer)}>
        <Button
          type="submit"
          disabled={!consentGiven.includes('consent')}
          className="ws3"
        >
          {t('pension.qnr.infoScreen.button.caption', 'Continue')}
        </Button>
        <Button
          variant="textColor"
          className="ws3"
          onClick={() => {
            trackStructuredEvent({
              category: 'PENSION',
              action: 'CLICKED_BOOK_AN_APPOINTMENT',
            });
            window.Calendly.initPopupWidget({
              url: calendly.pensionInsurance,
            });
          }}
          type="button"
        >
          {t('pension.qnr.infoScreen.bookCall.caption', 'Book a call')}
        </Button>
      </div>
    </form>
  );
};
