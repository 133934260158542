import { Checkbox } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import {
  CheckoutDocument,
  CheckoutDocumentCategory,
} from 'features/checkoutDocuments/models';
import { useSafeTranslation } from 'shared/i18n';

import styles from './PensionDocumentsReview.module.scss';

type PensionDocumentsReviewProps = {
  checkboxValue: string[];
  setCheckboxValues: (value: string[]) => void;
  documents: CheckoutDocument[];
};

export const PensionDocumentsReview = ({
  checkboxValue,
  setCheckboxValues,
  documents,
}: PensionDocumentsReviewProps) => {
  const { t } = useSafeTranslation();
  const documentLinkMapping = documents.reduce((mapping, currentDoc) => {
    const { url, category } = currentDoc;
    return { ...mapping, [category]: url };
  }, {} as Record<CheckoutDocumentCategory, string>);

  return (
    <section className={`bg-white bs-xs br8 ${styles.container}`}>
      <Checkbox
        bordered={false}
        classNames={{ label: 'wmx8' }}
        value={checkboxValue}
        onChange={setCheckboxValues}
        wide
        options={{
          hasReviewedDocuments: (
            <Markdown openLinksInNewTab={true}>
              {t(
                'pension.qnr.submitApplication.documentsReview.confirmation.hasReviewedDocuments.text',
                'I confirm to have downloaded and accepted the [insurance conditions/IPID]({{insuranceConditions}}), [advice record]({{adviceRecord}}), and [licensing information]({{licensingInformation}}).',
                {
                  insuranceConditions: documentLinkMapping.INSURANCE_CONDITIONS,
                  adviceRecord: documentLinkMapping.ADVICE_RECORD,
                  licensingInformation:
                    documentLinkMapping.FEATHER_INTRODUCTORY_INFORMATION,
                }
              )}
            </Markdown>
          ),
        }}
      />
    </section>
  );
};
