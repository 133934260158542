import { email } from '@getpopsure/private-constants';
import { Address } from '@getpopsure/public-models';
import { useUpdateChecklist } from 'features/studentChecklist/hooks/useUpdateChecklist';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import { StepProps } from '../../models';
import { getChecklistAddress, getQuestionnaireAddress } from '../../selectors';
import { GermanAddressView } from './GermanAddress.view';

export const GermanAddress = (props: StepProps) => {
  const { completed } = props;
  const { policyId } = useParams<{ policyId: string }>();
  const questionnaireAddress = useSelector(getQuestionnaireAddress(policyId));
  const address = useSelector(getChecklistAddress(policyId));
  const [openModal, setOpenModal] = useState(false);
  const { t } = useSafeTranslation();

  const {
    requestFn: updateChecklist,
    reset,
    isLoading,
    isError,
  } = useUpdateChecklist();

  const errorMessage = isError
    ? t(
        'studentChecklist.germanAddress.error.message',
        'Student checklist could not be updated with address details. Please try again or contact us at {{supportEmail}}.',
        { supportEmail: email.help }
      )
    : undefined;

  useEffect(() => {
    if (isError || isLoading) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }

    return () => {
      setOpenModal(false);
    };
  }, [isError, isLoading]);

  const handleOpenModal = () => setOpenModal(true);

  const handleSaveAddress = async (newAddress: Address) => {
    const payload = {
      address: {
        ...newAddress,
        additionalInformation: newAddress?.additionalInformation || '',
      },
    };

    updateChecklist(policyId, payload);
  };

  return (
    <GermanAddressView
      address={address}
      completed={completed}
      error={errorMessage}
      loading={isLoading}
      openModal={openModal}
      questionnaireAddress={questionnaireAddress}
      handleSaveAddress={handleSaveAddress}
      onOpenModal={handleOpenModal}
      onCloseModal={() => {
        reset();
        setOpenModal(false);
      }}
    />
  );
};
