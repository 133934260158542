import dayjs from 'dayjs';
import { Plan as BikePlan, Provider } from 'models/bike';
import { RoutesObject } from 'models/questionnaireFramework';
import { QuestionReviewValue } from 'models/review';
import { TFunction } from 'shared/i18n';
import { RadioOption } from 'shared/models/types';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';
import { isUploadedFiles } from 'shared/validators';

import {
  bikeBasicPlanClaimTypes,
  BikeClaims,
  bikeClaimsReviewQuestions,
  bikeClaimsReviewQuestionsTitleMapping,
  BikeClaimType,
  bikeComfortPlanClaimTypes,
  claimTypeMapping,
  claimTypesAvailableAfterSixMonths,
  isBikeClaimType,
} from '../models';

export const isOlderThanSixMonths = (date: string): boolean =>
  dayjs(date).diff(dayjs(), 'months') <= -6;

export const retrieveBikeClaimTypes = (
  plan: BikePlan | undefined,
  policyStartDate: string,
  providerId: Provider
): BikeClaimType[] => {
  if (providerId === 'BARMENIA') {
    return [
      ...bikeBasicPlanClaimTypes,
      ...bikeComfortPlanClaimTypes,
      ...claimTypesAvailableAfterSixMonths,
    ];
  }
  if (plan === 'COMFORT') {
    if (isOlderThanSixMonths(policyStartDate)) {
      return [
        ...bikeBasicPlanClaimTypes,
        ...bikeComfortPlanClaimTypes,
        ...claimTypesAvailableAfterSixMonths,
      ];
    }

    return [...bikeBasicPlanClaimTypes, ...bikeComfortPlanClaimTypes];
  }
  return [...bikeBasicPlanClaimTypes];
};

export const retrieveBikeClaimTypesMapping = (
  plan: BikePlan | undefined,
  policyStartDate: string,
  providerId: Provider,
  t: TFunction
): Partial<{ [K in BikeClaimType]: RadioOption }> => {
  const availableClaimTypes = retrieveBikeClaimTypes(
    plan,
    policyStartDate,
    providerId
  );
  return availableClaimTypes.reduce(
    (obj, item) => ({
      ...obj,
      [item]: claimTypeMapping(t)[item],
    }),
    {}
  );
};

// eslint-disable-next-line
export const formatBikeClaimAnswers = (answer: any, t: TFunction): string => {
  if (isBikeClaimType(answer)) {
    const claimTypeInfo = claimTypeMapping(t)[answer];

    if (typeof claimTypeInfo === 'string') {
      return claimTypeInfo;
    }

    return claimTypeInfo.title;
  }

  if (Array.isArray(answer) && isUploadedFiles(answer)) {
    return answer.reduce((value, current, idx) => {
      if (idx === answer.length - 1) {
        return value + current.name;
      }
      return `${value}${current.name}, `;
    }, '');
  }

  return String(answer);
};

export const retrieveBikeClaimQuestionReviewValues = (
  questionnaire: Partial<BikeClaims>,
  routes: RoutesObject<BikeClaims>,
  t: TFunction
): QuestionReviewValue[] => {
  return bikeClaimsReviewQuestions.map((questionId) => {
    const title = bikeClaimsReviewQuestionsTitleMapping(t)[questionId];

    if (questionId === 'amount') {
      return {
        title,
        path: routes.payoutDetails.path,
        value: questionnaire.payoutDetails?.amount
          ? formatCurrencyWithSpace(questionnaire.payoutDetails.amount)
          : null,
      };
    }

    if (questionId === 'iban') {
      return {
        title,
        path: routes.payoutDetails.path,
        value: questionnaire.payoutDetails?.iban ?? null,
      };
    }

    return {
      title,
      path: routes[questionId].path,
      value: formatBikeClaimAnswers(questionnaire[questionId], t),
    };
  });
};
