import styles from './style.module.scss';

const TimedLoadSpinnerView = ({
  title,
  description,
  delayedAnimatedText,
}: {
  title?: string;
  description?: string;
  delayedAnimatedText?: string;
}) => (
  <div className={`d-flex fd-column ai-center ${styles.container}`}>
    <div className="ds-spinner ds-spinner__l" />
    {title && (
      <>
        <div className="p-h1 mt24 ta-center">{title}</div>
        {description && <div className="p-p mt16 ta-center">{description}</div>}
        {delayedAnimatedText && (
          <div className={`p-p mt16 ta-center ${styles.subText}`}>
            {delayedAnimatedText}
          </div>
        )}
      </>
    )}
  </div>
);

export default TimedLoadSpinnerView;
