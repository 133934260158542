import { trackQuestionnaireReviewEditClicked } from '@getpopsure/analytics';
import { CardButton } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { InsuranceTypes } from 'models/insurances/types';
import { QuestionReviewValue } from 'models/review';
import { FormEvent } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { getUserId } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { mapVerticalIdToSnowplowVerticalId } from 'shared/trackers';

import styles from './style.module.scss';

interface Props {
  handleSubmit: (e: FormEvent) => void;
  loading?: boolean;
  error?: string;
  questionReviewValues: QuestionReviewValue[];
  title?: JSX.Element;
  isDescriptionVisible?: boolean;
  confirmationText?: string;
  region?: string;
  insuranceType: InsuranceTypes;
  ctaText?: string;
}

const Review = ({
  loading,
  error,
  handleSubmit,
  region = 'de',
  insuranceType,
  questionReviewValues,
  title: titleElement,
  isDescriptionVisible,
  confirmationText,
  ctaText,
}: Props) => {
  const { t } = useSafeTranslation();
  const userId = useSelector(getUserId);

  const cleanedQuestionReviewValues = questionReviewValues.reduce(
    (acc: QuestionReviewValue[], item: QuestionReviewValue) => {
      const { title, value, path } = item;
      if (title && value && path) return acc.concat(item);
      return acc;
    },
    []
  );

  const handleClickToEdit = (path: string | null) => {
    const vertical = mapVerticalIdToSnowplowVerticalId[insuranceType]
      ? mapVerticalIdToSnowplowVerticalId[insuranceType]
      : insuranceType;

    trackQuestionnaireReviewEditClicked({
      user_id: userId ?? null,
      question: path ?? '',
      insurance_type: vertical ?? '',
      region,
    });
  };

  return (
    <div className={`mt80 p-body d-flex ai-center ${styles.container}`}>
      <form onSubmit={handleSubmit}>
        {titleElement && titleElement}
        {isDescriptionVisible && (
          <div className="p-p">
            {t(
              'components.review.description',
              'Review or edit the information you’ve provided so far:'
            )}
          </div>
        )}
        <div className="mt24">
          {cleanedQuestionReviewValues.map(({ title, value, path }) => (
            <CardButton
              key={path}
              className="mt8 wmx5"
              title={title ?? ''}
              description={value}
              href={path ?? ''}
              onClick={() => handleClickToEdit(path)}
            />
          ))}
        </div>
        <div className="mt24 p-p--small wmx8">
          {confirmationText ??
            t(
              'components.review.agreement',
              'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered—or change the contract in accordance with § 19 Abs. 5 VVG.'
            )}
        </div>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--warning mt16">{error}</div>
        </AnimateHeight>
        <button
          className={classNames('p-btn--primary mt24 wmn3 mb80', {
            'p-btn--loading': loading,
          })}
          data-cy="button-review"
          type="submit"
        >
          {ctaText ?? t('components.review.buttoncaption', 'Continue')}
        </button>
      </form>
    </div>
  );
};

export default Review;
