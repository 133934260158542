/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { RootReducerAction } from 'constants/actions';
import checkoutDocuments from 'features/checkoutDocuments/checkoutDocuments.reducers';
import i18n from 'features/i18n/reducers';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Reducer,
} from 'redux';
import {
  createTransform,
  PersistConfig,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { createState } from 'shared/util/createState';

import packageJson from '../../package.json';
import billing from '../features/billingHistory/reducers';
import lifeBeneficiaries from '../features/lifeBeneficiaries/lifeBeneficiaries.reducers';
import paymentMethods from '../features/paymentMethods/paymentMethods.reducers';
import paymentScreen from '../features/paymentScreen/paymentScreen.reducers';
import claims from './claims';
import genericQuestionnaire from './genericQuestionnaire';
import healthCard from './healthCard';
import policies from './policies';
import recommendationTool from './recommendationTool';
import request from './request';
import user from './user';

const { name } = packageJson;

const appReducer = combineReducers({
  user,
  request,
  policies,
  claims,
  genericQuestionnaire,
  paymentMethods,
  paymentScreen,
  billing,
  healthCard,
  recommendationTool,
  i18n,
  lifeBeneficiaries,
  checkoutDocuments,
});

export type AppState = ReturnType<typeof appReducer>;

export type AppDispatch = typeof store.dispatch;

// TODO: add stronger types to rootReducer
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer: Reducer<any, any> = (
  state: AppState,
  action: RootReducerAction
) => {
  if (action.type === 'CLEAR_REDUCER_STATE') {
    const newState = action.preserving.reduce(
      (o, p) => ({ ...o, [p]: state[p] }),
      createState({})
    );
    // eslint-disable-next-line no-param-reassign
    state = newState;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return appReducer(state, action as any);
};

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose; // tslint:disable-line

const setTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => outboundState
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const persistConfig: PersistConfig<any> = {
  key: name,
  storage,
  blacklist: ['request'],
  transforms: [setTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware), sentryReduxEnhancer)
);

export const persistor = persistStore(store);

/* tslint:disable */
if (window.Cypress) {
  window.__REDUX_DEVTOOLS_EXTENSION__ =
    window.parent.__REDUX_DEVTOOLS_EXTENSION__;
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ =
    window.parent.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}
/* tslint:enable */
