import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import {
  BottomOrRegularModal,
  Checkbox,
  IbanInput,
  InformationBox,
} from '@popsure/dirty-swan';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Trans, useSafeTranslation } from 'shared/i18n';
import { isValidIban } from 'shared/util/isValidIban';

export const PayoutDetails = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
  value,
}: QuestionnaireFormProps<Questionnaire, string>) => {
  const [validationError, setValidationError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [iban, setIban] = useState<string>(value || '');

  const { t } = useSafeTranslation();

  const handleSubmit = () => {
    if (!iban || !termsAccepted) {
      setValidationError('Enter a valid IBAN');
      return;
    }

    onSubmitValue(iban);
  };

  useEffect(() => {
    const isValid = !!iban && isValidIban(iban) && termsAccepted;
    setValidForSubmission(isValid);

    if (isValid) {
      setValidationError('');
    }
  }, [iban, setValidForSubmission, termsAccepted]);

  return (
    <>
      <Header onSubmit={handleSubmit}>
        <div className="mt24">
          <h4 className="p-h1 mb8">
            {t('claims.expatLongTerm.payoutDetails.iban.title', 'Bank details')}
          </h4>
          <p className="p-p mb8">
            {t(
              'claims.expatLongTerm.payoutDetails.iban.description',
              "The IBAN of your account where you'll receive the payout if approved"
            )}
          </p>
          <IbanInput
            placeholder={t(
              'claims.expatLongTerm.payoutDetails.iban.placeholder',
              'EU IBAN'
            )}
            value={iban}
            onChange={setIban}
          />
          <Checkbox
            options={{
              TERMS: (
                <Trans i18nKey="claims.expsatLongTerm.payoutDetails.terms.checkbox">
                  I agree to
                  <button
                    className="d-inline-block pl8 ds-interactive-component p-a"
                    type="button"
                    onClick={() => setTermsModalVisible(true)}
                  >
                    data processing agreement
                  </button>
                  .
                </Trans>
              ),
            }}
            onChange={(values) => setTermsAccepted(values.includes('TERMS'))}
            value={termsAccepted ? ['TERMS'] : []}
            bordered={false}
            classNames={{ container: 'mt24' }}
          />
        </div>
        <AnimateHeight duration={300} height={validationError ? 'auto' : 0}>
          <InformationBox variant="warning" className="mt16">
            {validationError}
          </InformationBox>
        </AnimateHeight>
      </Header>
      <BottomOrRegularModal
        title={t(
          'claims.expatLongTerm.payoutDetails.terms.modalTitle',
          'Data processing agreement'
        )}
        isOpen={termsModalVisible}
        onClose={() => setTermsModalVisible(false)}
      >
        <p className="p-p px24 pb24">
          {t(
            'claims.expatLongTerm.payoutDetails.terms.modalContent',
            'I consent Feather (Popsure Deutschland GmbH) to support claims. You do have right not to give consent but if you do consent you are agreeing to Feather sharing claims and supporting additional medical reports or health records that may be requested in order to process your claim.'
          )}
        </p>
      </BottomOrRegularModal>
    </>
  );
};
