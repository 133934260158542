import { TFunction } from '@getpopsure/i18n-react';
import { faq } from '@getpopsure/private-constants';
import { illustrations } from '@popsure/dirty-swan';
import { FAQInfo } from 'components/faq';
import { ReactNode } from 'react';

import coinStackIcon from './assets/coin-stack.svg';
import euroIcon from './assets/euro-stack.svg';
import styles from './Quote.module.scss';

export const considerations = (
  t: TFunction
): {
  title: string;
  description: string;
  icon: { src: string; alt: string };
}[] => [
  {
    title: t('pension.qnr.quote.fees.title', 'Fees'),
    description: t(
      'pension.qnr.quote.fees.description',
      'There is no upfront fee. The management fee is €1.50 monthly plus 0.5% per year. The investment fee is 0.2% per year. Check out [how Feather’s pension plan fees compares with other ETF plans]({{faqLink}}).',
      { faqLink: faq.pensionDifferenceWithETFs }
    ),
    icon: { src: euroIcon, alt: 'Euro coin' },
  },
  {
    title: t('pension.qnr.payouts.title', 'Tax efficient payouts'),
    description: t(
      'pension.qnr.payouts.description',
      "Normally you have to pay taxes on profits from investments. With the Feather pension plan, you'll have considerable tax advantages from the start of your retirement."
    ),
    icon: {
      src: coinStackIcon,
      alt: t(
        'pension.qnr.quote.payouts.icon',
        'Euro coin next to a stack of coins'
      ),
    },
  },
];

export const whyFeather = (
  t: TFunction
): {
  title: string;
  description: string;
  icon: ReactNode;
}[] => [
  {
    title: t(
      'pension.qnr.quote.whyFeather1.title',
      'Digital account management'
    ),
    description: t(
      'pension.qnr.quote.whyFeather1.description',
      'Manage your pension insurance policy online, including updating information or claims.'
    ),
    icon: (
      <img
        src={illustrations.phone2Fa}
        alt="phone"
        className={styles.whyFeatherIcon}
      />
    ),
  },
  {
    title: t(
      'pension.qnr.quote.whyFeather2.title',
      'Top rated English support'
    ),
    description: t(
      'pension.qnr.quote.whyFeather2.description',
      'If you’re unable to solve a problem, we can assist you online via video call or by email.'
    ),
    icon: (
      <img
        src={illustrations.user}
        alt="a person's head"
        className={styles.whyFeatherIcon}
      />
    ),
  },
  {
    title: t('pension.qnr.quotewhyFeather3.title', 'Customized coverage'),
    description: t(
      'pension.qnr.quotewhyFeather3.description',
      'Choose between different plans to adjust your coverage to your needs.'
    ),
    icon: (
      <img
        src={illustrations.shield}
        alt="shield"
        className={styles.whyFeatherIcon}
      />
    ),
  },
  {
    title: t('pension.qnr.quotewhyFeather4.title', 'Everything in one account'),
    description: t(
      'pension.qnr.quotewhyFeather4.description',
      'Health, liability, household, and other insurance policies can all be managed in one account.'
    ),
    icon: (
      <img
        src={illustrations.allInOneAccount}
        alt="phone"
        className={styles.whyFeatherIcon}
      />
    ),
  },
];

export const faqData = (t: TFunction): FAQInfo[] => [
  {
    questionId: 1,
    question: t(
      'pension.qnr.quote.faq1.question',
      'How much money should I pay, and can I adjust it later?'
    ),
    answer: t(
      'pension.qnr.quotefaq1.answer',
      'The payment amount is entirely adaptable, tailored to your disposable income and long-term financial outlook. You have the freedom to start with as little as €50/month and gradually adjust it as you see fit.'
    ),
  },
  {
    questionId: 2,
    question: t(
      'pension.qnr.quote.faq2.question',
      'Is it possible for me to withdraw money, and if so, are there any limitations in place?'
    ),
    answer: t(
      'pension.qnr.quote.faq2.answer',
      'Yes, you are indeed eligible to withdraw money. No, there are no limitations on withdrawing money. However, our recommendation is to maintain this investment until your retirement. By doing so, you can take advantage of the tax discounts provided by the German government.'
    ),
  },
  {
    questionId: 3,
    question: t(
      'pension.qnr.quote.faq3.question',
      'When I retire, do I get monthly/one-off payments?'
    ),
    answer: t(
      'pension.qnr.quote.faq3.answer',
      'You can choose and even mix and match.'
    ),
  },
  {
    questionId: 4,
    question: t(
      'pension.qnr.quote.faq4.question',
      'Can I pause paying and then resume without canceling the plan?'
    ),
    answer: t(
      'pension.qnr.quote.faq4.answer',
      'Yes. No restrictions, no extra cost. You can pause as long as you want as many times as you want and then again continue paying for your pension insurance.'
    ),
  },
  {
    questionId: 5,
    question: t(
      'pension.qnr.quote.faq5.question',
      'How does the "flexibility on location" work? Can a person outside Germany subscribe?'
    ),
    answer: t(
      'pension.qnr.quote.faq5.answer',
      'You need a German address and tax ID to sign up. After signing up, you can keep your pension insurance running from wherever you are in the world.'
    ),
  },
  {
    questionId: 6,
    question: t(
      'pension.qnr.quote.faq6.question',
      'Can I sign up for the Feather pension product as an American?'
    ),
    answer: t(
      'pension.qnr.quote.faq6.answer',
      'Yes, although there are issues between the German and US tax authorities regarding investment products, we found a way to be able to offer you an investment-based private pension.'
    ),
  },
  {
    questionId: 7,
    question: t(
      'pension.qnr.quotefaq7.question',
      'What happens to the money if you die?'
    ),
    answer: t(
      'pension.qnr.quotefaq7.answer',
      'If you die, before retirement, all of the saved pension will be paid to your dependents and on top of that a small extra amount from the risk life insurance (this amount decreases over time, when you get old it becomes insignificant, but when you’re young it is about 10%).\n\nAfter retirement, you can take money out any time with the 50% tax benefit, and if you die your dependents get all that is left plus a small risk life extra. They may choose to turn the pension into a rolling monthly payment (if they choose the rolling payment, they get a monthly pension til they die).'
    ),
  },
];
