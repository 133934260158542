import { TFunction } from '@getpopsure/i18n-react';
import {
  CreditCardIcon,
  DentalToothTeethIcon,
  DependentsFamilyIcon,
  DoctorStethoscopeIcon,
  EyeVisionIcon,
  HeartHandIcon,
  HospitalBuildingIcon,
  PregnacyIcon,
  TrendingUpIcon,
  VideoDoctorIcon,
  VisaPassportIcon,
  WorldGlobeEarthIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const publicHealth = (t: TFunction): AdditionalInfoType => ({
  description: t(
    'policyModal.publicHealth.description',
    'Public health insurance is state insurance where members along with the government contribute to more than 100 individual not-for-profit public health funds. Regardless of the provider you choose, 95% of the coverage offered by public health insurance is the same and is defined by law.'
  ),
  accordions: [
    {
      title: t('policyModal.publicHealth.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <DoctorStethoscopeIcon size={24} />,
          question: t(
            'policyModal.publicHealth.covered1.question',
            'Comprehensive health insurance'
          ),
          answer: t(
            'policyModal.publicHealth.covered1.answer',
            "Everything from general doctors' visits, specialists, checkups, and hospital stays to vaccinations and medical transportation. Everything you would expect from a full health insurance. Medications are covered for around 90% of costs. All pre-existing conditions are covered."
          ),
        },
        {
          id: 'covered-2',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.publicHealth.covered2.question',
            'Dental (basic)'
          ),
          answer: t(
            'policyModal.publicHealth.covered2.answer',
            '- Includes dental checkups and basic treatments only (with basic materials).\n- Dental insurance add-on is available and recommended. It covers dental cleanings, high quality materials and additional treatments.'
          ),
        },
        {
          id: 'covered-3',
          icon: <HeartHandIcon size={24} />,
          question: t(
            'policyModal.publicHealth.covered3.question',
            'Treatments and therapies'
          ),
          answer: t(
            'policyModal.publicHealth.covered3.answer',
            '- Preventative care treatments\n- Therapeutic measures (up to 90% of costs)\n- Mental health therapy\n- Medical aids (covered between 75% - 90% of costs)\n- Statutory health screenings (e.g. breast cancer, bowel cancer, skin cancer)'
          ),
        },
        {
          id: 'covered-4',
          icon: <PregnacyIcon size={24} />,
          question: t(
            'policyModal.publicHealth.covered4.question',
            'Pregnancy and childbirth'
          ),
          answer: t(
            'policyModal.publicHealth.covered4.answer',
            '- General pregnancy coverage.\n- Fertility treatment (e.g. IVF) under very limited situations, depending on the provider.\n- Midwife support during pregnancy, birth and after birth.\n- Birth preparation courses.'
          ),
        },
        {
          id: 'covered-5',
          icon: <HospitalBuildingIcon size={24} />,
          question: t(
            'policyModal.publicHealth.covered5.question',
            'Public hospitals'
          ),
          answer: t(
            'policyModal.publicHealth.covered5.answer',
            'Shared room only'
          ),
        },
        {
          id: 'covered-6',
          icon: <WorldGlobeEarthIcon size={24} />,
          question: t(
            'policyModal.publicHealth.covered6.question',
            'EU-wide coverage'
          ),
          answer: t(
            'policyModal.publicHealth.covered6.answer',
            'Your insurance card includes the European Health Insurance Card (EHIC). You can use  it within the EU and get covered up to anything reimbursed in Germany.'
          ),
        },
        {
          id: 'covered-7',
          icon: <VideoDoctorIcon size={24} />,
          question: t(
            'policyModal.publicHealth.covered7.question',
            'Video doctors'
          ),
          answer: t(
            'policyModal.publicHealth.covered7.answer',
            'Many insurance providers (like TK, BARMER, DAK) offer medical advice by phone or video call from English speaking doctors based in Germany.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.publicHealth.title2', 'What’s not covered'),
      items: [
        {
          id: 'not-covered-1',
          icon: <HospitalBuildingIcon size={24} />,
          question: t(
            'policyModal.publicHealth.notCovered1.question',
            'Private practices and hospitals'
          ),
          answer: t(
            'policyModal.publicHealth.notCovered1.answer',
            'Only public doctors and hospitals are covered.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <EyeVisionIcon size={24} />,
          question: t(
            'policyModal.publicHealth.notCovered2.question',
            'Vision'
          ),
          answer: t(
            'policyModal.publicHealth.notCovered2.answer',
            '- Vision aids (e.g. glasses or contact lenses) are not covered\n- Refractive eye surgery (e.g. LASIK) is not covered'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <WorldGlobeEarthIcon size={24} />,
          question: t(
            'policyModal.publicHealth.notCovered3.question',
            'Coverage outside the EU'
          ),
          answer: t(
            'policyModal.publicHealth.notCovered3.answer',
            '- There’s no coverage outside the EU.\n- Repatriation costs are not covered.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.publicHealth.title3', 'Details'),
      items: [
        {
          id: 'details-1',
          icon: <DependentsFamilyIcon size={24} />,
          question: t(
            'policyModal.publicHealth.details1.question',
            'Dependents can join for free'
          ),
          answer: t(
            'policyModal.publicHealth.details1.answer',
            'If the highest earner in the family is on public health insurance, children and non-working legal partners can be added for free.'
          ),
        },
        {
          id: 'details-2',
          icon: <VisaPassportIcon size={24} />,
          question: t(
            'policyModal.publicHealth.details2.question',
            'Works for all residence permits and visas'
          ),
          answer: t(
            'policyModal.publicHealth.details2.answer',
            'Public health insurance is valid for all residence permits and visas.'
          ),
        },
        {
          id: 'details-3',
          icon: <CreditCardIcon size={24} />,
          question: t(
            'policyModal.publicHealth.details3.question',
            'No up-front payments'
          ),
          answer: t(
            'policyModal.publicHealth.details3.answer',
            'Everything is directly billed to your provider using your insurance card, so you never have to pay out of pocket up-front.'
          ),
        },
        {
          id: 'details-4',
          icon: <TrendingUpIcon size={24} />,
          question: t(
            'policyModal.publicHealth.details4.question',
            'Prices increase over time'
          ),
          answer: t(
            'policyModal.publicHealth.details4.answer',
            'On average, prices have increased by 3,3% per year across all public health insurance providers since 2011.'
          ),
        },
      ],
    },
  ],
  buttonText: t('policyModal.publicHealth.buttonText', 'Choose a provider'),
  buttons: [
    {
      text: t(
        'policyModal.publicHealth.buttons.text',
        'Compare health insurances'
      ),
      href: 'https://feather-insurance.com/health-insurance',
    },
  ],
});
