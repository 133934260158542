import { BottomOrRegularModal, Button, IbanInput } from '@popsure/dirty-swan';
import { InlineButton } from 'features/studentChecklist/components';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { isValidIban } from 'shared/util/isValidIban';

import styles from './BankAccountModal.module.scss';

interface BankAccountModalProps {
  onClose: () => void;
  onSubmit: (iban: string) => void;
  initialIban?: string | null;
  loading: boolean;
  error?: string;
}

export const BankAccountModal = ({
  onClose,
  onSubmit,
  initialIban,
  loading,
  error,
}: BankAccountModalProps) => {
  const { t } = useSafeTranslation();
  const [iban, setIban] = useState<string>(initialIban ?? '');
  const [ibanError, setIbanError] = useState<string | undefined>(undefined);
  const [viewDetails, setViewDetails] = useState<boolean>(false);

  const handleFormSubmit = () => {
    setIbanError(undefined);
    if (!isValidIban(iban)) {
      setIbanError('Please enter a valid IBAN');
      return;
    }
    onSubmit(iban);
  };

  return (
    <BottomOrRegularModal title="Submit your IBAN" isOpen onClose={onClose}>
      <div className={styles.container}>
        <p className="p-p">
          {t(
            'studentChecklist.bankAccount.modal.description',
            'Your IBAN will be sent to the insurance provider. You will only be charged when your coverage starts.'
          )}
        </p>
        <IbanInput
          value={iban}
          onChange={setIban}
          placeholder={t(
            'studentChecklist.bankAccount.modal.input.placeholder',
            'IBAN'
          )}
          className="mb16 mt16"
        />
        {ibanError && <p className="p-notice p-notice--danger">{ibanError}</p>}
        <p className="my16 p-notice--info">
          {t(
            'studentChecklist.bankAccount.modal.additionalInfo',
            'Make sure the IBAN is correct before submitting'
          )}
        </p>
        {!viewDetails && (
          <InlineButton onClick={() => setViewDetails(true)}>
            {t(
              'studentChecklist.bankAccount.modal.agreement.button.title',
              'View details'
            )}
          </InlineButton>
        )}
        <AnimateHeight height={viewDetails ? 'auto' : 0}>
          {viewDetails && (
            <p className="p-p wmx5">
              {t(
                'studentChecklist.bankAccount.modal.agreement.description',
                'By providing your payment information and confirming this payment, you authorise (A) Popsure Deutschland GmbH and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.'
              )}
            </p>
          )}
        </AnimateHeight>
        <AnimateHeight duration={300} height={error ? 'auto' : 0}>
          <div className="p-notice p-notice--danger mt16 wmx8">{error}</div>
        </AnimateHeight>
        <Button
          data-testid="submit-bank-account"
          disabled={!iban}
          className="mt16 w100 wmx3 d-block"
          onClick={handleFormSubmit}
          loading={loading}
        >
          {t('studentChecklist.bankAccount.modal.submitButton.title', 'Submit')}
        </Button>
      </div>
    </BottomOrRegularModal>
  );
};
