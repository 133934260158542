import { insurance } from '@getpopsure/private-constants';
import {
  getRoutes,
  QuestionnaireQuestions,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { UserWithBrokerMandate } from 'models/user';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { AppState } from 'reducers';
import { getAccountInfo } from 'selectors/user';
import { useSafeTranslation } from 'shared/i18n';
import { SignupQuestionnaire } from 'SignupQuestionnaire';

import { ConfirmUpgrade } from './components/ConfirmUpgrade/ConfirmUpgrade';
import { RegionSelector } from './components/RegionSelector/RegionSelector';
import { ExpatLongTerm, isValidRegion, regionToNameMapping } from './models';
import {
  ExpatLongTermComponents,
  getTranslatedQuestionnaire,
} from './questionnaire';

export const YEARLY_MIN_INCOME_THRESHOLD =
  12 * insurance.healthInsurance.miniJobThreshold;

const getRules = (isOver18: boolean): Rule<ExpatLongTerm>[] => [
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: { type: 'years', value: 70 },
    },
    then: {
      goTo: 'ageIs70AndAboveBlocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: {
      op: 'dateDiff',
      variable: { type: 'years', value: 65 },
    },
    then: {
      goTo: 'ageIsBetween65And69Blocker',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'EMPLOYED' },
    },
    then: {
      goTo: 'employedOccupationBlocker',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: { type: 'static', value: 'PROFESSIONAL_ATHLETE' },
    },
    then: {
      goTo: 'professionalAthleteOccupationBlocker',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'HAVE_A_JOB_OFFER',
      },
    },
    then: {
      goTo: 'occupationDescription',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'SELF_EMPLOYED',
      },
    },
    then: {
      goTo: 'occupationDescription',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: 'OTHER',
      },
    },
    then: {
      goTo: 'occupationDescription',
    },
  },
  {
    id: 'occupation',
    if: () => {
      if (isOver18) {
        return true;
      }
      return false;
    },
    then: {
      goTo: 'addFamilyMembers',
    },
    else: {
      goTo: 'quote',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
  {
    id: 'startDate',
    if: () => {
      if (isOver18) {
        return true;
      }
      return false;
    },
    then: {
      goTo: 'signature',
    },
    else: {
      goTo: 'legalGuardianName',
    },
  },
  {
    id: 'legalGuardianName',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianDateOfBirth',
    },
  },
  {
    id: 'legalGuardianDateOfBirth',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'legalGuardianEmail',
    },
  },
];

const removeReview: RemoveAnswerType<ExpatLongTerm> = {
  op: 'always',
  questions: ['review'],
};

const removeAnswersLogic: Partial<
  Record<keyof ExpatLongTerm, RemoveAnswerType<ExpatLongTerm>>
> = {
  dateOfBirth: {
    op: 'always',
    questions: [
      'quote',
      'legalGuardianName',
      'legalGuardianDateOfBirth',
      'legalGuardianEmail',
      'review',
    ],
  },
  occupation: {
    op: 'always',
    questions: ['quote'],
  },
  quote: removeReview,
  name: removeReview,
  email: removeReview,
  review: {
    op: 'always',
    questions: ['hasDownloadedDocs'],
  },
};

const prefillNameAndBirthDate = <
  Questionnaire extends QuestionnaireQuestions,
  Key extends keyof Questionnaire
>(
  questionnaireAnswers: Partial<Questionnaire>,
  questionId: Key,
  answer: Questionnaire[Key],
  account?: UserWithBrokerMandate
) => {
  if (
    questionId === 'email' &&
    account &&
    account.email === answer &&
    questionnaireAnswers.email !== answer
  ) {
    let toUpdate: Partial<ExpatLongTerm> = {};
    const { firstName, lastName } = account;

    if (firstName && lastName) {
      toUpdate = { ...toUpdate, name: { firstName, lastName } };
    }
    return toUpdate;
  }

  return null;
};

export const SignupPage = () => {
  const questionnaireAnswers =
    useSelector(
      (state: AppState) => state.genericQuestionnaire.expatLongTerm
    ) || {};
  const dispatch = useDispatch();
  const account = useSelector(getAccountInfo);
  const { t } = useSafeTranslation();
  const history = useHistory();

  const switchFromExpat = useQueryParamValue('switchFromExpat');

  const { localeId } = useParams<{ localeId?: string }>();

  useEffect(() => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatLongTerm', { region: localeId })
    );
  }, [localeId, dispatch]);

  const { region: questionnaireRegion, quote: questionnaireQuote } =
    questionnaireAnswers;

  useEffect(() => {
    if (
      questionnaireRegion &&
      questionnaireQuote &&
      questionnaireRegion !== questionnaireQuote?.region
    ) {
      dispatch(
        removeGenericQuestionnaireAnswer('expatLongTerm', ['quote', 'region'])
      );
    }
  }, [dispatch, questionnaireQuote, questionnaireRegion]);

  if (switchFromExpat) {
    return (
      <ConfirmUpgrade
        onSubmit={() => {
          history.push(
            generatePath(routes.policies.expatLongTerm.path, {
              localeId: 'de',
            })
          );
        }}
      />
    );
  }

  if (!localeId || !isValidRegion(localeId)) {
    return <RegionSelector />;
  }

  const onAnswer = <QuestionId extends keyof ExpatLongTerm>(
    questionId: QuestionId,
    answer: ExpatLongTerm[QuestionId]
  ) => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatLongTerm', {
        [questionId]: answer,
        ...(questionId === 'intro' ? { isPolicyHolder: true } : {}),
      })
    );
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    dispatch(
      removeGenericQuestionnaireAnswer('expatLongTerm', answersToRemove)
    );

    const toUpdate = prefillNameAndBirthDate(
      questionnaireAnswers,
      questionId,
      answer,
      account
    );
    if (toUpdate) {
      dispatch(storeGenericQuestionnaireAnswer('expatLongTerm', toUpdate));
    }
  };

  const isOver18 = questionnaireAnswers.dateOfBirth
    ? dayjs().diff(questionnaireAnswers.dateOfBirth, 'years') >= 18
    : false;

  const rules = getRules(isOver18);

  const continueFromAgeIsBetween65And69Blocker = () => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatLongTerm', {
        ageIsBetween65And69Blocker: true,
      })
    );
    history.push(expatLongTermRoutes.occupation.path);
  };

  const continueFromEmployedOccupationBlocker = () => {
    dispatch(
      storeGenericQuestionnaireAnswer('expatLongTerm', {
        employedOccupationBlocker: true,
      })
    );
    history.push(
      isOver18
        ? expatLongTermRoutes.addFamilyMembers.path
        : expatLongTermRoutes.quote.path
    );
  };

  // TODO: Move this country-handling logic where it's centralized, translatable and reusable (f.e. i18n library)
  const region =
    localeId === 'nl' ? 'the Netherlands' : regionToNameMapping[localeId];

  const questions = getTranslatedQuestionnaire(
    t,
    region,
    localeId,
    continueFromAgeIsBetween65And69Blocker,
    continueFromEmployedOccupationBlocker
  );

  const basePath = generatePath(routes.policies.expatLongTerm.path, {
    localeId: String(localeId),
  });

  const baseQuestionnairePath =
    routes.policies.expatLongTerm.questionnaire.path.replace(
      ':localeId',
      String(localeId)
    );

  const expatLongTermRoutes = getRoutes(questions, basePath);

  const questionnaire = {
    components: questions,
    routes: expatLongTermRoutes,
    rules,
  };

  return (
    <Switch>
      <Route path={baseQuestionnairePath}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: ExpatLongTermComponents,
          }}
          basePath={basePath}
          questionId="intro"
          featureName="ExpatLongTerm"
        />
      </Route>
    </Switch>
  );
};
