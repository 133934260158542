import View from 'components/timedLoadSpinner/view';
import { useEffect, useState } from 'react';

const TIMEOUT_LENGTH = 1_500;

const TimedLoadSpinner = ({
  delayInMilliseconds,
  title,
  description,
  delayedAnimatedText,
}: {
  delayInMilliseconds?: number;
  title?: string;
  description?: string;
  delayedAnimatedText?: string;
}) => {
  const [displaySpinner, setDisplaySpinner] = useState(false);

  const waitTime =
    delayInMilliseconds !== undefined ? delayInMilliseconds : TIMEOUT_LENGTH;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplaySpinner(true);
    }, waitTime);
    return () => clearTimeout(timeout);
  }, [waitTime]);

  if (displaySpinner)
    return (
      <View
        title={title}
        description={description}
        delayedAnimatedText={delayedAnimatedText}
      />
    );

  return <></>;
};

export default TimedLoadSpinner;
