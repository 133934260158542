import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { getCheckoutInfo } from 'features/checkout/selectors';
import { CheckoutPolicyDetails } from 'features/dogLiabilitySpain/models';
import { ReferrerCodeVerify } from 'features/referralEngine';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';

import styles from './PolicyDetails.module.scss';

export const PolicyDetails = () => {
  const { t } = useSafeTranslation();

  const checkoutInfo = useSelector(
    getCheckoutInfo<CheckoutPolicyDetails>('DOG_LIABILITY_ES')
  );

  if (!checkoutInfo) {
    return <></>;
  }

  const { startDate, policyDetails } = checkoutInfo.mainPolicy;
  const { coveredArea, dogName, microchipNumber } = policyDetails;

  const formattedStartedDate = dayjs(startDate).format('DD MMM YYYY');

  return (
    <div className={styles.container}>
      <div className="p-h2 mb16">
        {t(
          'dogLiabilitySpain.qnr.checkout.policyDetails .title',
          'Policy details'
        )}
      </div>
      <Cell
        title={t(
          'dogLiabilitySpain.qnr.checkout.policyDetails.startDate',
          'Requested start date'
        )}
        value={formattedStartedDate}
        additionalInfo={{
          title: t(
            'dogLiabilitySpain.qnr.checkout.policyDetails.requestedStartDate.additionalInfo.title',
            'Requested start date'
          ),
          description: t(
            'dogLiabilitySpain.qnr.checkout.policyDetails.requestedStartDate.additionalInfo.description',
            'Your coverage should begin on {{coverageDate}}, but only after you have received the official confirmation.',
            {
              coverageDate: formattedStartedDate,
            }
          ),
        }}
      />
      {coveredArea && (
        <Cell
          title={t(
            'dogLiabilitySpain.qnr.checkout.policyDetails.coveredArea',
            'Area of coverage'
          )}
          value={coveredArea}
        />
      )}
      {dogName && (
        <Cell
          title={t(
            'dogLiabilitySpain.qnr.checkout.policyDetails.dogName',
            "Dog's name"
          )}
          value={dogName}
        />
      )}
      {microchipNumber && (
        <Cell
          title={t(
            'dogLiabilitySpain.qnr.checkout.policyDetails.microchipNumber',
            'Microchip number'
          )}
          value={microchipNumber}
        />
      )}
      <div className={`bg-grey-200 mt32 mb32 ${styles.divider}`} />
      {checkoutInfo?.mainPolicy.monthlyPrice && (
        <div className="d-flex">
          <div className="p-h3">
            {t(
              'dogLiabilitySpain.qnr.checkout.policyDetails.total',
              'Your monthly total'
            )}
          </div>
          <div className="p-h3 ml-auto">
            {formatCurrencyWithSpace(checkoutInfo?.mainPolicy.monthlyPrice, 2)}
          </div>
        </div>
      )}
      <ReferrerCodeVerify />
    </div>
  );
};
