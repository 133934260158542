import { RegionSelector as RegionSelectorView } from 'components/RegionSelector';
import routes from 'constants/routes';
import { regionToNameMapping } from 'features/expatEu/models';
import { generatePath, useHistory } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

export const RegionSelector = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const { eu, ...regions } = regionToNameMapping;

  const handleSubmit = (
    selectedRegion: string,
    setError: (error: string) => void
  ) => {
    const region = Object.entries(regions).find(([_, name]) => {
      return name === selectedRegion;
    });

    if (!region) {
      setError(
        t('expatEu.regionSelector.errorMsg', {
          defaultValue:
            'Unfortunately we do not offer Expat health insurance in {{region}}.',
          region: selectedRegion,
        })
      );
      return;
    }

    if (region[0] === 'de') {
      history.push(routes.policies.expat.path);
      return;
    }

    if (region[0] === 'es') {
      history.push(routes.policies.expatSpain.path);
      return;
    }

    history.push(
      generatePath(routes.policies.expatEu.path, { localeId: region[0] })
    );
  };

  return <RegionSelectorView onSubmit={handleSubmit} regions={regions} />;
};
