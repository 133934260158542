import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button, Checkbox } from '@popsure/dirty-swan';
import { Pension } from 'features/pension/models';
import { FormEvent, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import shieldIcon from '../InfoScreen/icons/shield.svg';
import styles from './LegalDisclaimer.module.scss';

export const LegalDisclaimer = ({
  onSubmitValue,
}: QuestionnaireFormProps<Pension>) => {
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
  const { t } = useSafeTranslation();

  const isValid =
    checkboxValues.includes('riskConsent') &&
    checkboxValues.includes('shortTermLossConsent');

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    onSubmitValue(true, {
      riskConsent: checkboxValues.includes('riskConsent'),
      shortTermLossConsent: checkboxValues.includes('shortTermLossConsent'),
    });
  };

  return (
    <form
      className={`p-container d-flex fd-column ai-start jc-center wmx12 ${styles.container}`}
      onSubmit={handleSubmit}
    >
      <img src={shieldIcon} alt="shield icon" />
      <h1 className="p-h1 mt16 mb8">
        {t('pension.qnr.legalDisclaimer.title', 'Before we continue...')}
      </h1>
      <p className="p-p">
        {t(
          'pension.qnr.legalDisclaimer.description',
          'We need your confirmation for the following items:'
        )}
      </p>
      <Checkbox
        bordered={false}
        classNames={{ container: 'mt24', label: 'wmx8' }}
        onChange={setCheckboxValues}
        wide
        options={{
          riskConsent: t(
            'pension.qnr.legalDisclaimer.riskConsent.checkbox.text',
            'I’m aware and willing to take the risk that I may not get back all the money I invest. There is no guarantee, which is true for almost all investment products.'
          ),
          shortTermLossConsent: t(
            'pension.qnr.legalDisclaimer.shortTermLoss.checkbox.text',
            'I acknowledge that I can lose a large part of my investment in the short term by investing my Pension into ETFs, which is true for all stock market ETF-based products.'
          ),
        }}
        value={checkboxValues}
      />
      <div className="mt24 d-flex gap16">
        <Button disabled={!isValid} data-cy="button-continue">
          {t('pension.qnr.legalDisclaimer.button.caption', 'Continue')}
        </Button>
      </div>
    </form>
  );
};
