import { TFunction } from '@getpopsure/i18n-react';
import { Button, Input } from '@popsure/dirty-swan';
import {
  FORMATTED_BONUS_AMOUNT,
  FORMATTED_LIMIT_AMOUNT,
  REFERRAL_ENGINE_TC_URL,
} from 'features/referralEngine/constants';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Trans, useSafeTranslation } from 'shared/i18n';

const getErrorMessage = (t: TFunction, error?: string): string | undefined => {
  if (!error) return;

  if (error === 'NEW_CUSTOMERS_ONLY')
    return t(
      'referralengine.verifymodal.error.newCustomersOnly',
      'This code is only valid for new customers'
    );

  if (error === 'NOT_VALID')
    return t(
      'referralengine.verifymodal.error.notValid',
      'This code is not valid'
    );

  return t(
    'referralengine.verifymodal.error.default',
    'This code is not valid'
  );
};

interface ReferrerCodeVerifyModalProps {
  onSubmitCode(code: string): void;
  loading?: boolean;
  error?: string;
}

export const ReferrerCodeVerifyModal = (
  props: ReferrerCodeVerifyModalProps
) => {
  const { onSubmitCode, loading, error } = props;
  const [code, setCode] = useState<string>('');
  const { t } = useSafeTranslation();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (code) {
      onSubmitCode(code);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p24 wmx6">
      <p className="p-p">
        <Trans i18nKey="referralengine.verifymodal.title">
          You’ll{' '}
          <span className="fw-bold">
            receive the {{ bonus: FORMATTED_BONUS_AMOUNT }}
          </span>{' '}
          bonus after 3 months*
        </Trans>
      </p>
      <Input
        placeholder={t(
          'referralengine.verifymodal.input.placeholder',
          'Promo code'
        )}
        className="mt16"
        value={code}
        onChange={handleInputChange}
        required
        error={getErrorMessage(t, error)}
      />
      <Button className="mt24 w100" type="submit" loading={loading}>
        {t('referralengine.verifymodal.button.submit', 'Submit')}
      </Button>
      <Trans i18nKey="referralengine.verifymodal.disclaimer">
        <p className="p-p p-p--small mt24">
          *Only valid once your policy has been active for 3 months. You will
          receive an email to set up the bank account to which the payout will
          be sent. The payout is limited to{' '}
          {{ bonusLimit: FORMATTED_LIMIT_AMOUNT }} per year per person. See our{' '}
          <a
            className="p-a"
            href={REFERRAL_ENGINE_TC_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </a>{' '}
          for more information.
        </p>
      </Trans>
    </form>
  );
};
