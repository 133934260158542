import { insurance } from '@getpopsure/private-constants';
import { Rule } from '@getpopsure/qnr-framework';
import { isOver } from 'shared/util/isOver';
import { isUnder18 } from 'shared/util/isUnder18';

import { getJobCollar } from './constants/profession';
import { DisabilityV2, Profession } from './models';

const rockClimbingDetailsBlockers = [
  'OVER_4000_METERS_ALTITUDE',
  'NO_RECOMMENDED_SAFETY_EQUIPMENT',
  'ICE_OR_GLACIER_CLIMBING',
];

const scubaDivingDetailsBlockers = [
  'DEEPER_THAN_50_METERS',
  'CAVE_OR_ICE_DIVING',
  'REBREATHER_OR_SOLO_DIVING',
];

const isProfession = (profession: unknown): profession is Profession => {
  return (
    typeof profession === 'object' &&
    (profession as Profession).value !== undefined
  );
};

export const rules: Rule<DisabilityV2>[] = [
  // PreQuote
  // Personal information
  {
    id: 'dateOfBirth',
    if: (dateOfBirth) => isUnder18(dateOfBirth as string),
    then: {
      goTo: 'ageYoungerThan18Blocker',
    },
  },
  {
    id: 'dateOfBirth',
    if: (dateOfBirth) => isOver(dateOfBirth as string, 50),
    then: {
      goTo: 'ageOlderThan50Blocker',
    },
  },
  {
    id: 'occupation',
    if: {
      op: 'isIn',
      variable: {
        type: 'static',
        value: ['STUDENT', 'UNEMPLOYED', 'OTHER'],
      },
    },
    then: {
      goTo: 'occupationBlocker',
    },
  },
  {
    id: 'profession',
    if: (profession) => {
      if (isProfession(profession)) {
        return getJobCollar(profession.value) === 'X';
      }
      return false;
    },
    then: {
      goTo: 'tallyForm',
    },
  },
  {
    id: 'monthlyNetIncome',
    if: (monthlyNetIncome) =>
      (monthlyNetIncome as number) < insurance.healthInsurance.miniJobThreshold,
    then: {
      goTo: 'monthlyNetIncomeBlocker',
    },
  },
  {
    id: 'stayAbroad',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'stayAbroadDetails',
    },
  },
  {
    id: 'stayAbroad',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  // Insurance history
  {
    id: 'hasPreviousInsurance',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'previousInsuranceDetails',
    },
  },
  {
    id: 'hasPreviousInsurance',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'hasActiveOrPendingInsurance',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'activeOrPendingInsuranceDetails',
    },
  },
  {
    id: 'hasActiveOrPendingInsurance',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  // Medical history
  {
    id: 'hasDegreeOfDisability',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'degreeOfDisabilityHealthIssuesDetails',
    },
  },
  {
    id: 'hasDegreeOfDisability',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'degreeOfDisabilityHealthIssuesDetails',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'degreeOfDisabilityPercentage',
    },
  },
  {
    id: 'degreeOfDisabilityPercentage',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'degreeOfDisabilityDuration',
    },
  },
  {
    id: 'hasReceivedPhysicalExam',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasPrescribedMedication',
    },
  },
  {
    id: 'hasPrescribedMedication',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'prescribedMedicationDetails',
    },
    else: {
      goTo: 'hasConsecutiveSickLeave',
    },
  },
  {
    id: 'hasPrescribedMedication',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'prescribedMedicationDetails',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'prescribedMedicationDosage',
    },
  },
  {
    id: 'prescribedMedicationDosage',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'prescribedMedicationDuration',
    },
  },
  {
    id: 'prescribedMedicationDuration',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'prescribedMedicationReason',
    },
  },
  {
    id: 'prescribedMedicationReason',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'hasConsecutiveSickLeave',
    },
  },
  {
    id: 'hasConsecutiveSickLeave',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'sickLeaveReason',
    },
    else: {
      goTo: 'hasReceivedSubstanceAbuseTreatment',
    },
  },
  {
    id: 'hasConsecutiveSickLeave',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'sickLeaveReason',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'sickLeaveDuration',
    },
  },
  {
    id: 'sickLeaveDuration',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'hasSickLeaveClearance',
    },
  },
  {
    id: 'hasReceivedSubstanceAbuseTreatment',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
    else: {
      goTo: 'hasTreatmentForHeartTumorKidneyDiabetes',
    },
  },
  {
    id: 'hasTreatmentForHeartTumorKidneyDiabetes',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
    else: {
      goTo: 'hasUsedOrPrescribedMedicalAids',
    },
  },
  {
    id: 'hasUsedOrPrescribedMedicalAids',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'medicalAidsDetails',
    },
    else: {
      goTo: 'hasTreatmentForNervousOrMusculoskeletalIssues',
    },
  },
  {
    id: 'hasUsedOrPrescribedMedicalAids',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'medicalAidsDetails',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'medicalAidPrescriptionReason',
    },
  },
  {
    id: 'medicalAidPrescriptionReason',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'isMedicalAidStillUsed',
    },
  },
  {
    id: 'isMedicalAidStillUsed',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'medicalAidPrescriptionStartDate',
    },
  },
  {
    id: 'hasTreatmentForNervousOrMusculoskeletalIssues',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'nervousOrMusculoskeletalIssuesDetails',
    },
  },
  {
    id: 'hasTreatmentForNervousOrMusculoskeletalIssues',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'nervousOrMusculoskeletalIssuesDetails',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'nervousOrMusculoskeletalIssuesDetails',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'nervousOrMusculoskeletalIssuesTreatmentDuration',
    },
  },
  {
    id: 'nervousOrMusculoskeletalIssuesTreatmentDuration',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'isCuredFromNervousOrMusculoskeletalTreatment',
    },
  },

  {
    id: 'hasReceivedPsychoTherapy',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'psychoTherapyDetails',
    },
  },
  {
    id: 'hasReceivedPsychoTherapy',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'psychoTherapyDetails',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'isStillReceivingPsychoTherapy',
    },
  },
  {
    id: 'isStillReceivingPsychoTherapy',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'isCuredFromPsychoTherapy',
    },
  },
  {
    id: 'isCuredFromPsychoTherapy',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'psychoTherapyEndDate',
    },
  },
  {
    id: 'hasBeenHospitalized',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hospitalizationReason',
    },
  },
  {
    id: 'hasBeenHospitalized',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'hasEligibilityCheck',
    },
  },
  {
    id: 'hospitalizationReason',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'hospitalizationDuration',
    },
  },
  {
    id: 'hospitalizationDuration',
    if: {
      op: 'always',
    },
    then: {
      goTo: 'isIllnessCured',
    },
  },
  {
    id: 'hasReceivedHivTreatment',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
  },
  // Extreme sports
  {
    id: 'participatesInRockClimbing',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'rockClimbingDetails',
    },
  },
  {
    id: 'rockClimbingDetails',
    if: (rockClimbingDetails) =>
      ((rockClimbingDetails || []) as string[]).some((detail) =>
        rockClimbingDetailsBlockers.includes(detail)
      ),
    then: {
      goTo: 'postSignupBlocker',
    },
  },
  {
    id: 'participatesInScubaDiving',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'scubaDivingDetails',
    },
  },
  {
    id: 'scubaDivingDetails',
    if: (scubaDivingDetails) =>
      ((scubaDivingDetails || []) as string[]).some((detail) =>
        scubaDivingDetailsBlockers.includes(detail)
      ),
    then: {
      goTo: 'postSignupBlocker',
    },
  },
  {
    id: 'participatesInBoulderingAndMountainSports',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'participatesInExtremeMountainSports',
    },
  },
  {
    id: 'participatesInExtremeMountainSports',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
  },
  {
    id: 'participatesInMartialArts',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'participatesInCompetitiveMartialArts',
    },
  },
  {
    id: 'participatesInCompetitiveMartialArts',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
  },
  {
    id: 'participatesInHorseRiding',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'participatesInEquestrianSports',
    },
  },
  {
    id: 'participatesInEquestrianSports',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
  },
  {
    id: 'participatesInCompetitiveRacing',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
  },
  {
    id: 'participatesInAviationSports',
    if: {
      op: 'equals',
      variable: {
        type: 'static',
        value: true,
      },
    },
    then: {
      goTo: 'postSignupBlocker',
    },
  },
];
