import { dentalQuoteComparison_enDe } from '@getpopsure/data-sync';
import { useTranslation } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { Table, TableProps } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import { Dispatch, SetStateAction } from 'react';
import { isMobileApp } from 'shared/util/isMobileApp';

import { DentalQuotePlan, Plan, QuoteCustomization } from '../../../models';

interface ComparisonTableProps {
  selectedPlan: Plan;
  setQuoteCustomization: Dispatch<SetStateAction<QuoteCustomization>>;
  planInfoMapping: DentalQuotePlan;
}

export const ComparisonTable = (props: ComparisonTableProps) => {
  const { t } = useTranslation();
  const { selectedPlan, planInfoMapping, setQuoteCustomization } = props;

  const cellReplacements = {
    'table_row$50e94fcb-b761-4784-aabc-be44207b8a59_0': {
      onClick: () =>
        setQuoteCustomization((prevState) => ({
          ...prevState,
          plan: 'BASIC',
        })),
      isSelected: selectedPlan === 'BASIC',
      price: `${englishFormattedEuroCurrency(planInfoMapping.BASIC.price)}/mo`,
      disabled: false,
    },
    'table_row$50e94fcb-b761-4784-aabc-be44207b8a59_1': {
      onClick: () =>
        setQuoteCustomization((prevState) => ({
          ...prevState,
          plan: 'ADVANCED',
        })),
      isSelected: selectedPlan === 'ADVANCED',
      price: t(
        'dental.qnr.preQuote.quote.coverageTable.price',
        '{{price}}/mo',
        {
          price: englishFormattedEuroCurrency(planInfoMapping.ADVANCED.price),
        }
      ),
      disabled: false,
      dataTestId: 'table-advanced-plan-button',
    },
  };

  const handleOnSelectionChanged = (index: number) => {
    const plan = index === 1 ? 'BASIC' : 'ADVANCED';
    setQuoteCustomization((prevState) => ({ ...prevState, plan }));
  };

  return (
    <Table
      title={dentalQuoteComparison_enDe.tableData.title}
      tableData={
        dentalQuoteComparison_enDe.tableData
          .tableData as TableProps['tableData']
      }
      cellReplacements={cellReplacements}
      stickyHeaderTopOffset={isMobileApp ? 88 : 0}
      onSelectionChanged={handleOnSelectionChanged}
      modalContentRenderer={(markdown) =>
        typeof markdown === 'string' ? (
          <Markdown>{markdown}</Markdown>
        ) : (
          markdown
        )
      }
    />
  );
};
