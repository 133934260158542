import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { PostQuoteQuestionnaire } from 'models/bike';
import endpoint from 'shared/api';

type UpdateBikeV1QuestionnaireData<T extends keyof PostQuoteQuestionnaire> = {
  questionnaireId: string;
  infoType: T;
  answer: Partial<PostQuoteQuestionnaire[T]>;
  uploadTokens?: string[];
};

export const useUpdateBikeV1Questionnaire = <
  T extends keyof PostQuoteQuestionnaire
>() => {
  const mutation = useMutation({
    mutationFn: (data: UpdateBikeV1QuestionnaireData<T>) => {
      const { questionnaireId, infoType, answer, uploadTokens } = data;
      return endpoint.updateBikeQuestionnaire(
        { [infoType]: { ...answer } },
        questionnaireId,
        uploadTokens
      );
    },
    onError: (error, variables) => {
      const { questionnaireId, answer } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'BIKE',
        },
        extra: {
          questionnaireId,
          answer,
          description: 'Error happened while updating Bike questionnaire',
        },
      });
    },
  });

  return mutation;
};
