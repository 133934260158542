import { TFunction } from '@getpopsure/i18n-react';

import { questionPropLookup } from '../lookups';
import { DisabilityV2Questionnaire } from '.';

export const getMedicalHistoryQuestionnaire = (
  t: TFunction
): DisabilityV2Questionnaire => [
  {
    id: 'isSmoker',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).isSmoker,
    },
  },
  {
    id: 'hasDegreeOfDisability',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasDegreeOfDisability,
    },
  },
  {
    id: 'degreeOfDisabilityHealthIssuesDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).degreeOfDisabilityHealthIssuesDetails,
    },
  },
  {
    id: 'degreeOfDisabilityPercentage',
    groupId: 'signup',
    type: 'INPUT',
    props: {},
    screen: {
      ...questionPropLookup(t).degreeOfDisabilityPercentage,
    },
  },
  {
    id: 'degreeOfDisabilityDuration',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).degreeOfDisabilityDuration,
    },
  },
  {
    id: 'hasReceivedPhysicalExam',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasReceivedPhysicalExam,
    },
  },
  {
    id: 'hasPrescribedMedication',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasPrescribedMedication,
    },
  },
  {
    id: 'prescribedMedicationDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).prescribedMedicationDetails,
    },
  },
  {
    id: 'prescribedMedicationDosage',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).prescribedMedicationDosage,
    },
  },
  {
    id: 'prescribedMedicationDuration',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).prescribedMedicationDuration,
    },
  },
  {
    id: 'prescribedMedicationReason',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).prescribedMedicationReason,
    },
  },
  {
    id: 'hasConsecutiveSickLeave',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasConsecutiveSickLeave,
    },
  },
  {
    id: 'sickLeaveReason',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).sickLeaveReason,
    },
  },
  {
    id: 'sickLeaveDuration',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).sickLeaveDuration,
    },
  },
  {
    id: 'hasSickLeaveClearance',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasSickLeaveClearance,
    },
  },
  {
    id: 'hasReceivedSubstanceAbuseTreatment',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasReceivedSubstanceAbuseTreatment,
    },
  },
  {
    id: 'hasTreatmentForHeartTumorKidneyDiabetes',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasTreatmentForHeartTumorKidneyDiabetes,
    },
  },
  {
    id: 'hasUsedOrPrescribedMedicalAids',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasUsedOrPrescribedMedicalAids,
    },
  },
  {
    id: 'medicalAidsDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).medicalAidsDetails,
    },
  },
  {
    id: 'medicalAidPrescriptionReason',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).medicalAidPrescriptionReason,
    },
  },
  {
    id: 'isMedicalAidStillUsed',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).isMedicalAidStillUsed,
    },
  },
  {
    id: 'medicalAidPrescriptionStartDate',
    groupId: 'signup',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
    },
    screen: {
      ...questionPropLookup(t).medicalAidPrescriptionStartDate,
    },
  },
  {
    id: 'hasTreatmentForNervousOrMusculoskeletalIssues',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasTreatmentForNervousOrMusculoskeletalIssues,
    },
  },
  {
    id: 'nervousOrMusculoskeletalIssuesDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).nervousOrMusculoskeletalIssuesDetails,
    },
  },
  {
    id: 'nervousOrMusculoskeletalIssuesTreatmentDuration',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).nervousOrMusculoskeletalIssuesTreatmentDuration,
    },
  },
  {
    id: 'isCuredFromNervousOrMusculoskeletalTreatment',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).isCuredFromNervousOrMusculoskeletalTreatment,
    },
  },
  {
    id: 'hasReceivedPsychoTherapy',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasReceivedPsychoTherapy,
    },
  },
  {
    id: 'psychoTherapyDetails',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).psychoTherapyDetails,
    },
  },
  {
    id: 'isStillReceivingPsychoTherapy',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).isStillReceivingPsychoTherapy,
    },
  },
  {
    id: 'isCuredFromPsychoTherapy',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).isCuredFromPsychoTherapy,
    },
  },
  {
    id: 'psychoTherapyEndDate',
    groupId: 'signup',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
    },
    screen: {
      ...questionPropLookup(t).psychoTherapyEndDate,
    },
  },
  {
    id: 'hasBeenHospitalized',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasBeenHospitalized,
    },
  },
  {
    id: 'hospitalizationReason',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).hospitalizationReason,
    },
  },
  {
    id: 'hospitalizationDuration',
    groupId: 'signup',
    type: 'TEXT',
    props: {},
    screen: {
      ...questionPropLookup(t).hospitalizationDuration,
    },
  },
  {
    id: 'isIllnessCured',
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).isIllnessCured,
    },
  },
  {
    id: 'hasReceivedHivTreatment',
    required: true,
    groupId: 'signup',
    type: 'BOOLEAN',
    props: {},
    screen: {
      ...questionPropLookup(t).hasReceivedHivTreatment,
    },
  },
];
