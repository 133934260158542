import { website } from '@getpopsure/private-constants';
import { illustrations } from '@popsure/dirty-swan';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

export const MissingDetailsBlocker = () => {
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container}>
      <div className={`p-body ta-center wmx8 ${styles.innerContainer}`}>
        <img
          src={illustrations.shieldWarning}
          alt={t('claims.bike.blocker.missingDetails.imgAlt', 'Purple shield')}
          width={68}
        />
        <header className="mt16 p-h1">
          {t(
            'claims.bike.blocker.missingDetails.title',
            'We can’t process your claim yet'
          )}
        </header>
        <p className={`p-p mt16 ${styles.description}`}>
          {t(
            'claims.bike.blocker.missingDetails.description',
            'It looks like the bike frame number and/or proof of purchase is missing. We need this information to process your claim. \n\nYou can reach out to us to provide the missing details.'
          )}
        </p>
        <a
          className={`p-btn--primary mt16 wmn3 ${styles.button}`}
          href={website.support}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('claims.bike.blocker.missingDetails.cta', 'Contact us')}
        </a>
      </div>
    </div>
  );
};
