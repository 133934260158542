import styles from './styles.module.scss';

export interface Props {
  title: string | JSX.Element;
  description?: string;
  icon: { src: string; alt: string };
  className?: string;
}

export const GettingStartedCard = ({
  className,
  title,
  description,
  icon,
}: Props) => (
  <div className={`d-flex p16 wmx8 bg-white br8 ${styles.card} ${className}`}>
    <img className={`mr16 ${styles.icon}`} src={icon.src} alt={icon.alt} />
    <div className="d-flex fd-column jc-center">
      <h2 className="p-h4">{title}</h2>
      {description && <p className="p-p tc-grey-600 mt8">{description}</p>}
    </div>
  </div>
);
