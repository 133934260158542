import { TFunction } from 'i18next';

import { questionPropLookup } from '../lookups';
import {
  identificationDocumentMapping,
  incomeSourceMapping,
  InvestmentStrategyMap,
  InvestmentSustainabilityMap,
  occupationMapping,
  paysTaxesInGermanyMapping,
  Pension,
  QuestionnaireTitles,
  UpdateableQuestionnaire,
} from '../models';

const omittedinvestmentStrategyInfoKeys = [
  'privatePensionInfo',
  'privatePensionLongerThan5YearsInfo',
] as const;

export const createLabelTitles = (
  answers: UpdateableQuestionnaire,
  t: TFunction
) => {
  const { shortTermLossConsent, riskConsent, submitApplication } =
    questionPropLookup(t);

  const labelTitles: QuestionnaireTitles = {
    investmentStrategy: {},
    personalInfo: {},
    financialHistory: {
      sepa: questionPropLookup(t).sepa.question,
    },
    legalDisclaimer: {
      shortTermLossConsent: shortTermLossConsent.question,
      riskConsent: riskConsent.question,
      hasReviewedDocuments: submitApplication.question,
    },
    tariffInfo: {},
  };

  Object.keys(answers.investmentStrategy).forEach((key) => {
    labelTitles.investmentStrategy[key] = questionPropLookup(t)[
      key as keyof Pension
    ]
      ? questionPropLookup(t)[key as keyof Pension].question
      : '';
  });

  Object.keys(answers.personalInfo).forEach((key) => {
    labelTitles.personalInfo[key] = questionPropLookup(t)[key as keyof Pension]
      ? questionPropLookup(t)[key as keyof Pension].question
      : '';
  });

  Object.keys(answers.financialHistory).forEach((key) => {
    labelTitles.financialHistory[key] = questionPropLookup(t)[
      key as keyof Pension
    ]
      ? questionPropLookup(t)[key as keyof Pension].question
      : '';
  });

  omittedinvestmentStrategyInfoKeys.forEach(
    (key) => delete labelTitles.investmentStrategy[key]
  );
  return labelTitles;
};

export const labelAnswers = (t: TFunction) => ({
  tariffInfo: {},
  investmentStrategy: {
    investmentSustainability: InvestmentSustainabilityMap(t),
    investmentStrategy: InvestmentStrategyMap(t),
  },
  personalInfo: {
    identificationDocument: identificationDocumentMapping(t),
  },
  financialHistory: {
    paysTaxesInGermany: paysTaxesInGermanyMapping(t),
    occupation: occupationMapping(t),
    incomeSource: incomeSourceMapping(t),
  },
  legalDisclaimer: {},
});
