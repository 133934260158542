import { Gender } from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { ActionResponse } from 'shared/models/types';
import { ZGenderSchema, ZNameSchema } from 'shared/models/validations';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

const PLANS = ['BASIC', 'ADVANCED', 'PREMIUM'] as const;

export type Plan = typeof PLANS[number];

export const ZTravelHealthQuote = z.object({
  id: z.string(),
  planId: z.enum(PLANS),
  price: z.number(),
});

export type TravelHealthQuote = z.TypeOf<typeof ZTravelHealthQuote>;

// TODO: define the checkout policy details
export type TravelCheckoutPolicyDetails = {
  startDate: string;
  planId: string;
  othersCovered: Array<{
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender?: string | undefined;
  }>;
};

// TODO: define the required  quote schema
export type CreateQuoteReturnType = {
  status: ActionResponse;
};

const coverOptions = z.enum(['MYSELF', 'MYSELF_AND_OTHERS']);
export type CoverOptions = z.TypeOf<typeof coverOptions>;

export type TravelHealthV1 = {
  intro: SCREEN;
  cover: CoverOptions;
  numberOfCovered?: number;
  numberOfCoveredBlocker?: BLOCKER;

  dateOfBirth: string;
  ageBlocker?: BLOCKER;

  isLivingInGermany: boolean;
  germanyBlocker?: BLOCKER;

  allCoveredLivingInGermany?: boolean;
  anyCoveredOver64?: boolean;

  questionnaireId: string;
  quoteId: string;
  quote: TravelHealthQuote;

  startDate: string;

  othersCovered?: (Name & {
    dateOfBirth: string;
    gender?: Gender;
  })[];

  name: Name;
  email: string;
  gender: Gender;

  review: boolean;
  // downloadDocuments: boolean;
  checkoutInfo: CheckoutInfo<TravelCheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;
};

export const ZSubmittableTravelHealthV1Questionnaire = z
  .object({
    name: ZNameSchema,
    email: z.string(),
    gender: ZGenderSchema,
    dateOfBirth: z.string(),
    startDate: z.string(),
    othersCovered: z
      .array(
        z.object({
          firstName: z.string(),
          lastName: z.string(),
          dateOfBirth: z.string(),
          gender: z.string().optional(),
        })
      )
      .optional()
      .default([]),
    quote: ZTravelHealthQuote,
  })
  .transform(
    ({
      name,
      email,
      gender,
      dateOfBirth,
      startDate,
      othersCovered,
      quote,
    }) => ({
      personalInfo: {
        name,
        email,
        gender,
        dateOfBirth,
      },
      tariffInfo: {
        startDate,
        planId: quote.planId,
        cover: othersCovered.length === 0 ? 'MYSELF' : 'MYSELF_AND_OTHERS',
        numberOfCovered: othersCovered.length,
        othersCovered,
      },
    })
  );
export type SubmittableTravelHealthV1Questionnaire = z.infer<
  typeof ZSubmittableTravelHealthV1Questionnaire
>;

export type TravelHealthV1GroupIds = 'preQuote' | 'signup';

const TravelAnswerKeys = [
  'dateOfBirth',
  'isLivingInGermany',
  'email',
  'name',
  'gender',
] as const;
type TravelAnswerKey = typeof TravelAnswerKeys[number];
type TravelTariffInfo = SubmittableTravelHealthV1Questionnaire['tariffInfo'];

export type TravelHealthV1Meta = {
  labels: {
    title: {
      personalInfo: Record<TravelAnswerKey, string>;
      tariffInfo: Record<keyof TravelTariffInfo, string>;
    };
    answers: {
      personalInfo: Partial<Record<TravelAnswerKey, unknown>>;
      tariffInfo: Partial<Record<keyof TravelTariffInfo, unknown>>;
    };
  };
  questionOrder: {
    personalInfo: TravelAnswerKey[];
    tariffInfo: string[];
  };
};
