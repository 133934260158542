import { type MultiDropzoneProps, MultiDropzone } from '@popsure/dirty-swan';
import { TaskEngineContext } from 'features/taskEngine/utils/taskEngine.context';
import { useUploadFiles } from 'hooks/useUploadFiles';
import { useContext, useEffect } from 'react';

export type FileInputProps = {
  onValidate: (valid: boolean) => void;
} & Omit<
  MultiDropzoneProps,
  'uploading' | 'onFileSelect' | 'uploadedFiles' | 'onRemoveFile'
>;

// Makes POST request to /questionniare/uploads that creates a record in Uploads table
export const FileInputQuestionnaire = (props: FileInputProps) => {
  const { onValidate } = props;
  const {
    files: {
      uploadedFiles: uploadedFilesInContext,
      setUploadedFiles: setUploadedFilesToContext,
    },
  } = useContext(TaskEngineContext);
  const {
    uploadFile,
    loading: uploading,
    removeFile,
    isSubmitValid,
    uploadedFiles,
  } = useUploadFiles({
    initialValue: uploadedFilesInContext,
    isQuestionnaire: true,
  });

  useEffect(() => {
    onValidate(isSubmitValid);
    setUploadedFilesToContext(uploadedFiles);
    // `onValidate` runs in an infinite loop when added to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitValid]);

  return (
    <MultiDropzone
      {...props}
      uploadedFiles={uploadedFiles}
      onFileSelect={(files: File[]) => {
        uploadFile(files);
      }}
      onRemoveFile={removeFile}
      uploading={uploading}
    />
  );
};
