import { captureException } from '@sentry/react';
import { InsuranceTypes } from 'models/insurances/types';
import { useEffect } from 'react';
import endpoint from 'shared/api';

export const useCheckoutStarted = (
  insuranceType: InsuranceTypes,
  email?: string
) => {
  useEffect(() => {
    if (!email) {
      return;
    }

    endpoint
      .setCheckoutStarted(insuranceType, email)
      .then(({ status }) => {
        if (status !== 201) {
          captureException('Checkout returned a non success status', {
            tags: {
              feature: 'Checkout Started',
            },
          });
        }
      })
      .catch((error) => {
        if (error) {
          captureException(error, {
            tags: {
              feature: 'Checkout Started',
            },
            extra: {
              description: 'Checkout starting failed',
            },
          });
        }
      });
  }, [email, insuranceType]);
};
