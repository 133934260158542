import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { MailingListResponse } from 'models/mailingPreferences';
import endpoint from 'shared/api';

const getMailingPreferences = async (): Promise<MailingListResponse> => {
  const { data } = await endpoint.getMailingPreferences();

  return data;
};

export const useGetMailingPreferences = () => {
  const { isLoading, data, error } = useQuery<MailingListResponse, AxiosError>({
    queryKey: ['mailingPreferences'],
    queryFn: getMailingPreferences,
    enabled: true,
  });

  return {
    isLoading,
    data,
    error,
  };
};
