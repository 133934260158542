import { BottomOrRegularModal, InfoCard } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { CoverageId, QuotePlan } from 'models/expat';
import { useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import { coverageByPlan, coverageModalContent } from '../content';
import styles from './style.module.scss';

type CardState = 'actionable' | 'static' | 'muted';

export const isCoveredInPlan = (coveredInBasic: boolean): boolean =>
  coveredInBasic === true;

export const retrieveCardState = (
  coveredInBasic: boolean,
  id?: CoverageId
): CardState => {
  if (isCoveredInPlan(coveredInBasic) === true) {
    if (id !== undefined) {
      return 'actionable';
    }
    return 'static';
  }

  return 'muted';
};

const ComparisonTable = ({
  selectedPlan,
  region,
}: {
  selectedPlan: QuotePlan;
  region: string;
}) => {
  const [coverage, setCoverage] = useState<CoverageId | undefined>();
  const { t } = useSafeTranslation();

  return (
    <div className={styles.container}>
      <BottomOrRegularModal
        title={coverage ? coverageModalContent(t, region)[coverage]?.title : ''}
        isOpen={coverage !== undefined}
        onClose={() => setCoverage(undefined)}
      >
        {coverage && coverageModalContent(t, region)[coverage].content}
      </BottomOrRegularModal>

      <div className={`mt16 ${styles['card-container']}`}>
        {coverageByPlan(selectedPlan, t).map(
          ({ title, icon, description, coveredInBasic, id }) => {
            const state = retrieveCardState(coveredInBasic, id);
            if (state === 'muted') {
              return null;
            }

            return (
              <div key={title}>
                <InfoCard
                  onClick={() => {
                    if (state === 'actionable' && id !== undefined) {
                      setCoverage(id);
                    }
                  }}
                  className={classNames(
                    'mt32 ml16 wmx4',
                    styles['card-height']
                  )}
                  title={title}
                  topIcon={icon}
                  state={state}
                  rightIcon={state === 'actionable' ? 'info' : undefined}
                >
                  <div
                    className={`p-p ta-center tc-grey-600 ${styles['info-description']}`}
                  >
                    {description}
                  </div>
                </InfoCard>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default ComparisonTable;
