import { RequestAction } from 'constants/actions';
import { insuranceTypes } from 'models/insurances/types';
import { AppState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { ZAddressSchema } from 'shared/models/validations';
import { z } from 'zod';

export interface TaskEngineFilterOptions {
  by: string;
  value: string;
}

export interface TaskEngineScreenOptions {
  title: string;
}

export interface TaskEngineOptions {
  screen?: TaskEngineScreenOptions;
  filter?: (value: Task, index: number, array: Task[]) => boolean;
  onTaskClick?: (task: Task) => void;
}

export interface TaskEngineProps {
  options?: TaskEngineOptions;
  render?: (args: {
    tasks: JSX.Element[];
    error?: unknown;
  }) => JSX.Element | null;
}

export interface TaskScreenProps {
  description?: string;
  input?: {
    name?: string;
    type?: string;
    placeholder?: string;
    pattern?: string;
  };
  button?: {
    title?: string;
  };
  additionalInfo?: string;
}

export const TASK_TYPES = [
  'BIKE_FRAME_NUMBER_UPDATE',
  'BIKE_RECEIPTS_UPDATE',
  'CLAIM_MISSING_INFO_REDIRECT',
  'LIABILITY_ADDRESS_UPDATE',
  'PRIVATE_HEALTH_TAX_ID_UPDATE',
  'PRIVATE_HEALTH_RELOCATION_DATE_UPDATE',
  'PRIVATE_HEALTH_ADDRESS_UPDATE',
  'PRIVATE_HEALTH_PASSPORT_INFO_UPDATE',
  'GENERIC',
] as const;

export type TaskType = typeof TASK_TYPES[number];

const ACTION_TYPES = [
  'INPUT',
  'TEXTAREA',
  'DATE_SELECTOR',
  'ADDRESS',
  'SUBMIT',
] as const;
export type TaskActionType = typeof ACTION_TYPES[number];

export const TASK_STATUSES = ['OPEN', 'COMPLETED', 'CANCELED'] as const;

const BikeFrameNumberDescriptionSchema = z.object({
  type: z.literal('BIKE_FRAME_NUMBER_UPDATE'),
  attributes: z.object({
    policyId: z.string().uuid(),
    brand: z.string(),
    model: z.string(),
    questionnaireId: z.string().uuid(),
  }),
});

const ClaimMissingInfoRedirectDescriptionSchema = z.object({
  type: z.literal('CLAIM_MISSING_INFO_REDIRECT'),
  attributes: z.object({
    claimId: z.string().uuid(),
    insuranceType: z.enum(insuranceTypes),
  }),
});

const LiabilityAddressUpdateDescriptionSchema = z.object({
  type: z.literal('LIABILITY_ADDRESS_UPDATE'),
  attributes: z.object({
    policyId: z.string().uuid(),
    questionnaireId: z.string().uuid(),
  }),
});

const PrivateHealthTaxIdUpdateDescriptionSchema = z.object({
  type: z.literal('PRIVATE_HEALTH_TAX_ID_UPDATE'),
  attributes: z.object({
    policyId: z.string().uuid(),
    questionnaireId: z.string().uuid(),
    fullName: z.string(),
  }),
});

const PrivateHealthRelocationDateUpdateDescriptionSchema = z.object({
  type: z.literal('PRIVATE_HEALTH_RELOCATION_DATE_UPDATE'),
  attributes: z.object({
    policyId: z.string().uuid(),
    questionnaireId: z.string().uuid(),
  }),
});

const PrivateHealthAddressUpdateDescriptionSchema = z.object({
  type: z.literal('PRIVATE_HEALTH_ADDRESS_UPDATE'),
  attributes: z.object({
    policyId: z.string().uuid(),
    questionnaireId: z.string().uuid(),
  }),
});

export const PrivateHealthPassportInfoUpdateDescriptionSchema = z.object({
  type: z.literal('PRIVATE_HEALTH_PASSPORT_INFO_UPDATE'),
  attributes: z.object({
    policyId: z.string().uuid(),
    questionnaireId: z.string().uuid(),
  }),
});

export const BikeReceiptsUpdateDescriptionSchema = z.object({
  type: z.literal('BIKE_RECEIPTS_UPDATE'),
  attributes: z.object({
    brand: z.string(),
    model: z.string(),
    policyId: z.string().uuid(),
    questionnaireId: z.string().uuid(),
  }),
});

export const GenericTaskMetadataSchema = z.object({
  title: z.string().nullish(),
  subtitle: z.string().nullish(),
  description: z.string().nullish(),
  additionalInfo: z.string().nullish(),
});

const GenericTaskDescriptionSchema = z.object({
  type: z.literal('GENERIC'),
  metadata: GenericTaskMetadataSchema,
  // TODO [Talo]: add validation for actions
  actions: z.any(),
  attributes: z
    .object({
      claimId: z.string().optional(),
      policyId: z.string().optional(),
    })
    .optional(),
});
/**
 * This union contains the attributes that are specific to each task.
 */
export const TaskDescriptionSchema = z.discriminatedUnion('type', [
  BikeFrameNumberDescriptionSchema,
  ClaimMissingInfoRedirectDescriptionSchema,
  LiabilityAddressUpdateDescriptionSchema,
  PrivateHealthRelocationDateUpdateDescriptionSchema,
  PrivateHealthAddressUpdateDescriptionSchema,
  PrivateHealthPassportInfoUpdateDescriptionSchema,
  BikeReceiptsUpdateDescriptionSchema,
  GenericTaskDescriptionSchema,
]);

export const BaseTaskSchema = z.object({
  id: z.string().uuid(),
  status: z.enum(TASK_STATUSES),
  description: TaskDescriptionSchema,
});

export const BikeFrameNumberUpdateTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: BikeFrameNumberDescriptionSchema,
  })
);

export const ClaimMissingInfoRedirectTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: ClaimMissingInfoRedirectDescriptionSchema,
  })
);

export const LiabilityAddressUpdateTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: LiabilityAddressUpdateDescriptionSchema,
  })
);

export const PrivateHealthTaxIdUpdateTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: PrivateHealthTaxIdUpdateDescriptionSchema,
  })
);

export const PrivateHealthRelocationDateUpdateTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: PrivateHealthRelocationDateUpdateDescriptionSchema,
  })
);

export const PrivateHealthAddressUpdateTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: PrivateHealthAddressUpdateDescriptionSchema,
  })
);

export const PrivateHealthPassportInfoUpdateTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: PrivateHealthPassportInfoUpdateDescriptionSchema,
  })
);

export const BikeDescriptionUpdateTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: BikeReceiptsUpdateDescriptionSchema,
  })
);

export const GenericTaskSchema = BaseTaskSchema.merge(
  z.object({
    description: GenericTaskDescriptionSchema,
  })
);

export type BikeFrameNumberUpdateTask = z.infer<
  typeof BikeFrameNumberUpdateTaskSchema
>;
export type ClaimMissingInfoRedirectTask = z.infer<
  typeof ClaimMissingInfoRedirectTaskSchema
>;
export type LiabilityAddressUpdateTask = z.infer<
  typeof LiabilityAddressUpdateTaskSchema
>;
export type PrivateHealthTaxIdUpdateTask = z.infer<
  typeof PrivateHealthTaxIdUpdateTaskSchema
>;
export type PrivateHealthRelocationDateUpdateTask = z.infer<
  typeof PrivateHealthRelocationDateUpdateTaskSchema
>;
export type PrivateHealthAddressUpdateTask = z.infer<
  typeof PrivateHealthAddressUpdateTaskSchema
>;

export type PrivateHealthPassportInfoUpdateTask = z.infer<
  typeof PrivateHealthPassportInfoUpdateTaskSchema
>;
export type BikeReceiptsUpdateTask = z.infer<
  typeof BikeDescriptionUpdateTaskSchema
>;
export type GenericTaskMetadata = z.infer<typeof GenericTaskMetadataSchema>;
export type GenericTask = z.infer<typeof GenericTaskSchema>;

export type Task =
  | BikeFrameNumberUpdateTask
  | BikeReceiptsUpdateTask
  | ClaimMissingInfoRedirectTask
  | LiabilityAddressUpdateTask
  | PrivateHealthTaxIdUpdateTask
  | PrivateHealthRelocationDateUpdateTask
  | PrivateHealthAddressUpdateTask
  | PrivateHealthPassportInfoUpdateTask
  | BikeReceiptsUpdateTask
  | GenericTask;

export const DateLikeSchema = z
  .union([z.date(), z.string(), z.number()])
  .pipe(z.coerce.date());

export const FileUploadSchema = z.object({
  fileName: z.string(),
  blobName: z.string(),
});

export const UploadTaskDataSchema = z.object({
  files: z.array(FileUploadSchema).default([]),
});

export type FileUploadType = z.infer<typeof FileUploadSchema>;

export type UploadTaskDataType = z.infer<typeof UploadTaskDataSchema>;

export const UploadReceiptsTaskDataSchema = z.object({
  uploadIds: z.array(z.string()),
});

export type UploadReceiptsTaskDataType = z.infer<
  typeof UploadReceiptsTaskDataSchema
>;

export const PassportInfoSchema = z.object({
  passportNumber: z.string().optional(),
  passportExpiry: DateLikeSchema.optional(),
});

export const PayloadSchema = z.union([
  // System-defined tasks
  z.object({ address: ZAddressSchema }),
  z.object({ frameNumber: z.string().optional() }),
  UploadReceiptsTaskDataSchema,
  z.object({ germanTaxId: z.string().length(11).optional() }),
  z.object({ relocationDate: DateLikeSchema.optional() }),
  PassportInfoSchema,
  // Generic tasks
  // Handle inputs with unknown object shapes
  z.record(z.string(), z.unknown()),
  // Handle generic file upload tasks
  UploadTaskDataSchema,
]);

export const UpdateTaskSchema = z.object({
  taskId: z.string().uuid(),
  payload: PayloadSchema,
});

export type UpdateTask = z.infer<typeof UpdateTaskSchema>;
export type PassportInfoSchemaType = z.infer<typeof PassportInfoSchema>;

export type UpdateTaskDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  RequestAction
>;
