import { NavButton } from 'components/NavButton';
import type {
  LiabilityClaims,
  Questionnaire as LiabilityQuestionnaire,
} from 'features/liabilityClaims/models';
import Questionnaire from 'features/questionnaireFramework';
import { isMobileApp } from 'shared/util/isMobileApp';

interface ViewProps {
  questionnaire: LiabilityQuestionnaire;
  questionnaireAnswers: Partial<LiabilityClaims>;
  handleAnswerQuestion: (answer: Partial<LiabilityClaims>) => void;
}

const LiabilityClaimsView = ({
  questionnaireAnswers,
  handleAnswerQuestion,
  questionnaire,
}: ViewProps) => {
  return (
    <>
      {isMobileApp && <NavButton title="Previous Question" />}
      <Questionnaire
        featureName="Liability Claims"
        questionnaire={questionnaire}
        questionnaireAnswers={questionnaireAnswers}
        handleAnswerQuestion={handleAnswerQuestion}
      />
    </>
  );
};

export default LiabilityClaimsView;
