import { Region } from '@getpopsure/public-models';
import { UserWithBrokerMandate } from 'models/user';
import { AppState } from 'reducers';
import { getMyPolicies } from 'selectors/policies/getMyPolicies';

export const getNeedToSignBrokerMandate = (state: AppState): boolean => {
  const user = getAccountInfo(state);
  const policies = getMyPolicies(state);

  if (!user) {
    return false;
  }

  if (!policies) {
    return false;
  }

  return policies.length > 0 && user.signedBrokerMandate === false;
};

export const getEmail = (state: AppState): string | undefined => {
  return state.user.accountInfo?.email;
};

export const getDateOfBirth = (state: AppState): string | undefined => {
  return state.user.accountInfo?.dateOfBirth;
};

export const getAccountInfo = (
  state: AppState
): UserWithBrokerMandate | undefined => {
  return state.user.accountInfo;
};

export const getDataRemovalRequests = (
  state: AppState
): string[] | undefined => {
  return state.user.accountInfo?.dataRemovalRequests;
};

export const getUserId = (state: AppState): string | undefined => {
  return state.user.accountInfo?.id;
};

export const getUserRegion = (state: AppState): Region | undefined => {
  return state.user.accountInfo?.region;
};

export const isDelinquentUser = (state: AppState): boolean | undefined => {
  return state.user.accountInfo?.isDelinquent;
};
