import { BottomOrRegularModal, Input } from '@popsure/dirty-swan';
import { createPopup } from '@typeform/embed';
import { fetchAllClaims } from 'actions/claims';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ExpatCancellationReasonsId } from 'models/expat';
import { InsuranceTypes } from 'models/insurances/types';
import { Fragment, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { getClaims } from 'selectors/claims';
import { getAccountInfo } from 'selectors/user';
import { TFunction, Trans, useSafeTranslation } from 'shared/i18n';

import { ViewProps } from '../../../cancelModal/types';
import styles from '../style.module.scss';
import { isInRightOfWithdrawalPeriod } from '../utils';

const getExpatCancellationReasons = (
  t: TFunction,
  showRightOfWithdrawlReason?: boolean
): {
  id: ExpatCancellationReasonsId;
  title: string;
}[] => [
  ...(showRightOfWithdrawlReason
    ? [
        {
          id: 'RIGHT_OF_WITHDRAWAL' as ExpatCancellationReasonsId,
          title: t(
            'myPolicies.cancelModal.expatHealth.reason.rightOfWithdrawal.title',
            'I changed my mind and need a full refund'
          ),
        },
      ]
    : []),
  {
    id: 'FOUND_A_JOB',
    title: t(
      'myPolicies.cancelModal.expatHealth.reason.foundAJob.title',
      'I found a job'
    ),
  },
  {
    id: 'FOUND_ANOTHER_INSURANCE',
    title: t(
      'myPolicies.cancelModal.expatHealth.reason.foundAnotherInsurance.title',
      'I got another health insurance'
    ),
  },
  {
    id: 'LEFT_GERMANY',
    title: t(
      'myPolicies.cancelModal.expatHealth.reason.leftGermany.title',
      'I will not live in Germany'
    ),
  },
  {
    id: 'ADJUST_POLICY',
    title: t(
      'myPolicies.cancelModal.expatHealth.reason.adjustPolicy.title',
      'I need to adjust my policy'
    ),
  },
];

export const canCancel = (
  insuranceType: InsuranceTypes,
  cancelationReason?: ExpatCancellationReasonsId,
  newInsuranceProvider?: string
): boolean => {
  if (insuranceType !== 'INCOMING') {
    return true;
  }

  if (cancelationReason === 'FOUND_ANOTHER_INSURANCE') {
    return (
      newInsuranceProvider !== undefined && newInsuranceProvider.length > 0
    );
  }

  return cancelationReason !== undefined;
};

const ClaimsFetcher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllClaims());
  }, [dispatch]);

  return null;
};

export const CancelExpatHealthModal = ({
  type,
  activeUntil,
  onCloseModal,
  onCancelPolicy,
  isCancelling,
  cancellingError,
  isOpen,
  id: policyId,
  createdAt,
}: ViewProps) => {
  const { t } = useSafeTranslation();
  const claims = useSelector(getClaims) ?? null;
  const accountInfo = useSelector(getAccountInfo);
  const email = accountInfo?.email || '';

  const [customCancellationReason, setCustomCancellationReason] = useState('');
  const [selectedIncomingCancellationReason, setIncomingCancellationReason] =
    useState<ExpatCancellationReasonsId | undefined>();
  const [newInsuranceProvider, setNewInsuranceProvider] = useState<
    string | undefined
  >();

  const valid = canCancel(
    type,
    selectedIncomingCancellationReason,
    newInsuranceProvider
  );

  let additionalInfo: string | undefined;

  switch (selectedIncomingCancellationReason) {
    case 'FOUND_ANOTHER_INSURANCE':
      additionalInfo = newInsuranceProvider;
      break;
    case 'ADJUST_POLICY':
      additionalInfo = customCancellationReason;
      break;
  }

  const handleCancelPolicy = () => {
    const successMessage =
      selectedIncomingCancellationReason === 'ADJUST_POLICY'
        ? t(
            'myPolicies.cancelModal.expatHealth.adjustPolicy.successMessage.',
            'Your policy was successfully sent'
          )
        : t(
            'myPolicies.cancelModal.expatHealth.reason.successMessage',
            'Your policy was successfully cancelled'
          );
    onCancelPolicy(
      selectedIncomingCancellationReason,
      additionalInfo,
      successMessage
    );
  };

  const policyHasClaims = claims?.some(
    (claim) => claim.userPolicyId === policyId
  );
  const nowDate = Date.now();

  const showRightOfWithdrawlReason =
    isInRightOfWithdrawalPeriod({
      nowDate,
      startDateOfRoWPeriod: createdAt,
    }) && !policyHasClaims;

  return (
    <BottomOrRegularModal
      title={t('myPolicies.cancelModal.expatHealth.title', 'Cancel policy')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      {isOpen && <ClaimsFetcher />}
      <div
        className={`mt24 ${styles.container}`}
        data-testid="cancel-modal-expat"
      >
        <>
          <form
            className="mt16"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {getExpatCancellationReasons(t, showRightOfWithdrawlReason).map(
              ({ id, title: reasonTitle }) => (
                <Fragment key={id}>
                  <label className="p-p mt8 d-block" htmlFor={id}>
                    <input
                      id={id}
                      value={id}
                      type="radio"
                      name="cancellationReason"
                      onChange={() => {
                        setIncomingCancellationReason(id);
                      }}
                      checked={id === selectedIncomingCancellationReason}
                    />
                    &nbsp;{reasonTitle}
                  </label>
                  {id === 'RIGHT_OF_WITHDRAWAL' && (
                    <AnimateHeight
                      duration={300}
                      height={
                        selectedIncomingCancellationReason ===
                        'RIGHT_OF_WITHDRAWAL'
                          ? 'auto'
                          : 0
                      }
                    >
                      <div
                        className={`p-notice--warning mt8 ${styles['new-job-info-container']}`}
                      >
                        <p className="p-p mb16">
                          {t(
                            'myPolicies.cancelModal.expatHealth.reason.rightOfWithdrawal.description.1',
                            'Any coverage you had so far will be canceled and you will get a full refund. This means you will not be covered for any period previously included in this coverage.'
                          )}
                        </p>
                        <p className="p-p mb16">
                          {t(
                            'myPolicies.cancelModal.expatHealth.reason.rightOfWithdrawal.description.2',
                            'If you are already in Germany, remember that it is legally required to have continuous health insurance. To ensure uninterrupted coverage, please choose another cancellation option. This will make sure you’re covered from the start date of the policy until the end of your billing period.'
                          )}
                        </p>
                      </div>
                    </AnimateHeight>
                  )}
                  {id === 'FOUND_A_JOB' && (
                    <AnimateHeight
                      duration={300}
                      height={
                        selectedIncomingCancellationReason === 'FOUND_A_JOB'
                          ? 'auto'
                          : 0
                      }
                    >
                      <div
                        className={`p-notice--info mt8 ${styles['new-job-info-container']}`}
                      >
                        <p className="p-p mb16">
                          {t(
                            'myPolicies.cancelModal.expatHealth.reason.foundAJob.description',
                            'Congrats on your new job! Perhaps you liked working with Feather, and because in Germany you need continuous health insurance coverage, we can help you upgrade to a full public or private health plan.'
                          )}
                        </p>
                        <p className="p-p">
                          <button
                            className={`p-a ${styles['sign-up-public-button']}`}
                            onClick={() =>
                              createPopup('PrhiQctf', {
                                hidden: { email },
                              }).open()
                            }
                            type="button"
                          >
                            {t(
                              'myPolicies.cancelModal.expatHealth.reason.foundAJob.upgradeButton.caption',
                              'Upgrade to public or private'
                            )}
                          </button>
                        </p>
                      </div>
                    </AnimateHeight>
                  )}
                  {id === 'FOUND_ANOTHER_INSURANCE' && (
                    <AnimateHeight
                      duration={300}
                      height={
                        selectedIncomingCancellationReason ===
                        'FOUND_ANOTHER_INSURANCE'
                          ? 'auto'
                          : 0
                      }
                    >
                      <Input
                        className="mt8 wmx5"
                        placeholder={t(
                          'myPolicies.cancelModal.expatHealth.reason.foundAnotherInsurance.input.placeholder',
                          'Insurance provider'
                        )}
                        value={newInsuranceProvider ?? ''}
                        onChange={(e) => {
                          setNewInsuranceProvider(e.target.value);
                        }}
                      />
                    </AnimateHeight>
                  )}
                  {id === 'ADJUST_POLICY' && (
                    <AnimateHeight
                      duration={300}
                      height={
                        selectedIncomingCancellationReason === 'ADJUST_POLICY'
                          ? 'auto'
                          : 0
                      }
                    >
                      <Input
                        className="mt8 wmx5"
                        placeholder={t(
                          'myPolicies.cancelModal.expatHealth.reason.adjustPolicy.input.placeholder',
                          'Please describe the changes'
                        )}
                        value={customCancellationReason ?? ''}
                        onChange={(e) => {
                          setCustomCancellationReason(e.target.value);
                        }}
                      />
                      <div className="p-notice--info mt16 p-p">
                        {t(
                          'myPolicies.cancelModal.expatHealth.reason.adjustPolicy.info',
                          ' We can help you make changes to your policy. Once the request is sent, our team will reach out to you with the next steps.'
                        )}
                      </div>
                    </AnimateHeight>
                  )}
                </Fragment>
              )
            )}
          </form>
        </>
        {activeUntil &&
          selectedIncomingCancellationReason !== 'ADJUST_POLICY' &&
          selectedIncomingCancellationReason !== 'RIGHT_OF_WITHDRAWAL' && (
            <Trans i18nKey="myPolicies.cancelModal.expatHealth.activeUntil.description">
              <p className="p-notice--warning mt24 p-p">
                The cancellation will be effective at the end of your next
                billing period on{' '}
                <b className="fw-bold">
                  {{ endDate: dayjs(activeUntil).format('DD MMM YYYY') }}.
                </b>
              </p>
            </Trans>
          )}
        <div className={`d-flex f-wrap mt24 ${styles['button-container']}`}>
          <button
            className={classNames(`p-btn--primary wmn3`, {
              'p-btn--loading': isCancelling,
              invalid: !valid,
            })}
            disabled={
              !valid ||
              isCancelling ||
              (selectedIncomingCancellationReason === 'ADJUST_POLICY' &&
                !customCancellationReason)
            }
            onClick={handleCancelPolicy}
            type="button"
          >
            {selectedIncomingCancellationReason === 'ADJUST_POLICY'
              ? t(
                  'myPolicies.cancelModal.expatHealth.adjustPolicyButton.caption',
                  'Send request'
                )
              : t(
                  'myPolicies.cancelModal.expatHealth.confirmButton.caption',
                  'Cancel policy'
                )}
          </button>
          <button
            className={`p-btn--secondary wmn2 ${styles['secondary-button']}`}
            onClick={() => onCloseModal()}
            type="button"
          >
            {t(
              'myPolicies.cancelModal.expatHealth.closeButton.caption',
              'Close'
            )}
          </button>
        </div>
        {cancellingError && (
          <p className="p-notice--danger p-p mt24">{cancellingError}</p>
        )}
      </div>
    </BottomOrRegularModal>
  );
};
