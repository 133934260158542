import { z } from 'zod';

export type LegalSubmitClaims = {
  description: string;
  phoneConsultation: boolean;
  phoneNumber: string;
  review: boolean;
  processing: boolean;
  submitted: boolean;
};

export const ZSubmitClaimInfo = z.object({
  description: z.string(),
  phoneConsultation: z.boolean(),
  phoneNumber: z.string(),
});

const claimType = ['BOOK_A_CONSULTATION', 'LEGAL_CLAIM'] as const;
export type LegalClaimType = typeof claimType[number];
export type LegalClaimsAndConsultations = {
  claimType: LegalClaimType;
};
