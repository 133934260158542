import {
  getRoutes,
  PartialQuestionnaire,
  Question,
  Questionnaire,
  Rule,
  ScreenProps,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  DownloadIcon,
  FileIcon,
  Input,
  PlusIcon,
  TrashIcon,
} from '@popsure/dirty-swan';
import routes from 'constants/routes';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import {
  SignupQuestionnaire,
  SignupSpecificComponents,
} from '../SignupQuestionnaire';
import { exportData, omit, readSession, writeSession } from './functions';
import { getFieldForType } from './getFieldForType';
import { getFormForType } from './getFormForType';
import { getSettingsForQuestionType } from './getSettingsForQuestionType';
import {
  ADD_NEW_QUESTION,
  BuilderQuestionnaire,
  ExtendedQuestionType,
  MODE,
} from './models';
import { Rules } from './rules';
import styles from './style.module.scss';

export const components: ExtendedQuestionType[] = [
  'ADDRESS',
  'BOOLEAN',
  'CHECKBOX',
  'COUNTRY_MULTI',
  'COUNTRY_SINGLE',
  'CURRENCY',
  'DATE',
  'INPUT',
  'NAME',
  'NUMBER',
  'RADIO',
  'TEXT',
  'DROPDOWN_SINGLE',
  'NUMERIC_CONVERSION',
  'INTRO',
  'AUTOSUGGEST',
  'BLOCKER',
  'REDIRECT',
  'QUOTEPAGE',
  'REVIEW',
];

export const Builder = () => {
  const savedQuestionnaire = readSession();
  const [questions, setQuestions] = useState<
    PartialQuestionnaire<Record<string, unknown>, unknown, unknown>
  >(() => {
    return savedQuestionnaire
      ? (savedQuestionnaire as BuilderQuestionnaire).components
      : [
          {
            id: 'intro',
            required: true,
            type: 'INTRO',
            props: {
              title: '',
              subTitle: '',
              ...getSettingsForQuestionType('INTRO'),
            },
            screen: {
              question: '',
            },
            groupId: 'example',
          },
        ];
  });

  const [mode, setMode] = useState<MODE>('BUILD');

  const [activeQuestionId, setActiveQuestionId] = useState<string | null>();

  const [questionnaireAnswers, setQuestionnaireAnswers] = useState({});
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  const [rules, setRules] = useState<Rule<Record<string, unknown>>[]>(() => {
    return savedQuestionnaire
      ? (savedQuestionnaire as BuilderQuestionnaire).rules
      : [];
  });

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, routes.policies.builder.path),
    rules,
  };

  useEffect(() => {
    writeSession({
      components: questions,
      routes: getRoutes(questions, routes.policies.builder.path),
      rules,
    });
  }, [questions, rules]);

  const onUpdateProps = (key: string, value: unknown) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question.id === activeQuestionId) {
          return {
            ...question,
            props: {
              ...question.props,
              [key]: value,
            },
          } as Question<Record<string, unknown>>;
        }
        return question;
      })
    );
  };

  const onUpdateQuestion = (
    updatedQuestion: Question<Record<string, unknown>>
  ) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === activeQuestionId) {
          return updatedQuestion;
        }
        return question;
      });
    });
  };

  const onUpdateQuestionId = (id: string) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === activeQuestionId) {
          return {
            ...question,
            id,
          };
        }
        return question;
      });
    });
    setActiveQuestionId(id);
  };

  const onUpdateSettings = (key: string, value: unknown) => {
    setQuestions((preQuestions) => {
      return preQuestions.map((question) => {
        if (question.id === activeQuestionId) {
          return {
            ...question,
            screen: {
              ...question.screen,
              [key]: value,
            },
          };
        }
        return question;
      });
    });
  };

  const addQuestion = () => {
    if (activeQuestionId === ADD_NEW_QUESTION) {
      return;
    }
    setQuestions((prevQuestions) => {
      return [
        ...prevQuestions,
        {
          id: ADD_NEW_QUESTION,
          required: true,
          type: 'INTRO',
          props: {
            title: '',
            subTitle: '',
            ...getSettingsForQuestionType('INTRO'),
          },
          screen: {
            question: '',
          },
          groupId: 'example',
        },
      ];
    });

    setActiveQuestionId(ADD_NEW_QUESTION);
  };

  const removeQuestion = (questionId: string) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((question) => question.id !== questionId)
    );
    if (activeQuestionId === questionId) {
      setActiveQuestionId(null);
    }
  };

  const onAnswer = (
    questionId: string,
    answer: unknown,
    additionalData: Partial<Record<string, unknown>> = {}
  ) => {
    if (mode === 'BUILD') {
      return;
    }

    setQuestionnaireAnswers((answers) => {
      if (questionId === 'quotepage') {
        return {
          ...answers,
          [questionId]: {
            ...(answer as object),
            addons: additionalData.addons,
          },
        };
      }
      const keysToRemove = stateManagerHelper(
        {},
        questionnaire.components,
        questionnaireAnswers,
        questionnaire.rules
      ).getAnswersToRemove(questionId, answer);

      // remove any outdated answers due to an answer change
      return {
        ...omit(answers, keysToRemove),
        ...additionalData,
        [questionId]: answer,
      };
    });
  };

  const importData = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    if (input && input.files && input.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsText(input.files[0], 'UTF-8');
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === 'string') {
          const data = JSON.parse(e.target.result);
          setQuestions(data.components);
          setRules(data.rules);
        }
      };
    }
  }, []);

  const moveQuestion = (from: number, to: number) => {
    if (to === -1 || to >= questions.length) {
      return;
    }
    const value = questions[from];
    const removed = [...questions.slice(0, from), ...questions.slice(from + 1)];
    const updatedQuestions = [
      ...removed.slice(0, to),
      value,
      ...removed.slice(to),
    ];
    setQuestions(updatedQuestions);
  };

  if (mode === 'PREVIEW') {
    return (
      <Switch>
        <Route path={routes.policies.builder.preview.path}>
          <div className="d-flex fd-column p16">
            <div className="d-flex fd-row ai-center jc-center mb32">
              <Button
                className="p-btn--secondary ws2"
                type="button"
                onClick={() => {
                  setMode('BUILD');
                  history.push(routes.policies.builder.path);
                }}
              >
                Exit
              </Button>
            </div>
            <SignupQuestionnaire
              questionnaireAnswers={questionnaireAnswers}
              questionnaire={questionnaire}
              onAnswer={onAnswer}
              configuration={{
                continueButtonText: 'Continue',
                components: {},
              }}
              featureName="Builder"
              basePath={routes.policies.builder.path}
              questionId={activeQuestionId ?? 'intro'}
            />
          </div>
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={routes.policies.builder.path}>
        <div className="d-flex fd-column p16">
          <div className="d-flex fd-column">
            <div className="d-flex fd-row ai-center jc-center mb32">
              <Button
                className="ws2"
                type="button"
                onClick={() => setMode('PREVIEW')}
              >
                Preview
              </Button>
            </div>
            {questions.map((question, idx) => {
              return (
                <QuestionComponent
                  key={question.id}
                  question={question}
                  isActive={question.id === activeQuestionId}
                  setActiveQuestionId={setActiveQuestionId}
                  removeQuestion={removeQuestion}
                  onUpdateProps={onUpdateProps}
                  onUpdateSettings={onUpdateSettings}
                  onUpdateQuestionId={onUpdateQuestionId}
                  onUpdateQuestion={onUpdateQuestion}
                  questionnaire={questionnaire}
                  onMoveUp={
                    idx > 0
                      ? () => {
                          moveQuestion(idx, idx - 1);
                        }
                      : undefined
                  }
                  onMoveDown={
                    idx < questions.length - 1
                      ? () => {
                          moveQuestion(idx, idx + 1);
                        }
                      : undefined
                  }
                />
              );
            })}
            <div className="d-flex w100 jc-center ai-center">
              {questions.find(
                (question) => question.id === ADD_NEW_QUESTION
              ) === undefined && (
                <Button
                  type="button"
                  onClick={addQuestion}
                  className="mt8 ws4 p-btn--secondary"
                >
                  <PlusIcon /> Add Question
                </Button>
              )}
            </div>

            <Rules rules={rules} questions={questions} updateRules={setRules} />
            <div className="d-flex ai-center jc-center mt32">
              <Button
                type="button"
                className="p-btn--secondary"
                leftIcon={<DownloadIcon />}
                onClick={exportData}
              >
                Download
              </Button>
              <input
                style={{
                  visibility: 'hidden',
                  width: '0',
                }}
                ref={uploadRef}
                type="file"
                onChange={importData}
              />
              <Button
                type="button"
                className="p-btn--secondary"
                leftIcon={<FileIcon />}
                onClick={() => {
                  uploadRef.current?.click();
                }}
              >
                Import
              </Button>
            </div>
          </div>
        </div>
      </Route>
    </Switch>
  );
};

type QuestionComponentProps = {
  question: Question<Record<string, unknown>>;
  isActive: boolean;
  setActiveQuestionId: (questionId: string | null) => void;
  removeQuestion: (questionId: string) => void;
  onUpdateProps: (key: string, value: unknown) => void;
  onUpdateSettings: (key: string, value: unknown) => void;
  onUpdateQuestionId: (questionId: string) => void;
  onUpdateQuestion: (
    updatedQuestion: Question<Record<string, unknown>>
  ) => void;
  questionnaire: BuilderQuestionnaire;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
};

const QuestionComponent = ({
  question,
  isActive,
  setActiveQuestionId,
  removeQuestion,
  onUpdateProps,
  onUpdateSettings,
  onUpdateQuestion,
  onUpdateQuestionId,
  questionnaire,
  onMoveUp,
  onMoveDown,
}: QuestionComponentProps) => {
  return (
    <div className="mt8" key={question.id}>
      <div className="d-flex fd-row ai-center jc-between">
        <div className="d-flex fd-row ws1">
          <div className="ws1">
            {onMoveUp && (
              <button type="button" onClick={onMoveUp} className="c-pointer">
                <ArrowUpIcon />
              </button>
            )}
          </div>
          <div className="ws1">
            {onMoveDown && (
              <button type="button" onClick={onMoveDown} className="c-pointer">
                <ArrowDownIcon />
              </button>
            )}
          </div>
        </div>

        <button
          type="button"
          className="ai-start ta-start fw-bold c-pointer tc-grey-700 w90 bg-transparent"
          onClick={() => {
            if (isActive) {
              setActiveQuestionId(null);
            } else {
              setActiveQuestionId(question.id);
            }
          }}
        >
          {question.id}
        </button>
        <div className="ws1">
          <button
            type="button"
            className="c-pointer tc-grey-500 bg-transparent"
            onClick={() => removeQuestion(question.id)}
          >
            <TrashIcon />
          </button>
        </div>
      </div>
      <hr />
      <div className="bg-grey-200 mt32 mb32" />

      {isActive && (
        <>
          <Questionnaire
            questionnaireAnswers={{}}
            questionnaire={questionnaire}
            onAnswer={() => {}}
            configuration={{
              continueButtonText: 'Continue',
              components: SignupSpecificComponents,
            }}
            featureName="Builder"
            questionId={question.id}
          />
          <div>
            <select
              onChange={(e) => {
                const type = e.target.value as ExtendedQuestionType;
                onUpdateQuestion({
                  ...question,
                  props: getSettingsForQuestionType(
                    e.target.value as ExtendedQuestionType
                  ),
                  type,
                } as Question<Record<string, unknown>>);
              }}
              value={question.type}
            >
              {components.map((component) => {
                return (
                  <option key={component} value={component}>
                    {component}
                  </option>
                );
              })}
            </select>
          </div>
          <Properties
            question={question}
            setQuestionId={onUpdateQuestionId}
            setProps={(name, value) => onUpdateProps(name, value)}
            setSettings={(name, value) => onUpdateSettings(name, value)}
            setRequired={(required) => {
              onUpdateQuestion({
                ...question,
                required,
              });
            }}
          />
          <div className="bg-grey-300 my32 p16">
            <pre>{JSON.stringify(question, null, 4)}</pre>
          </div>
        </>
      )}
    </div>
  );
};

const entryClass = 'd-flex fd-row my8 gap16';
const labelClass = 'p-p tc-grey-600 wmn1';
const titleClass = 'p-h3 mt24 mb8 mb8';
const inputClass = 'ws8';

type PropertiesProps = {
  question: Question<Record<string, unknown>>;
  setQuestionId: (id: string) => void;
  setProps: (name: string, value: unknown) => void;
  setSettings: (name: keyof ScreenProps, value: unknown) => void;
  setRequired: (required: boolean) => void;
};

const Properties = ({
  question,
  setProps,
  setQuestionId,
  setSettings,
  setRequired,
}: PropertiesProps) => {
  const { id, props, screen: settings = {} } = question;

  const FormProps = getFormForType(question.type);
  return (
    <div className="my16">
      <hr />
      <div className="p-container mt16">
        <div key={id} className={`${entryClass} ${styles.entry}`}>
          <label
            className={`${labelClass} ${styles.label}`}
            htmlFor="questionId"
          >
            Question Id
          </label>
          <Input
            type="text"
            defaultValue={id}
            onBlur={(e) =>
              setQuestionId(
                e.target.value.toLowerCase().replace(/ ([a-z])/g, (g) => {
                  return g.toLowerCase()[1].toUpperCase();
                })
              )
            }
            className={`${inputClass} ${styles.input}`}
            id="questionId"
          />
        </div>
        <div className={`${entryClass} ${styles.entry}`}>
          <input
            id="required"
            className="p-checkbox"
            type="checkbox"
            checked={question.required}
            onChange={(e) => setRequired(e.target.checked)}
          />
          <label htmlFor="required" className={`${labelClass}`}>
            Required
          </label>
        </div>
        <h3 className={titleClass}>Inputs</h3>
        {Object.entries(FormProps).map(([name, field]) => {
          const Component = getFieldForType(field);

          return (
            <div key={name} className={`${entryClass} ${styles.entry}`}>
              <label
                htmlFor={name}
                className={`${labelClass} ${styles.label} ${styles.mainLabel}`}
              >
                {name}
              </label>
              {Component && (
                <Component
                  name={name}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={props[name] ?? ''}
                  onChange={(value) => setProps(name, value)}
                />
              )}
            </div>
          );
        })}
        <h3 className={titleClass}>Screen settings</h3>
        <div className="d-flex fd-column">
          <div className={`${entryClass} ${styles.entry}`}>
            <label
              className={`${labelClass} ${styles.label}`}
              htmlFor="screenQuestion"
            >
              Question
            </label>
            <Input
              type="text"
              defaultValue={settings.question ?? ''}
              onBlur={(e) => setSettings('question', e.target.value)}
              className={`${inputClass} ${styles.input}`}
              id="screenQuestion"
            />
          </div>
          <div className={`${entryClass} ${styles.entry}`}>
            <label
              className={`${labelClass} ${styles.label}`}
              htmlFor="screenDescription"
            >
              Description
            </label>
            <Input
              type="text"
              defaultValue={settings.description ?? ''}
              onBlur={(e) => setSettings('description', e.target.value)}
              className={`${inputClass} ${styles.input}`}
              id="screenDescription"
            />
          </div>
          <div className={`${entryClass} ${styles.entry}`}>
            <label
              className={`${labelClass} ${styles.label}`}
              htmlFor="screenLayout"
            >
              Layout
            </label>
            <select
              onChange={(e) => {
                setSettings('layout', e.target.value as ExtendedQuestionType);
              }}
              value={settings.layout ?? 'Centered'}
              id="screenLayout"
            >
              <option key="centered" value="Centered">
                Centered
              </option>
              <option key="standalone" value="Standalone">
                Standalone
              </option>
            </select>
          </div>
          <div className={`${entryClass} ${styles.entry}`}>
            <label
              className={`${labelClass} ${styles.label}`}
              htmlFor="buttonText"
            >
              Button text
            </label>
            <Input
              type="text"
              defaultValue={settings.description ?? 'Continue'}
              onBlur={(e) =>
                setSettings('buttonText', e.target.value ?? 'Continue')
              }
              className={`${inputClass} ${styles.input}`}
              id="buttonText"
            />
          </div>
          <div>
            <h4 className="p-h4 mt16 mb8">Additional Information</h4>
            <div className="d-flex fd-column">
              <div className={`${entryClass} ${styles.entry}`}>
                <label
                  className={`${labelClass} ${styles.label}`}
                  htmlFor="screenInfoTitle"
                >
                  Title
                </label>
                <Input
                  type="text"
                  defaultValue={settings.additionalInfo?.title ?? ''}
                  onBlur={(e) =>
                    setSettings('additionalInfo', {
                      ...(settings.additionalInfo ?? {}),
                      title: e.target.value,
                    })
                  }
                  className={`${inputClass} ${styles.input}`}
                  id="screenInfoTitle"
                />
              </div>
              <div className={`${entryClass} ${styles.entry}`}>
                <label
                  className={`${labelClass} ${styles.label}`}
                  htmlFor="screenInfoDescription"
                >
                  Description
                </label>
                <textarea
                  defaultValue={settings.additionalInfo?.description ?? ''}
                  onBlur={(e) =>
                    setSettings('additionalInfo', {
                      ...(settings.additionalInfo ?? {}),
                      description: e.target.value,
                    })
                  }
                  rows={8}
                  className={`ws8 p-input ${styles.textArea}`}
                  id="screenInfoDescription"
                />
              </div>
              <div className={`${entryClass} ${styles.entry}`}>
                <label
                  className={`${labelClass} ${styles.label}`}
                  htmlFor="screenMoreInfo"
                >
                  More Info Link
                </label>
                <Input
                  type="text"
                  defaultValue={settings.additionalInfo?.moreInfoLink ?? ''}
                  onBlur={(e) =>
                    setSettings('additionalInfo', {
                      ...(settings.additionalInfo ?? {}),
                      moreInfoLink: e.target.value,
                    })
                  }
                  className={`${inputClass} ${styles.input}`}
                  data-cy="address-input-line2"
                  id="screenMoreInfo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Builder;
