import { Bike as Questionnaire } from 'models/genericQuestionnaire';
import { Name } from 'models/user';

export type QuestionId = keyof Questionnaire;

export type Plan = 'BASIC' | 'COMFORT';
export type Type = 'BIKE' | 'E_BIKE';
export type NotifyType = 'VINTAGE' | 'CARBON';
export type Provider = 'BERLIN_DIREKT' | 'BARMENIA';

export const mapBikeType: { [K in Type]: string } = {
  BIKE: 'Bicycle',
  E_BIKE: 'E-bike',
};

export type QuestionnaireType =
  | 'BIKE_PRE_QUOTE'
  | 'BIKE_POST_QUOTE'
  | 'BIKE_SCREENED_OUT';

export interface EmailNotificationQuestionnaire {
  email: string;
  notificationType: NotifyType;
}

export interface PreQuoteQuestionnaire {
  bikeInfo: {
    livingInGermany: boolean;
    purchaseDate: string;
    carbonFrame: boolean;
    purchasePrice: number;
    type: Type;
  };
}

export interface PostQuoteQuestionnaire {
  personalInfo: {
    email: string;
    name: Name;
    dateOfBirth: string;
  };
  bikeInfo: {
    purchaseDate: string;
    carbonFrame: boolean;
    purchasePrice: number;
    type: Type;
    brand: string;
    model: string;
    frameNumber?: string;
  };
}

export interface Quote {
  id: string;
  planId: Plan;
  price: number;
  providerId: string;
}

export interface Quotes {
  questionnaireId: string;
  options: Quote[];
  selectedQuote?: Quote;
}

export interface CardInfo {
  title: string;
  description: string | React.ReactNode;
  icon: { src: string; alt: string };
}

export interface BikeDetails {
  brand: string;
  model: string;
}

export interface QuestionnaireLabels {
  labels: {
    title: {
      personalInfo: {
        [K in keyof Partial<Questionnaire & 'brand' & 'model'>]: string;
      };
      bikeInfo: {
        [K in keyof Partial<Questionnaire & 'brand' & 'model'>]: string;
      };
    };
    answers: {
      bikeInfo: {
        type: {
          [K in Type]: string;
        };
      };
    };
  };
  questionOrder: {
    personalInfo: Array<QuestionId | 'brand' | 'model'>;
    bikeInfo: Array<QuestionId | 'brand' | 'model'>;
  };
}

export interface PolicyDetails {
  bikePurchaseDate: string;
  brand: string;
  model: string;
  frameNumber: string;
}
