import Markdown from 'components/markdown';

import styles from './IconCard.module.scss';

export const IconCard = ({
  className = '',
  title,
  children,
  iconSrc,
  iconAlt,
  iconWidth,
  paragraphClassName = '',
}: {
  className?: string;
  title: string;
  children: string;
  iconSrc: string;
  iconAlt: string;
  iconWidth?: number;
  paragraphClassName?: string;
}) => (
  <div className={className}>
    <div className="d-flex ai-start c-gap16">
      <span>
        <img src={iconSrc} alt={iconAlt} width={iconWidth} />
      </span>
      <div>
        <h4 className={`p-h4 ${styles.title}`}>{title}</h4>
        <Markdown
          paragraphClassName={`p-p tc-grey-600 ${paragraphClassName}`}
          openLinksInNewTab
        >
          {children}
        </Markdown>
      </div>
    </div>
  </div>
);
