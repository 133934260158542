import { useTranslation } from '@getpopsure/i18n-react';
import { ComparisonTable as Table } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { isMobileApp } from 'shared/util/isMobileApp';

import { LegalQuotePlan, Modal, TableData } from '../models';
import { TableModal } from '../TableModal/TableModal';
import styles from './style.module.scss';
import { getTableData, getTableHeaders } from './tableData/legal';

export const ComparisonTable = ({
  showPrice,
  planMapping,
}: {
  showPrice: boolean;
  planMapping: LegalQuotePlan;
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<null | Modal>();

  const handleOpenModal = useCallback((newModal: Modal) => {
    setModal(newModal);
  }, []);

  const tableData: Array<TableData> = getTableData(t);

  const tableHeaders = getTableHeaders(
    t,
    handleOpenModal,
    showPrice,
    planMapping
  );

  return (
    <>
      <header
        className={classNames('fd-column ai-center pb24', styles.tableHeader)}
      >
        <h2 className="p-h2 p--serif">
          {t('legal.qnr.quote.coverageTable.title', 'Coverage overview')}
        </h2>
        <div className={classNames('ws2', styles.headerUnderline)} />
      </header>
      <Table
        headers={tableHeaders}
        data={tableData}
        collapsibleSections
        growContent
        firstColumnWidth={288}
        stickyHeaderTopOffset={isMobileApp ? 88 : 0}
      />
      {modal &&
        createPortal(
          <TableModal modal={modal} setModal={setModal} />,
          document.body
        )}
    </>
  );
};
