import { TFunction } from '@getpopsure/i18n-react';
import {
  AlertTriangleIcon,
  ArthritisIcon,
  BriefcaseIcon,
  CalendarIcon,
  DentalToothTeethIcon,
  DoctorStethoscopeIcon,
  HospitalBuildingIcon,
  MedicationPillsIcon,
  PlaneFlightIcon,
  PregnacyIcon,
  SyringeVaccinationIcon,
  WorldGlobeEarthIcon,
} from '@popsure/dirty-swan';

import { AdditionalInfoType } from '../../types';

export const travelHealth = (t: TFunction): AdditionalInfoType => ({
  price: '€18/year for individuals, €32/year for families',
  description: t(
    'policyModal.travelHealth.description',
    'This insurance covers medical expenses worldwide for an unlimited number of trips, each up to 56 days in duration. It is visa-compliant and valid for travel to any country outside of Germany.'
  ),
  accordions: [
    {
      title: t('policyModal.travelHealth.title1', "What's covered"),
      items: [
        {
          id: 'covered-1',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered1.question',
            'Dental care'
          ),
          answer: t(
            'policyModal.travelHealth.covered1.answer',
            'Pain-relieving dental treatment, simple fillings and repair of existing dentures.'
          ),
        },
        {
          id: 'covered-2',
          icon: <HospitalBuildingIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered2.question',
            'Hospital treatments'
          ),
          answer: t(
            'policyModal.travelHealth.covered2.answer',
            'Medical treatment at the hospital in case of illness or accident.'
          ),
        },
        {
          id: 'covered-3',
          icon: <DoctorStethoscopeIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered3.question',
            'Doctor’s visits'
          ),
          answer: t(
            'policyModal.travelHealth.covered3.answer',
            "Coverage for doctor's visits while traveling abroad in the event of illness or accident."
          ),
        },
        {
          id: 'covered-4',
          icon: <MedicationPillsIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered4.question',
            'Medical prescriptions'
          ),
          answer: t(
            'policyModal.travelHealth.covered4.answer',
            'Drugs, surgical dressings and aids prescribed to you by a doctor.'
          ),
        },
        {
          id: 'covered-5',
          icon: <PlaneFlightIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered5.question',
            'Rescue & repatriation'
          ),
          answer: t(
            'policyModal.travelHealth.covered5.answer',
            'Rescue costs, transport to and from hospital, repatriation of mortal remains or funeral costs.'
          ),
        },
        {
          id: 'covered-6',
          icon: <PregnacyIcon size={24} />,
          question: t(
            'policyModal.travelHealth.covered6.question',
            'Pregnancy complications'
          ),
          answer: t(
            'policyModal.travelHealth.covered6.answer',
            "You're covered in case of issues with your pregnancy or premature birth."
          ),
        },
      ],
    },
    {
      title: t('policyModal.travelHealth.title2', 'Add-ons'),
      items: [
        {
          id: 'addon-1',
          icon: <BriefcaseIcon size={24} />,
          question: t('policyModal.travelHealth.addon1.question', 'Luggage'),
          answer: t(
            'policyModal.travelHealth.addon1.answer',
            'Covers lost, stolen, and damaged luggage during the whole trip, if reported to the relevant authorities (police, airline company, hotel, etc.)'
          ),
        },
        {
          id: 'addon-2',
          icon: <PlaneFlightIcon size={24} />,
          question: t(
            'policyModal.travelHealth.addon2.question',
            'Trip cancellation and curtailment'
          ),
          answer: t(
            'policyModal.travelHealth.addon2.answer',
            'Get additional coverage in case of cancellation, change, or termination of trips due to insured events. Reclaim expenses for canceled vacation days if they have to be terminated or postponed due to covered incidents.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.travelHealth.title3', "What's not covered"),
      items: [
        {
          id: 'not-covered-1',
          icon: <ArthritisIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered1.question',
            'Pre-existing conditions'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered1.answer',
            'Treatments required for illness known prior to the start of the journey.'
          ),
        },
        {
          id: 'not-covered-2',
          icon: <DentalToothTeethIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered2.question',
            'Advanced dental care'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered2.answer',
            'Implants, orthodontic treatment, cleaning, crowns, in-lays, splints and functional treatments are not covered.'
          ),
        },
        {
          id: 'not-covered-3',
          icon: <SyringeVaccinationIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered3.question',
            'Precautionary measures'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered3.answer',
            'Vaccinations, preventative blood tests and other precautionary treatments are not covered.'
          ),
        },
        {
          id: 'not-covered-4',
          icon: <AlertTriangleIcon size={24} />,
          question: t(
            'policyModal.travelHealth.notCovered4.question',
            'Traveling despite official warnings'
          ),
          answer: t(
            'policyModal.travelHealth.notCovered4.answer',
            'Treatment is not covered if a travel warning was issued by the German government before departure.'
          ),
        },
      ],
    },
    {
      title: t('policyModal.travelHealth.title4', 'Important to know'),
      items: [
        {
          id: 'important-1',
          icon: <CalendarIcon size={24} />,
          question: t(
            'policyModal.travelHealth.important1.question',
            'You must sign up before traveling'
          ),
          answer: t(
            'policyModal.travelHealth.important1.answer',
            'You need to apply for your travel insurance policy 4 or more days before you travel. You cannot sign up after you have left the country or once you have determined that you need medical treatment abroad. If you need travel insurance for a visa abroad, you should also apply at least 4 working days prior to your visa appointment.'
          ),
        },
      ],
    },
  ],
  details: [
    {
      icon: PlaneFlightIcon,
      title: t(
        'policyModal.travelHealth.details.title1',
        'Unlimited trips per year'
      ),
      description: t(
        'policyModal.travelHealth.details.description1',
        'You can use this policy for any number of trips that are up to 56 days'
      ),
    },
    {
      icon: WorldGlobeEarthIcon,
      title: t('policyModal.travelHealth.details.title2', 'Worldwide coverage'),
      description: t(
        'policyModal.travelHealth.details.description2',
        'Valid for any country outside Germany'
      ),
    },
  ],
  buttonText: t('policyModal.travelHealth.actionButton', 'Get covered'),
});
