import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';

import { EditAnswersButton } from '../EditAnswersButton';
import styles from './OverviewHeader.module.scss';

export const OverviewHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.wrapper, 'mb32')}>
      <NavButton path={routes.me.policies.list.path} title="Back" />
      <div className="d-flex jc-between">
        <h1 className="p-h1">
          {t('recommendationtool.overview.title', 'Quick assessment')}
        </h1>
        <EditAnswersButton className={styles.mobileHidden} />
      </div>

      <EditAnswersButton className={classNames('mt16', styles.mobileDisplay)} />
    </div>
  );
};
