import { Toggle } from '@popsure/dirty-swan';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import {
  MailingListResponse,
  UpdateMailingPreference,
} from 'models/mailingPreferences';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './MailingPreferences.module.scss';

interface MailingPreferencesProps {
  mailingPreferences: MailingListResponse;
  error?: AxiosError | null;
  updateMailingPreferences: (
    options: UpdateMailingPreference
  ) => Promise<MailingListResponse>;
}

const MailingPreferences = ({
  mailingPreferences,
  updateMailingPreferences,
  error,
}: MailingPreferencesProps) => {
  const { t } = useSafeTranslation();

  const [feedback, setFeedback] = useState<string | null>(null);
  const [getAllEmails, setGetAllEmails] = useState<boolean>(
    mailingPreferences.customer.topics.every((topic) => topic.subscribed)
  );
  const [selected, setSelected] = useState<string[]>(
    mailingPreferences.customer.topics
      .filter((topic) => topic.subscribed)
      .map((topic) => `topic_${topic.id}`)
  );

  const handleGetAllEmailsChange = () => {
    if (!getAllEmails) {
      const allTopics = mailingPreferences.topics.map(
        (topic) => topic.identifier
      );
      handleToggleChange(allTopics);
    } else {
      handleToggleChange([]);
    }

    setGetAllEmails(!getAllEmails);
  };

  const handleToggleChange = (selectedTopics: string[]) => {
    setSelected(selectedTopics);
    setGetAllEmails(selectedTopics.length === mailingPreferences.topics.length);

    const options = mailingPreferences.topics.reduce(
      (all, topic) => ({
        ...all,
        [topic.identifier]: selectedTopics.includes(topic.identifier),
      }),
      {}
    );

    setFeedback(
      t(
        'account.communicationPreferences.savingToastMessage.loading',
        'Saving preferences...'
      )
    );

    updateMailingPreferences(options).then(() => {
      setTimeout(() => setFeedback(null), 3000);

      if (error) {
        setFeedback(
          t(
            'account.communicationPreferences.savingToastMessage.error',
            'There was an error saving your preferences. Please refresh and try again.'
          )
        );
      } else {
        setFeedback(
          t(
            'account.communicationPreferences.savingToastMessage.success',
            '🎉 Preferences saved'
          )
        );
      }
    });
  };

  const toggleOptions = mailingPreferences.topics.reduce(
    (all, topic) => ({
      ...all,
      [topic.identifier]: {
        title: topic.name,
        description: topic.description,
      },
    }),
    {}
  );

  return (
    <div className="p-container wmx8">
      <NavButton
        path={routes.account.path}
        title={t('account.backButton.caption', 'Account')}
      />

      <h1 className="p-h1 mb24">
        {t('account.communicationPreferences.title', 'Communication')}
      </h1>

      <Toggle
        options={{
          getAll: {
            title: t(
              'account.communicationPreferences.getAllEmails',
              'Get all emails'
            ),
          },
        }}
        classNames={{
          container: classNames('bg-white', styles.getAllToggle),
          label: classNames(styles.label, 'fd-row-reverse jc-between p24'),
        }}
        bordered={false}
        onChange={handleGetAllEmailsChange}
        value={getAllEmails ? ['getAll'] : []}
      />

      <Toggle
        options={toggleOptions}
        classNames={{
          container: classNames('bg-white', styles.topicsToggle),
          label: classNames(styles.label, 'fd-row-reverse jc-between p24'),
        }}
        bordered={false}
        onChange={handleToggleChange}
        value={selected}
      />

      <p className="p-p--small tc-grey-600 mt16">
        {t(
          'account.communicationPreferences.finePrint',
          'We will also send you important information about your insurance policies, as well as service announcements and terms, privacy and security updates.'
        )}
      </p>

      <div className={`mt16 d-flex ai-center ${styles.footer}`}>
        <p
          className={classNames('p-p ml24 tc-grey-600', styles.feedback, {
            [styles.feedbackActive]: feedback,
          })}
        >
          {feedback}
        </p>
      </div>

      <AnimateHeight duration={300} height={error ? 'auto' : 0}>
        <p className="p-notice--danger mt24 w100">{error}</p>
      </AnimateHeight>
    </div>
  );
};

export default MailingPreferences;
