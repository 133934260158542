import dayjs from 'dayjs';

const RIGHT_OF_WITHDRAWAL_PERIOD_IN_DAYS = 14;

export const isInRightOfWithdrawalPeriod = ({
  nowDate,
  startDateOfRoWPeriod,
}: {
  nowDate: string | number;
  startDateOfRoWPeriod?: string;
}) => {
  if (!startDateOfRoWPeriod) {
    return false;
  }

  return (
    dayjs(nowDate).diff(startDateOfRoWPeriod, 'days') <=
    RIGHT_OF_WITHDRAWAL_PERIOD_IN_DAYS
  );
};
