// TODO: Move this country-handling logic where it's centralized, translatable and reusable (f.e. i18n library)

import { Region, regions } from '@getpopsure/public-models';
import { Name } from 'models/user';
import { z } from 'zod';

// This will also allow us to handle country names that differ based on context ("Netherlands"/"The Netherlands")
export const regionToNameMapping: Record<Region, string> = {
  nl: 'Netherlands',
  se: 'Sweden',
  be: 'Belgium',
  it: 'Italy',
  at: 'Austria',
  mt: 'Malta',
  fi: 'Finland',
  cy: 'Cyprus',
  gr: 'Greece',
  ee: 'Estonia',
  pt: 'Portugal',
  lt: 'Lithuania',
  hr: 'Croatia',
  lv: 'Latvia',
  mc: 'Monaco',
  de: 'Germany',
  es: 'Spain',
  fr: 'France',
  eu: 'Europe',
};

export const NewUserSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  region: z.enum(regions),
});

export type SignInQuestions = {
  email: string;
  name: Name;
  region?: Region;
};

export type SignInGroupIds = 'question';
