import { TFunction } from '@getpopsure/i18n-react';
import { blog, faq, insurance, website } from '@getpopsure/private-constants';
import { isDateFuture } from '@getpopsure/public-utility';
import { Blocker } from 'components/Blocker';
import { Intro } from 'components/Intro';
import RadioForm from 'components/questionnaireForms/RadioForm';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { JobOffer } from 'features/expat/components/JobOffer';
import { UniversityName } from 'features/expat/components/UniversityName';
import {
  assertedUniversities,
  ExpatQuestionnaire,
  Occupation,
  occupationMapping,
  studentMapping,
  StudentType,
  University,
} from 'features/expat/models';
import {
  getArrivalDateYearRange,
  getDateOfBirthYearRange,
  getJobOfferDateYearRange,
  isBetweenFourAndFiveYearsInGermanyBlocker,
  isDateInThePast,
  isMoreThanFiveYearsInGermany,
  isOver,
  isWithinMonth,
  YEARLY_MIN_INCOME_THRESHOLD,
} from 'features/expat/utils';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { generatePath } from 'react-router';
import { isUnder18 } from 'shared/util/isUnder18';

export const preQuoteQNR = (
  t: TFunction
): PartialQuestionnaire<ExpatQuestionnaire, 'prequote'> => ({
  welcome: {
    required: true,
    type: 'CUSTOM',
    groupId: 'prequote',
    component: Intro,
    retrieveAnswerObject: (): Partial<ExpatQuestionnaire> => ({
      welcome: true,
      isPolicyHolder: true,
    }),
    retrieveQuestionsToRemove: () => ['dateOfBirth', 'arrivalDate', 'quote'],
  },
  dateOfBirth: {
    required: true,
    type: 'DATE',
    groupId: 'prequote',
    props: {
      question: t('expat.qnr.preQuote.age.title', 'When were you born?'),
      yearRange: getDateOfBirthYearRange(),
    },
    validateAnswer: (date) => {
      if (isDateFuture(date)) {
        return t(
          'expat.qnr.preQuote.age.error.noFutureDate',
          'The date has to be today or in the past'
        );
      }
      return null;
    },
    retrieveAnswerObject: (
      dateOfBirth: string,
      questionnaireAnswers
    ): Partial<ExpatQuestionnaire> => ({
      dateOfBirth,
      addDependents: isUnder18(dateOfBirth)
        ? false
        : questionnaireAnswers?.addDependents,
    }),
    retrieveNextPageId: (answer) => {
      if (isOver(answer, 75)) {
        return 'seventyFiveAndOverBlocker';
      }
      return 'occupation';
    },
    retrieveQuestionsToRemove() {
      return [
        'arrivalDate',
        'employedAnnualIncome',
        'employmentStartDate',
        'typeOfStudent',
        'universityName',
        'quote',
      ];
    },
  },
  seventyFiveAndOverBlocker: {
    required: (answers) => {
      if (answers.dateOfBirth) {
        return isOver(answers.dateOfBirth, 75);
      }
      return false;
    },
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.seventyFiveAndOverBlocker.title',
          'Unfortunately...'
        ),
        description: t(
          'expat.qnr.seventyFiveAndOverBlocker.description',
          "Because you are over 74 years old, you aren't eligible for expat health insurance.\n\nYou can try out our **digital recommendation** tool to find out which health insurance is the best option for you."
        ),
        buttonProps: [
          {
            type: 'href',
            href: generatePath(routes.insuranceTool.intro.path, {
              insuranceType: 'health',
            }),
            caption: t(
              'expat.qnr.seventyFiveAndOverBlocker.primaryButton.cta',
              'Get a recommendation'
            ),
          },
          {
            variant: 'SECONDARY',
            type: 'href',
            href: blog.insuranceAfter55,
            caption: t(
              'expat.qnr.seventyFiveAndOverBlocker.secondaryButton.cta',
              'Learn more'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },

  betweenFourAndFiveYearsInGermanyBlocker: {
    required: (answers) => {
      if (answers.betweenFourAndFiveYearsInGermanyBlocker) {
        return isBetweenFourAndFiveYearsInGermanyBlocker(answers.arrivalDate);
      }
      return false;
    },
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.betweenFourAndFiveYearsInGermanyBlocker.title',
          'Expat health insurance might not be the best choice for you...'
        ),
        description: t(
          'expat.qnr.betweenFourAndFiveYearsInGermanyBlocker.description',
          'Expat health insurance is only valid for the first 5 years of your stay in Germany. Soon, you will no longer be eligible for it.\n\n You can check out long-term expat health insurance as an alternative.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: insurance.longTermExpatHealth.landingPage,
            caption: t(
              'expat.qnr.betweenFourAndFiveYearsInGermanyBlocker.primaryButton.cta',
              'Explore long-term expat health'
            ),
          },
          {
            variant: 'SECONDARY',
            type: 'button',
            onClick: () => props.onSubmitValue(true),
            caption: t(
              'expat.qnr.betweenFourAndFiveYearsInGermanyBlocker.secondaryButton.cta',
              'Continue application'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    retrieveNextPageId: (answer) => {
      if (answer === true) {
        return 'quote';
      }
      return 'betweenFourAndFiveYearsInGermanyBlocker';
    },
    groupId: 'prequote',
  },
  moreThanFiveYearsInGermanyBlocker: {
    required: (answers) => {
      if (answers.arrivalDate) {
        return isMoreThanFiveYearsInGermany(answers.arrivalDate);
      }
      return false;
    },
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.nonAcceptedForEmploymentBlocker.title',
          'We have another option for you'
        ),
        description: t(
          'expat.qnr.moreThanFiveYearsInGermany.description',
          "Since you have been in Germany for longer than 5 years, you aren't eligible for expat health insurance.\n\nYou can explore long-term expat health insurance as an alternative."
        ),
        buttonProps: [
          {
            type: 'href',
            href: insurance.longTermExpatHealth.landingPage,
            caption: t(
              'expat.qnr.moreThanFiveYearsInGermany.exploreLongTermExpatButton.cta',
              'Explore long-term expat health'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },
  isProfessionalDancerBlocker: {
    required: (answers) => {
      return answers.isProfessionalDancer ?? false;
    },
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.nonAcceptedForEmploymentBlocker.title',
          'We have another option for you'
        ),
        description: t(
          'expat.qnr.isProfessionalDancerBlocker.description',
          'As a professional dancer, you are not eligible for expat health insurance.\n\nYou can explore long-term expat health insurance as an alternative.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: insurance.longTermExpatHealth.landingPage,
            caption: t(
              'expat.qnr.moreThanFiveYearsInGermany.exploreLongTermExpatButton.cta',
              'Explore long-term expat health'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },
  occupation: {
    required: true,
    type: 'CUSTOM',
    component: (props) =>
      RadioForm({
        ...props,
        value: props.value !== undefined ? String(props.value) : undefined,
        question: t(
          'expat.qnr.preQuote.occupation.title',
          "What's your main occupation in Germany?"
        ),
        mapValue: occupationMapping(t),
        additionalInfo: {
          title: t(
            'expat.qnr.preQuote.occupation.information.title',
            'Working for an international company?'
          ),
          description: t(
            'expat.qnr.preQuote.occupation.information.description',
            'If you have a contract with a company that has a tax registration in Germany, you are considered employed in Germany.'
          ),
        },
      }),
    retrieveAnswerObject: (
      occupation: Occupation
    ): Partial<ExpatQuestionnaire> => ({
      occupation,
    }),
    retrieveNextPageId: (answer: Occupation, questionnaireAnswers) => {
      if (['UNEMPLOYED', 'OTHER'].includes(answer)) {
        return 'arrivalDate';
      }

      if (answer === 'SELF_EMPLOYED') {
        return 'selfEmployedAnnualIncome';
      }

      if (
        answer === 'STUDENT' &&
        questionnaireAnswers?.dateOfBirth &&
        isOver(questionnaireAnswers.dateOfBirth, 30)
      ) {
        return 'quote';
      }

      if (
        answer === 'STUDENT' &&
        questionnaireAnswers?.dateOfBirth &&
        !isOver(questionnaireAnswers.dateOfBirth, 30)
      ) {
        return 'typeOfStudent';
      }

      if (answer === 'HAVE_A_JOB_OFFER') {
        return 'employmentStartDate';
      }

      if (answer === 'PROFESSIONAL_ATHLETE') {
        return 'isProfessionalDancer';
      }

      return 'employedAnnualIncome';
    },
    retrieveQuestionsToRemove() {
      return [
        'selfEmployedAnnualIncome',
        'employedAnnualIncome',
        'employmentStartDate',
        'typeOfStudent',
        'universityName',
        'quote',
      ];
    },
    groupId: 'prequote',
  },
  isProfessionalDancer: {
    required: (answers) => {
      return answers.occupation === 'PROFESSIONAL_ATHLETE';
    },
    type: 'BOOLEAN',
    props: {
      question: t(
        'expat.qnr.preQuote.isProfessionalDancer.title',
        'Are you a professional dancer?'
      ),
    },
    groupId: 'prequote',
    retrieveAnswerObject: (
      isProfessionalDancer: boolean
    ): Partial<ExpatQuestionnaire> => ({
      isProfessionalDancer,
    }),
    retrieveNextPageId: (answer: Occupation) => {
      if (answer) {
        return 'isProfessionalDancerBlocker';
      }
      return 'isProfessionalAthleteBlocker';
    },
  },
  employedAnnualIncome: {
    required: (answers) => {
      return answers.occupation === 'EMPLOYED';
    },
    type: 'CURRENCY',
    props: {
      question: t(
        'expat.qnr.preQuote.yearlyIncome.title',
        "What's your annual employment income before taxes?"
      ),
      placeholder: t(
        'expat.qnr.preQuote.yearlyIncome.placeholder',
        'Annual Income'
      ),
    },
    groupId: 'prequote',
    retrieveAnswerObject: (
      employedAnnualIncome: number
    ): Partial<ExpatQuestionnaire> => ({
      employedAnnualIncome,
    }),
    retrieveNextPageId: (answer, questionnaireAnswers) => {
      if (answer < YEARLY_MIN_INCOME_THRESHOLD) {
        return 'arrivalDate';
      }

      if (answer > insurance.privateHealth.employeeThreshold) {
        return 'aboveThresholdEmployedBlocker';
      }

      if (
        answer >= YEARLY_MIN_INCOME_THRESHOLD &&
        answer <= insurance.privateHealth.employeeThreshold &&
        questionnaireAnswers?.dateOfBirth &&
        !isOver(questionnaireAnswers.dateOfBirth, 55)
      ) {
        return 'withinThresholdEmployedBlocker';
      }
      return 'aboveThresholdOrOver55EmployedBlocker';
    },
  },
  selfEmployedAnnualIncome: {
    required: (answers) => {
      return answers.occupation === 'SELF_EMPLOYED';
    },
    type: 'CURRENCY',
    props: {
      question: t(
        'expat.qnr.preQuote.yearlyIncome.title',
        "What's your annual employment income before taxes?"
      ),
      placeholder: t(
        'expat.qnr.preQuote.yearlyIncome.placeholder',
        'Annual Income'
      ),
    },
    groupId: 'prequote',
    retrieveAnswerObject: (
      selfEmployedAnnualIncome: number
    ): Partial<ExpatQuestionnaire> => ({
      selfEmployedAnnualIncome,
    }),
    retrieveNextPageId: (answer) => {
      if (answer > insurance.privateHealth.freelancerThresholdUnder51) {
        return 'aboveThresholdSelfEmployedBlocker';
      }

      return 'arrivalDate';
    },
  },
  aboveThresholdSelfEmployedBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.aboveThresholdSelfEmployedBlocker.title',
          'You have another option to consider'
        ),
        description: t(
          'expat.qnr.aboveThresholdSelfEmployedBlocker.description',
          'Expat health insurance is a temporary option for new arrivals in Germany and offers limited coverage.\n\nDepending on your medical history, you might also be eligible for private health insurance, which provides more comprehensive coverage.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: insurance.privateHealth.landingPage,
            caption: t(
              'expat.qnr.aboveThresholdSelfEmployedBlocker.exploreButton.cta',
              'Explore private health insurance'
            ),
          },
          {
            variant: 'SECONDARY',
            type: 'button',
            onClick: () => props.onSubmitValue(),
            caption: t(
              'expat.qnr.aboveThresholdSelfEmployedBlocker.secondaryButton.cta',
              'Continue application'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },
  aboveThresholdEmployedBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.aboveThresholdEmployedBlocker.title',
          'Expat health insurance might not be right for you'
        ),
        description: t(
          'expat.qnr.aboveThresholdEmployedBlocker.description',
          'Expat health insurance is typically not accepted by employers in Germany. It is only a temporary option for new arrivals in Germany. It does not offer comprehensive health insurance coverage.\n\nIt looks like you might be eligible for private health insurance.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: insurance.privateHealth.landingPage,
            caption: t(
              'expat.qnr.aboveThresholdEmployedBlocker.exploreButton.cta',
              'Explore private health insurance'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },
  withinThresholdEmployedBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.withinThresholdEmployedBlocker.title',
          'Expat health insurance might not be right for you'
        ),
        description: t(
          'expat.qnr.withinThresholdEmployedBlocker.description',
          'Expat health insurance is typically not accepted by employers in Germany. It is only a temporary option for new arrivals in Germany. It does not offer full health insurance coverage or cover pre-existing conditions.\n\nIt looks like you might be eligible for public health insurance.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: insurance.publicHealth.landingPage,
            caption: t(
              'expat.qnr.withinThresholdEmployedBlocker.exploreButton.cta',
              'Explore public health insurance'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },
  aboveThresholdOrOver55EmployedBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.aboveThresholdOrOver55EmployedBlocker.title',
          'Expat health insurance might not be right for you'
        ),
        description: t(
          'expat.qnr.aboveThresholdOrOver55EmployedBlocker.description',
          'Expat health insurance is typically not accepted by employers in Germany. It is only a temporary option for new arrivals in Germany. It does not offer full health insurance coverage or cover pre-existing conditions.\n\nYou can try out our **digital recommendation tool** to find out which health insurance is the best option for you. '
        ),
        buttonProps: [
          {
            type: 'href',
            href: generatePath(routes.insuranceTool.intro.path, {
              insuranceType: 'health',
            }),
            caption: t(
              'expat.qnr.aboveThresholdOrOver55EmployedBlocker.exploreButton.cta',
              'Get a recommendation'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },
  employmentStartDate: {
    type: 'CUSTOM',
    component: (props) =>
      JobOffer({
        ...props,
        value: props.value === undefined ? undefined : String(props.value),
        question: t(
          'expat.qnr.preQuote.jobOfferStartDate.title',
          'When does your employment contract begin?'
        ),
        yearRange: getJobOfferDateYearRange(),
        skipDetails: {
          path: '',
          title: t(
            'expat.qnr.preQuote.jobOfferStartDate.skipQuestion',
            "I don't know yet"
          ),
          onClick: () => props.onSubmitValue(),
        },
        validate: (date?: string) => {
          if (!date) {
            return { validForSubmission: false };
          }
          if (date && isDateInThePast(date)) {
            return {
              validForSubmission: false,
              errorMsg: t(
                'expat.qnr.preQuote.jobOfferStartDate.error',
                'Date has to be in the future'
              ),
            };
          }
          if (date && isWithinMonth(date)) {
            return {
              validForSubmission: true,
              errorMsg: t(
                'expat.qnr.preQuote.jobOfferStartDate.warning',
                'Keep in mind that expat health insurance is a monthly subscription.'
              ),
            };
          }
          return { validForSubmission: true };
        },
      }),
    groupId: 'prequote',
    retrieveAnswerObject: (
      employmentStartDate: Date
    ): Partial<ExpatQuestionnaire> => ({
      employmentStartDate,
    }),
    retrieveNextPageId: () => {
      return 'arrivalDate';
    },
  },
  typeOfStudent: {
    type: 'RADIO',
    groupId: 'prequote',
    props: {
      question: t(
        'expat.qnr.preQuote.studentType.title',
        'Where are you studying?'
      ),
      mapValue: studentMapping(t),
    },
    retrieveAnswerObject: (
      typeOfStudent: StudentType
    ): Partial<ExpatQuestionnaire> => ({
      typeOfStudent,
    }),
    retrieveNextPageId: (answer: StudentType) => {
      if (['LANGUAGE_STUDENT', 'OTHER'].includes(answer)) {
        return 'arrivalDate';
      }
      return 'universityName';
    },
  },
  universityName: {
    type: 'CUSTOM',
    component: UniversityName,
    retrieveAnswerObject: (
      universityName: string
    ): Partial<ExpatQuestionnaire> => ({
      universityName,
    }),
    retrieveNextPageId: (answer: University, questionnaireAnswers) => {
      const isListedUniversity = assertedUniversities.includes(answer);
      if (
        answer === null ||
        !isListedUniversity ||
        (questionnaireAnswers?.dateOfBirth &&
          isOver(questionnaireAnswers.dateOfBirth, 30))
      ) {
        return 'arrivalDate';
      }
      return 'under30StudentBlocker';
    },
    groupId: 'prequote',
  },
  under30StudentBlocker: {
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t(
          'expat.qnr.under30StudentBlocker.title',
          'Expat health insurance might not be the best choice for you'
        ),
        description: t('expat.qnr.under30StudentBlocker.description', {
          defaultValue:
            'We strongly recommend students sign up for public health insurance. You will get a discounted price until you are 30 years old and it is your chance to get into the public healthcare system in Germany.\n\nExpat health insurance is only a short-term option for new arrivals in Germany. It does not provide full health insurance coverage or cover pre-existing conditions.\n\nIf you still decide to go with expat health insurance, you will need to [request an exemption]({{link}}) from a public health insurance provider. You will only be eligible for public health insurance again if you start working or switch study programs.',
          link: faq.studentHealthStatusConfirmation,
        }),
        buttonProps: [
          {
            type: 'href',
            href: insurance.publicHealth.landingPage,
            caption: t(
              'expat.qnr.under30StudentBlocker.exploreButton.cta',
              'Explore public health insurance'
            ),
          },
          {
            variant: 'SECONDARY',
            type: 'button',
            onClick: () => props.onSubmitValue(),
            caption: t(
              'expat.qnr.under30StudentBlocker.secondaryButton.cta',
              'Continue application'
            ),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    retrieveNextPageId: (answer) => {
      if (answer === undefined) {
        return 'quote';
      }
      return 'under30StudentBlocker';
    },
    groupId: 'prequote',
  },
  isProfessionalAthleteBlocker: {
    required: (answers) => {
      if (answers.occupation) {
        return (
          answers.occupation === 'PROFESSIONAL_ATHLETE' &&
          answers.isProfessionalDancer === false
        );
      }
      return false;
    },
    type: 'CUSTOM',
    component: (props) =>
      Blocker({
        title: t('expat.qnr.athleteBlocker.title', 'We can’t sign you up'),
        description: t(
          'expat.qnr.athleteBlocker.description',
          'Unfortunately, professional athletes, are not covered by expat health insurance.\n\nWe’re working hard to make our policies cover all use cases, but still have certain limitations.'
        ),
        buttonProps: [
          {
            type: 'href',
            href: website.support,
            caption: t('expat.qnr.athleteBlocker.button.cta', 'Speak to us'),
          },
        ],
        iconType: 'SHIELD',
        ...props,
      }),
    groupId: 'prequote',
  },
  arrivalDate: {
    required: true,
    type: 'DATE',
    groupId: 'prequote',
    props: {
      question: t(
        'expat.qnr.preQuote.arrivalDate.title',
        "What's your arrival date in Germany?"
      ),
      yearRange: getArrivalDateYearRange(),
    },
    retrieveAnswerObject: (
      arrivalDate: string
    ): Partial<ExpatQuestionnaire> => ({
      arrivalDate,
    }),
    validateAnswer: (answer) => {
      if (dayjs().add(1, 'year').subtract(1, 'days').isBefore(dayjs(answer))) {
        return t(
          'expat.qnr.preQuote.arrivalDate.maxFutureArrivalDate',
          'Arrival date should not be more than 1 year in the future. Please adjust the arrival date.'
        );
      }
      return null;
    },
    retrieveNextPageId: (answer) => {
      if (isMoreThanFiveYearsInGermany(answer)) {
        return 'moreThanFiveYearsInGermanyBlocker';
      }
      if (isBetweenFourAndFiveYearsInGermanyBlocker(answer)) {
        return 'betweenFourAndFiveYearsInGermanyBlocker';
      }
      return 'quote';
    },
    retrieveQuestionsToRemove: () => [],
  },
});
