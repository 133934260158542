import { defaultState as billing } from 'features/billingHistory/reducers';
import { defaultState as checkoutDocuments } from 'features/checkoutDocuments/checkoutDocuments.reducers';
import { defaultState as i18n } from 'features/i18n/reducers';
import { defaultState as lifeBeneficiaries } from 'features/lifeBeneficiaries/lifeBeneficiaries.reducers';
import { defaultState as paymentMethods } from 'features/paymentMethods/paymentMethods.reducers';
import { defaultState as paymentScreen } from 'features/paymentScreen/paymentScreen.reducers';
import type { AppState } from 'reducers';
import { defaultState as claims } from 'reducers/claims';
import { defaultState as genericQuestionnaire } from 'reducers/genericQuestionnaire';
// TODO: [kong] Dependency cycle via models/healthCard:2=>reducers:7
// eslint-disable-next-line
import { defaultState as healthCard } from 'reducers/healthCard';
// TODO: [kong] Dependency cycle via features/recommendation/health/questionnaire/containers:2=>selectors/healthInsuranceTool:5=>reducers:4
// eslint-disable-next-line
import { defaultState as policies } from 'reducers/policies';
import { defaultState as recommendationTool } from 'reducers/recommendationTool';
import { defaultState as request } from 'reducers/request';
import { defaultState as user } from 'reducers/user';

/**
 * @deprecated use createTestStore from `shared/testUtils` instead
 */
export function createState(partialState: Partial<AppState>): AppState {
  return {
    user,
    request,
    policies,
    claims,
    genericQuestionnaire,
    paymentMethods,
    paymentScreen,
    billing,
    healthCard,
    recommendationTool,
    i18n,
    lifeBeneficiaries,
    checkoutDocuments,
    ...partialState,
  };
}
