import { TFunction } from '@getpopsure/i18n-react';
import {
  Insurer,
  INSURERS,
} from '@getpopsure/public-health-insurance-pricing-engine';
import { Gender } from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { PartialQuestionnaire } from 'models/questionnaireFramework';
import { Name } from 'models/user';
import { ZGenderSchema, ZNameSchema } from 'shared/models/validations';
import type { BLOCKER } from 'SignupQuestionnaire';
import { z } from 'zod';

export type InsurerDetails = {
  value: Insurer;
  label: Insurer;
};

export type Plan = 'BASIC' | 'ADVANCED';

export type MissingTeeth = 'NONE' | 'ONE' | 'TWO' | 'THREE' | 'MORE_THAN_THREE';

export const missingTeethMapping = (
  t: TFunction
): Record<MissingTeeth, string> => ({
  NONE: t('dental.qnr.missingTeeth.map.none', 'None'),
  ONE: t('dental.qnr.missingTeeth.map.oneTooth', '1 tooth'),
  TWO: t('dental.qnr.missingTeeth.map.twoTeeth', '2 teeth'),
  THREE: t('dental.qnr.missingTeeth.map.threeTeeth', '3 teeth'),
  MORE_THAN_THREE: t(
    'dental.qnr.missingTeeth.map.moreThanThreeTeeth',
    'More than 3 teeth'
  ),
});

export const genderMapping = (t: TFunction): Record<Gender, string> => ({
  MALE: t('dental.qnr.gender.optionMale', 'Male'),
  FEMALE: t('dental.qnr.gender.optionFemale', 'Female'),
  OTHER: t('dental.qnr.gender.optionOther', 'Gender non-conforming'),
});

export const TOOTH_REPLACEMENT_COVERAGE = ['80%', '90%', '100%'] as const;

export type ToothReplacementCoverage =
  typeof TOOTH_REPLACEMENT_COVERAGE[number];

export type QuoteCustomization = {
  plan: Plan;
  toothReplacementCoverage?: ToothReplacementCoverage;
};

export type Quote = QuoteCustomization & {
  id: string;
  price: number;
};

export type QuoteOption = {
  id: string;
  planId: Plan;
  price: number;
  attributes: {
    toothReplacementCoverage: ToothReplacementCoverage | 'DEFAULT';
  };
};

export type DentalQuotePlan = Record<
  Plan,
  { name: string; icon: string; price: number }
>;

export type CheckoutPolicyDetails = {
  providerId: Insurer;
  planId: Plan;
  toothReplacementCoverage?: ToothReplacementCoverage;
  legalGuardianName?: Name;
  legalGuardianDateOfBirth?: string;
};

export type Dental = {
  // pre quote
  intro: boolean;
  publicInsurer: InsurerDetails;
  noPublicInsuranceBlocker: BLOCKER;
  dateOfBirth: string;
  quoteProcessing: QuoteOption[];
  quote: Quote;

  // signup
  signupIntro: boolean;
  advancedNoticeScreen?: boolean;
  missingTeeth: MissingTeeth;
  missingTeethBlocker?: BLOCKER;
  missingTwoTeethNotice?: boolean;
  missingThreeTeethNotice?: boolean;
  hasTreatment: boolean;
  hasTreatmentBasic?: boolean;
  hasTreatmentBlocker?: BLOCKER;
  email: string;
  name: Name;
  gender: Gender;
  legalGuardianName?: Name;
  legalGuardianDateOfBirth: string;
  startDate: string;

  // checkout
  reviewCheckout: boolean;
  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;

  quoteId: string;
  questionnaireId: string;
};

export type Questionnaire = PartialQuestionnaire<Dental, GroupId>;
export type QuestionId = keyof Dental;

export type GroupId = 'preQuote' | 'signup';

export const ZSubmittableDentalQuote = z.object({
  publicInsurer: z.enum(INSURERS as [string, ...string[]]),
  dateOfBirth: z.string(),
  plan: z.optional(z.enum(['BASIC', 'ADVANCED'])),
  toothReplacementCoverage: z.optional(z.enum(TOOTH_REPLACEMENT_COVERAGE)),
});

export const ZSubmittableDentalQuestionnaire = z
  .object({
    name: ZNameSchema,
    email: z.string(),
    gender: ZGenderSchema,
    dateOfBirth: z.string(),
    startDate: z.string(),
    legalGuardianName: z.optional(ZNameSchema),
    legalGuardianDateOfBirth: z.optional(z.string()),
    missingTeeth: z.optional(
      z.enum(['NONE', 'ONE', 'TWO', 'THREE', 'MORE_THAN_THREE'])
    ),
    hasTreatment: z.boolean(),
  })
  .transform(
    ({
      name,
      email,
      gender,
      dateOfBirth,
      startDate,
      hasTreatment,
      missingTeeth,
      legalGuardianName,
      legalGuardianDateOfBirth,
    }) => ({
      personalInfo: {
        name,
        email,
        gender,
        dateOfBirth,
        legalGuardianName,
        legalGuardianDateOfBirth,
      },
      medicalHistory: {
        hasOngoingTreatments: hasTreatment,
        numberOfMissingTeeth: missingTeeth ?? 'NONE',
      },
      tariffInfo: {
        startDate,
      },
    })
  );

export type SubmittableDentalQuestionnaire = z.TypeOf<
  typeof ZSubmittableDentalQuestionnaire
>;

export type QuestionnaireType = 'DENTAL_PRE_QUOTE' | 'DENTAL_POST_QUOTE';
