import { expatLongTerm_enDe } from '@getpopsure/data-sync';
import { Deductible } from '@getpopsure/expat-lt-pricing-engine';
import { useTranslation } from '@getpopsure/i18n-react';
import {
  Card,
  InformationBox,
  SegmentedControl,
  Table,
  TableProps,
  Toggle,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import FAQSection from 'components/faq';
import ReviewBadge from 'components/reviewBadge';
import SectionHeader from 'components/sectionHeader';
import { QuotePlan } from 'features/expat/models';
import { useState } from 'react';
import { Processing } from 'shared/pages/Processing';
import { isMobileApp } from 'shared/util/isMobileApp';

import { QuoteFooter } from './components/Footer/Footer';
import { QuoteHeader } from './components/Header/Header';
import { coverageData, faqData } from './content';
import styles from './Quote.module.scss';

const deductibleOptions: { value: Deductible; label: string }[] = [
  { value: '0', label: '€0' },
  { value: '750', label: '€750' },
  { value: '3750', label: '€3,750' },
];

interface QuoteViewProps {
  cellReplacements: TableProps['cellReplacements'];
  hasExtendedCoverage: boolean;
  isDependent?: boolean;
  isUnderEighteen: boolean;
  onBookACall: () => void;
  onExtendedCoverageChange: (value: boolean) => void;
  onSelectionChanged: (index: number) => void;
  onSubmit: () => void;
  onDeductibleChange: (value: Deductible) => void;
  plan: QuotePlan;
  price: number;
  deductible: Deductible;
}

export const QuoteView = ({
  cellReplacements,
  hasExtendedCoverage,
  isDependent,
  isUnderEighteen,
  onBookACall,
  onExtendedCoverageChange,
  onSelectionChanged,
  onSubmit,
  onDeductibleChange,
  plan,
  price,
  deductible,
}: QuoteViewProps) => {
  const { t } = useTranslation();
  const [hasLoaded, setHasLoaded] = useState(false);

  if (!hasLoaded) {
    return (
      <Processing
        onLoadComplete={() => setHasLoaded(true)}
        textList={[
          t(
            'expatLongTerm.qnr.quote.processing.loadingText',
            'Processing quote...'
          ),
        ]}
      />
    );
  }

  return (
    <div className="w100 bg-white">
      <QuoteHeader isDependent={isDependent} />

      <div className="p-body">
        <section className="mt56 mb80">
          {isUnderEighteen && !isDependent && (
            <InformationBox
              showIcon
              className="mb32"
              title={t(
                'expatLongTerm.qnr.quote.disclaimerNotDependentUnder18.title',
                'Disclaimer title'
              )}
            >
              {t(
                'expatLongTerm.qnr.quote.disclaimerNotDependentUnder18.description',
                'Disclaimer description'
              )}
            </InformationBox>
          )}
          <Table
            hideDetails
            textOverrides={{
              hideDetails: t(
                'expatLongTerm.qnr.quote.table.hideDetails',
                'Hide coverage details'
              ),
              showDetails: t(
                'expatLongTerm.qnr.quote.table.showDetails',
                'Coverage details'
              ),
            }}
            title={expatLongTerm_enDe.tableData.title}
            tableData={
              expatLongTerm_enDe.tableData.tableData as TableProps['tableData']
            }
            cellReplacements={cellReplacements}
            onSelectionChanged={onSelectionChanged}
            stickyHeaderTopOffset={isMobileApp ? 88 : 0}
          />
        </section>

        <section className="mb80">
          <SectionHeader
            centeredOnMobile
            title={
              isDependent
                ? t(
                    'expatLongTerm.qnr.quote.dependent.deductible.title',
                    'Choose their out-of-pocket amount'
                  )
                : t(
                    'expatLongTerm.qnr.quote.deductible.title',
                    'Choose your out-of-pocket amount'
                  )
            }
          />

          <Card
            dropShadow={false}
            classNames={{
              wrapper: classNames('mt24', styles.card),
            }}
          >
            <p className="p-p mb16 tc-grey-700">
              {isDependent
                ? t(
                    'expatLongTerm.qnr.quote.dependent.deductible.description1',
                    'This amount, known as the deductible or excess, is what they’ll pay before their insurance kicks in. A higher deductible means a lower monthly price.'
                  )
                : t(
                    'expatLongTerm.qnr.quote.deductible.description1',
                    'This amount, known as the deductible or excess, is what you’ll pay before your insurance kicks in. A higher deductible means a lower monthly price.'
                  )}
            </p>
            <p className="p-p mb16 tc-grey-700">
              {isDependent
                ? t(
                    'expatLongTerm.qnr.quote.dependent.deductible.description2',
                    `If they're applying for a visa or residence permit, we suggest choosing a deductible of no more than €750.`
                  )
                : t(
                    'expatLongTerm.qnr.quote.deductible.description2',
                    `If you're applying for a visa or residence permit, we suggest choosing a deductible of no more than €750.`
                  )}
            </p>

            <SegmentedControl
              className="my24"
              values={deductibleOptions.map(({ label }) => label)}
              selectedIndex={deductibleOptions.findIndex(
                ({ value }) => value === deductible
              )}
              onChange={(selectedIndex: number) =>
                onDeductibleChange(deductibleOptions[selectedIndex].value)
              }
            />
          </Card>
        </section>

        <section className="mb80">
          <SectionHeader
            centeredOnMobile
            title={
              isDependent
                ? t(
                    'expatLongTerm.qnr.quote.dependent.extendedCoverage.title',
                    'Extend their coverage'
                  )
                : t(
                    'expatLongTerm.qnr.quote.extendedCoverage.title',
                    'Extend your coverage'
                  )
            }
          />

          <Card
            title={t(
              'expatLongTerm.qnr.quote.extendedCoverage.checkbox.title',
              'Coverage to the US and Caribbean'
            )}
            description={
              isDependent
                ? t(
                    'expatLongTerm.qnr.quote.dependent.extendedCoverage.checkbox.description',
                    'Medical care in the US and the Caribbean can be extremely expensive. If they plan to travel frequently or stay for long periods in these regions, extending their coverage can help protect them from unexpected medical expenses.'
                  )
                : t(
                    'expatLongTerm.qnr.quote.extendedCoverage.checkbox.description',
                    'Medical care in the US and the Caribbean can be extremely expensive. If you plan to travel frequently or stay for long periods in these regions, extending your coverage can help protect you from unexpected medical expenses.'
                  )
            }
            actionIcon={
              <Toggle
                classNames={{
                  container: classNames(styles.toggle, 'ml32'),
                }}
                bordered={false}
                options={{
                  extendCoverage: '',
                }}
                value={hasExtendedCoverage ? ['extendCoverage'] : []}
                onChange={() => onExtendedCoverageChange(!hasExtendedCoverage)}
              />
            }
            onClick={() => onExtendedCoverageChange(!hasExtendedCoverage)}
            dropShadow={false}
            density="spacious"
            showActionIcon
            classNames={{
              buttonWrapper: classNames('mt24', styles.card),
              children: 'd-flex',
            }}
          />
        </section>

        <section className="mb80">
          <SectionHeader
            centeredOnMobile
            title={t('expatLongTerm.qnr.quote.whyFeather.title', 'Why Feather')}
          />

          <div className={classNames(styles.cardWrapper, 'd-grid gap16 mt24')}>
            {coverageData(t).map(({ title, description, icon }) => (
              <Card
                key={title}
                title={title}
                description={description}
                icon={icon}
                dropShadow={false}
                verticalAlignment="top"
                classNames={{ wrapper: styles.card }}
              />
            ))}
          </div>
        </section>

        <section className="p-body mb80">
          <FAQSection data={faqData(t)} />
        </section>

        <section>
          <ReviewBadge className="w100 mb80 d-flex jc-center" />
        </section>
      </div>

      <QuoteFooter
        onSubmit={onSubmit}
        plan={plan}
        price={price}
        bookACall={onBookACall}
      />
    </div>
  );
};
